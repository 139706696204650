"use client";
import { useController, FieldValues, FieldPath } from "react-hook-form";
import React, { useId } from "react";
import { cn } from "@/utils/utils";
import InputCore, { InputCoreProps } from "../core/InputCore";
import LabelControl from "../core/components/LabelControl";
export interface InputPropsCustom {
  label?: string;
  type?: "email" | "password" | "search" | "tel" | "text" | "url";
  showError?: boolean;
  icon?: React.ReactNode;
  prevIcon?: React.ReactNode;

}
function InputBorder<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>(props: InputCoreProps<TFieldValues, TName> & InputPropsCustom) {
  const { prevIcon, icon, className, label, ...resProps } = props;
  const { control, name, rules } = props;
  const { fieldState } = useController({ name, control, rules });
  const id = useId();
  return (
    <div
      className={`w-full flex flex-col ${fieldState.error && props.showError && "mb-4"
        }`}
    >
      {label ? (
        <LabelControl
          isrequired={props.required}
          htmlFor={id + "-" + name}
          className="text-base text-gray-500 font-semibold mb-2"
        >
          {label}
        </LabelControl>
      ) : null}
      <div className="flex-1 relative">
        <div className="  absolute left-3 z-10 top-0 bottom-0  flex items-center">
          {prevIcon}
        </div>
        <InputCore

          {...resProps}
          id={id + "-" + name}
          className={cn(
            "w-full border rounded p-3 border-solid border-gray-400 leading-5 focus:outline-none placeholder:text-gray-400 text-gray-500 text-base  ",
            className
            // fieldState.error && "border-red-500"
          )}
          errorClassName={cn("absolute", props.errorClassName)}
          inputClassName={`w-full ${props.inputClassName}`}
        />
        <div className="  absolute right-3 top-0 bottom-0  flex items-center">
          {icon}
        </div>
      </div>
    </div>
  );
}
export default InputBorder;
