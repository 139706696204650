const HomeTranslate = {
	errorKey: {},
	key: {
		cart: "cart",
		brand: "brand",
		quantity: "quantity",
		plu: "plu",
		total: "total",
		product: "product",
		view_cart_and_checkout: "view_cart_and_checkout",
		fast_shipping_2_hours: "fast_shipping_2_hours",
		free_ship: "free_ship",
		fast_shipping_hcm: "fast_shipping_hcm",
		every_order: "every_order",
	},
};

export default HomeTranslate;
