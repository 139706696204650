import { cn } from "@/utils/utils";
import React from "react";

const QrPosIcon = ({ className }: { className?: string }) => {
	return (
		<span className={cn("inline-block  text-gray-500", className)}>
			<svg
				width="30"
				height="30"
				viewBox="0 0 30 30"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<rect
					x="5"
					y="5"
					width="8.33333"
					height="8.33333"
					rx="1"
					stroke="#1A1A1A"
				/>
				<rect
					x="16.6641"
					y="5"
					width="8.33333"
					height="8.33333"
					rx="1"
					stroke="#1A1A1A"
				/>
				<rect
					x="16.6641"
					y="16.666"
					width="8.33333"
					height="8.33333"
					rx="1"
					fill="#1A1A1A"
				/>
				<rect
					x="5"
					y="16.666"
					width="8.33333"
					height="8.33333"
					rx="1"
					stroke="#1A1A1A"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M2 0H8V1H2C1.44772 1 1 1.44772 1 2V8H0V2C0 0.89543 0.895431 0 2 0ZM0 22V28C0 29.1046 0.89543 30 2 30H8V29H2C1.44772 29 1 28.5523 1 28V22H0ZM29 22V28C29 28.5523 28.5523 29 28 29H22V30H28C29.1046 30 30 29.1046 30 28V22H29ZM30 8V2C30 0.895431 29.1046 0 28 0H22V1H28C28.5523 1 29 1.44772 29 2V8H30Z"
					fill="#1A1A1A"
				/>
			</svg>
		</span>
	);
};

export default QrPosIcon;
