import * as React from "react";

import {
	Drawer,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerTitle,
} from "@/components/ui/drawer";
import { cn } from "@/utils/utils";
import { CloseIcon } from "./icons";

interface DrawerProps {
	animate?: "tran" | "fade";
	classNameContent?: string;
	children?: React.ReactNode;
	title?: string | React.ReactNode;
	open: boolean;
	onOpenChange?: () => void;
	onClose?: () => void;
	closeOnMark?: boolean;
	className?: string;
	classNameTitle?: string;
	classNameClose?: string;
}

export default function DrawerMobile({
	onClose,
	onOpenChange,
	open,
	title,
	children,
	className,
	classNameContent,
	classNameClose,
	classNameTitle,
}: DrawerProps) {
	return (
		<Drawer open={open} onClose={onClose} onOpenChange={onOpenChange}>
			<DrawerContent className={className}>
				<div className="w-full h-full flex flex-col relative">
					<div
						className={cn("absolute right-4 top-4 z-10", classNameClose)}
						onClick={onClose}>
						<CloseIcon />
					</div>
					{title && (
						<DrawerHeader className=" flex-shrink-0 relative">
							<DrawerTitle className={classNameTitle}>{title}</DrawerTitle>
						</DrawerHeader>
					)}
					<div className={cn("flex-1   overflow-y-auto p-4", classNameContent)}>
						{children}
					</div>
					<DrawerFooter className="p-0"></DrawerFooter>
				</div>
			</DrawerContent>
		</Drawer>
	);
}
