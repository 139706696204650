import { cn } from "@/utils/utils";
import React from "react";

const VisaCardIcon = ({ className }: { className?: string }) => {
	return (
		<span className={cn("inline-block = text-gray-500", className)}>
			<svg
				width="24"
				height="18"
				viewBox="0 0 24 18"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					opacity="0.07"
					d="M26.7105 0H2.28947C0.992105 0 0 0.975 0 2.25V15.75C0 17.025 1.06842 18 2.28947 18H26.7105C28.0079 18 29 17.025 29 15.75V2.25C29 0.975 27.9316 0 26.7105 0Z"
					fill="white"
				/>
				<path
					d="M27.3889 0C28.275 0 29 0.736364 29 1.63636V16.3636C29 17.2636 28.275 18 27.3889 18H1.61111C0.725 18 0 17.2636 0 16.3636V1.63636C0 0.736364 0.725 0 1.61111 0H27.3889Z"
					fill="white"
				/>
				<path
					d="M11.4515 14.25C14.4018 14.25 16.7936 11.8995 16.7936 9C16.7936 6.10051 14.4018 3.75 11.4515 3.75C8.50112 3.75 6.10938 6.10051 6.10938 9C6.10938 11.8995 8.50112 14.25 11.4515 14.25Z"
					fill="#EB001B"
				/>
				<path
					d="M17.553 14.25C20.5034 14.25 22.8951 11.8995 22.8951 9C22.8951 6.10051 20.5034 3.75 17.553 3.75C14.6027 3.75 12.2109 6.10051 12.2109 9C12.2109 11.8995 14.6027 14.25 17.553 14.25Z"
					fill="#F79E1B"
				/>
				<path
					d="M16.7899 8.99961C16.7899 7.19961 15.8741 5.62461 14.5004 4.72461C13.1267 5.69961 12.2109 7.27461 12.2109 8.99961C12.2109 10.7246 13.1267 12.3746 14.5004 13.2746C15.8741 12.3746 16.7899 10.7996 16.7899 8.99961Z"
					fill="#FF5F00"
				/>
			</svg>
		</span>
	);
};

export default VisaCardIcon;
