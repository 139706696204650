import React from 'react';
import { useBackdrop } from './providers/BackdropProvider';
import { LoadingIcon } from './icons';

export const Backdrop: React.FC = () => {
    const { isVisible, hideBackdrop } = useBackdrop();

    return isVisible ? (
        <div
            className="fixed inset-0 bg-[#0000006d] z-[6000] flex items-center justify-center"
        //   onClick={hideBackdrop} // Hide backdrop on click
        >
            <LoadingIcon  className=' text-white'/>
        </div>
    ) : null;
};
