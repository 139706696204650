import {
	CustomerGenders,
	CustomerJson,
	IS_VISTOR,
	Rank,
} from "@/types/Customer.type";
import { BaseModelJson } from "../types/BaseModelJson.type";
import { FileJson } from "../types/File.type";
import BaseModel from "./BaseModel";
import FileModel from "./FileModel";

const defaultData: CustomerJson = {
	company_id: 0,
	user_id: 0,
	id: 0,
	user_name: "",
	creator_id: 0,
	customer_type_id: 0,
	code: "",
	full_name: "",
	first_name: "",
	last_name: "",
	gender: 0,
	rank: {
		customer_id: 0,
		datecreated: 0,
		day_rank: 0,
		id: 0,
		total_spending: 0,
		rank_type_id: 0,
		status: 0,
		total_rank_accumulate: 0,
		max_day_rank: 0,
		min_rank_type: 0,
		max_rank_type: 0,
		rank_next: 0,
	},
	rank_type: 0,
	birthday: 0,
	email: "",
	phone: "",
	email_verified: 0,
	phone_verified: 0,
	country: 0,
	province: 0,
	district: 0,
	ward: 0,
	road: "",
	address: "",
	region_id: 0,
	sub_region_id: 0,
	sub_sub_region_id: 0,
	tag: "",
	note: "",
	invoicing_detail: "",
	count_order: 0,
	point: 0,
	money: 0,
	tax_buyer_name: "",
	tax_buyer_address: "",
	tax_buyer_phone: "",
	tax_buyer_account_number: "",
	tax_buyer_tax_number: "",
	ignore_auto_upgrade: 0,
	order_spent: 0,
	order_count: 0,
	order_lastdate: 0,
	order_lastid: 0,
	order_lastnumber: "",
	order_lastspent: 0,
	date_created: 0,
	datemodified_username: 0,
	datemodified_contact: 0,
	datemodified_email: 0,
	datemodified_phone: 0,
	datemodified_edit_user: 0,
	date_modified: 0,
	date_first_ordered: 0,
	date_last_ordered: 0,
	status: 0,
	isdeleted: 0,
	date_deleted: 0,
	total_collection: 0,
	avartar: FileModel.getDefaultData(),
	is_visitor: IS_VISTOR.NOT,
};

class CustomerModel extends BaseModel implements BaseModelJson<CustomerJson> {
	constructor(json: CustomerJson) {
		super();
		Object.assign(this, { ...defaultData, ...json });
	}

	static getDefaultData(): CustomerJson {
		return { ...defaultData };
	}

	toJson(): CustomerJson {
		return JSON.parse(JSON.stringify({ ...this }));
	}
}

export default CustomerModel;
