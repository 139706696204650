import BaseCollection from "@/common/collections/BaseCollection";
import { BaseCollectionJson } from "@/common/types/BaseCollection.type";

export enum CashflowreceiptsStatus {
	DRAFT = 1,
	PENDING,
	ACCEPT,
	SUCCSECC = 9,
	CANCEL = 11,
	NEW = 12,
}

export enum CashflowreceiptsDirection {
	RECEIPT = 5, // phiếu thu
	PAYMENT = 10, // phiếu chi
}
export enum CashflowreceiptsMethod {
	CASH = 1,
	TRASNFER = 3,
	COD = 11,
	VNPAY = 15,
	CARD = 19,
	VOUCHER = 9999,
}

export enum CashflowreceiptSource {
	ORDER = 1,
	DELIVERY = 11,
}

export enum CashflowreceiptTarget {
	TARGET_CASHFLOW_RECEIPT = 7, // biên lai dòng tiền
	CUSTOMER = 3, // khách hàng
	ANOTHER = 9,
}

// export enum CashflowreceiptsDirection {
// 	IN = 5,
// 	OUT = 10,
// }

export interface CashflowreceiptsCreateInput {
	status: CashflowreceiptsStatus;
	direction: CashflowreceiptsDirection;
	source_id: number | string;
	source: CashflowreceiptSource;
	value: number;
	target: CashflowreceiptTarget;
	target_id: number;

	method: CashflowreceiptsMethod;
	cashflow_group: CashflowGroup;
	store_id: number;

	name?: string;
	note?: string;
	identifier?: string;
	date_affected?: number;
	company_id?: number;
	creator_id?: number;
}

export type CashflowreceiptJson = {
	cashflow_group: string | number;
	company_id: number;
	creator_id: number;
	date_affected: number;
	date_created: number;
	date_modified: number;
	direction: string | number;
	id: number | string;
	identifier: string;
	method: number;
	name: string;
	note: string;
	source: number;
	source_id: number | string;
	status: CashflowreceiptsStatus;
	store_id: number;
	target: number;
	target_id: number;
	target_label: string;
	value: number;
};
export enum CashflowGroup {
	cashflow_group = 101, // bán hàng,
	GROUP_OUT_OTHER = 299, // khac
}

export type CashflowreceiptList = BaseCollectionJson<CashflowreceiptJson>;

export type CashflowFetchJson = {
	success: boolean;
	detail: {
		transStatus: ResCode; // Sử dụng kiểu phù hợp với dữ liệu thực sự thay vì `any`
		resCode: ResCode;
		// resDetail: {
		// 	resData: {
		// 		serviceName: string;
		// 		orderId: string;
		// 		posId: string;
		// 		amount: number;
		// 	};
		// 	resCode: number;
		// 	message: string;
		// 	merchantID: number;
		// };
	};
};

export type ResCode = {
	code: number;
	status: string;
	// description: string;
};

export type TranferBankInfor = {
	label: string;
	value: string;
	valueCopy?: string; // Dấu hỏi (?) để biểu thị rằng thuộc tính này là tùy chọn
};
