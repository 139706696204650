import { cn } from "@/utils/utils";

export type LabelFormProps = React.LabelHTMLAttributes<HTMLLabelElement> & {
	required?: boolean;
	show?: boolean;
	title?: React.ReactNode;
	addKeyTitle?: string;
};
const LabelForm = (props: LabelFormProps) => {
	const { required, show = true, title, ...p } = props;
	if (!show) {
		return null;
	}
	return (
		<label
			{...p}
			className={cn("text-sm leading-4 text-gray-400 ", props.className)}>
			{title ?? props.children}
			{required && <span className=" text-red-500"> *</span>}
		</label>
	);
};

export default LabelForm;
