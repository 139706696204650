const checkoutConstant = {
	POS: {
		ERRORS: {
			cashflow_create_failed: "cashflow_create_failed",
			delivery_create_failed: "delivery_create_failed",
			order_checkout_failed: "order_checkout_failed",
		},
	},
};

export default checkoutConstant;
