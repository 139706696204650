import { BaseCollectionJson } from "@/common/types/BaseCollection.type";
import { PricePromotionJson } from "./Cart.type";
import { CouponJson } from "./Coupon.type";

type PromotionBodyListJson = BaseCollectionJson<PromotionJson>;
type PromotionListJson = {
	data: PromotionResponse;
};
type PromotionResponse = {
	[key: string]: PromotionJson[];
};

type CampaignJson = {
	id: number;
	name: string;
	code: string;
	description: string;
	// sale_promotion_id: number;
};

// export enum PromotionType {
// 	CART,
// 	PRODUCT,
// }

export enum PromotionGroupType {
	coupon = "coupon",
	invoice = "invoice",
	seasonal = "seasonal",
}

export enum PromotionDiscountType {
	PRODUCT = "product",
	CART = "order",
}

export enum PromotionCalculateOnSalePrice {
	FINAL,
	ORIGINAL,
}
type PromotionJson = {
	id: number;
	status: boolean;
	create_date: number;
	req_subtotal: number;
	req_quantity: number;
	discount_value: number;
	discount_type: "product" | "order";
	req_productids: string;
	campaign_info: CampaignJson;
	priority: number;
	code?: string;
	is_use?: number;
	end_date: number;
	start_date?: number;
	type: number;
	type_time?: number;
	promotion_price: number;
	compare_discount: number;
	group: PromotionGroupType;
	req_collectionids: string;
	compare_discount_percent: number;
	// config?: ConfigDealthom;
	discount_value_type: "amount" | "percent";
	allow_multiple_codes: boolean;
	apply_with_other: boolean;
	max_applied: number;
	promotion_discount: number;
	label?: {
		name: string;
		name_card: string;
		bg_color?: string;
		text_color?: string;
	};
	codes?: CouponJson[];
	calculate_on_sale_price: PromotionCalculateOnSalePrice;
};

type SalePromotion = {
	id: number;
	type: number;
	invoice_value: number;
	prereq_category_id: number;
	promotion_type: number;
	product_discount: number;
	prereq_quantity: number;
	campaign_id: number;
	prereq_category_ids: number;
	prereq_apply_sameKind: boolean;
	prereq_product_ids: string;
	created_date: number;
	product_discount_ratio: number;
	product_ids_in: string;
	category_ids_in: string;
};

type PromotionFilter = {
	type?: "product" | "cart";
	product_ids?: string;
	product_category?: number;
	collection_id?: number;
	subtotal?: number;
};

type PromotionSale = {
	promotion: PromotionJson;
	pricePreview: PricePromotionJson;
};

export enum TypeTagConfig {
	VARIANT,
	CARD,
}

interface TagPromotionInfo {
	name: string;
	bg_color: string;
	text_color: string;
	type: TypeTagConfig;
}
type ConfigDealthom = {
	code: string;
	promotion_id: number[];
	tag_infor: TagPromotionInfo[];
};

type PromotionListFull = PromotionBodyListJson;
export type {
	PromotionJson,
	TagPromotionInfo,
	SalePromotion,
	PromotionFilter,
	PromotionListJson,
	CampaignJson,
	PromotionResponse,
	PromotionSale,
	PromotionBodyListJson,
	PromotionListFull,
	ConfigDealthom,
};
