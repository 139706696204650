"use client";

import React, { useId } from "react";

import { Device } from "@/types/Shop.type";

import Slider from "@/components/Slider";
// import Image from "@/components/ui/Image";
import LinkElement from "@/components/LinkElement";
import ImageConstant from "@/common/constants/image";
import DelayedImage from "@/components/DelayImage";
import Image from "next/image";
import ImageV2 from "@/components/ui/ImageV2";

export type BannerHomeData = {
	id: number;
	image: string;
	image_mobile: string;
	title: string;
	link: string;
	width: number;
	height: number;
	alt: string;
	from_time?: number;
	to_time?: number;
};

type Props = {
	data: BannerHomeData[];
	device: Device;
	id: string;
};

const BannerSlide = ({ data, device, id }: Props) => {
	const isMobile = device === "mobile";
	//p
	const handleRender = (item: BannerHomeData, index: number) => {
		return (
			<LinkElement
				className="w-screen max-w-[2500px]  block relative"
				href={item.link.length > 0 ? item.link : "#"}
				key={index}>
				{device === "desktop" && (
					<ImageV2
						alt={item.alt}
						src={item.image}
						width={100}
						height={52}
						layout="responsive"
						sizes="100vw"
						placeholder={"blur"}
						blurDataURL={ImageConstant.BlurDataURL}
						priority
						//   className={`w-full ${!isMobile ? "h-auto" : ""}`}
					/>
				)}

				{device === "mobile" && (
					<ImageV2
						alt={item.alt}
						src={item.image_mobile}
						layout="responsive"
						width={76}
						height={76}
						sizes="100vw"
						placeholder={"blur"}
						blurDataURL={ImageConstant.BlurDataURL}
						priority
						//   className={`w-full ${!isMobile ? "h-auto" : ""}`}
					/>
				)}
			</LinkElement>
		);
	};

	return (
		<div
			className="bannerhome__wrapper overflow-hidden mx-auto max-w-[2500px]"
			id={id}>
			{/* <Carousel
        data={data}
        itemSlider={handleRender}
        breakpoints={{
          0: { slidesPerView: 1, spaceBetween: 0 },
          640: { slidesPerView: 1, spaceBetween: 0 },
          768: { slidesPerView: 1, spaceBetween: 0 },
          1024: { slidesPerView: 1, spaceBetween: 0 },
          1200: { slidesPerView: 1, spaceBetween: 0 },
        }}
        loop={true}
        centeredSlides={{ desktop: false, mobile: false }}
        navigation={true}
        autoplay={true}
        autoplayParameters={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        speed={500}
        slidesPerGroup={1}
      /> */}
			<Slider
				id={useId()}
				dataSource={data}
				isloading={false}
				itemRender={handleRender}
				navigation={true}
				speed={500}
				loop={true}
				pagination={{ clickable: true }}
				autoplay={true}
				slidesPerGroup={1}
				slidesPerView={1}
				allowSlideNext={true}
				allowSlidePrev={true}
				freeMode={false}
				allowTouchMove={true}
				centeredSlidesDesktop={false}
				centeredSlidesMobile={true}
				classButtonSlide="hidden"
				responsive={{
					xxs: { slidesPerView: 1, spaceBetween: 0 },
					xs: { slidesPerView: 1, spaceBetween: 0 },
					sm: { slidesPerView: 1, spaceBetween: 0 },
					md: { slidesPerView: 1, spaceBetween: 0 },
					lg: { slidesPerView: 1, spaceBetween: 0 },
					xl: { slidesPerView: 1, spaceBetween: 0 },
				}}
			/>
		</div>
	);
};

export default BannerSlide;
