import BaseApi from "@/common/api/BaseApi";
import CouponCollection from "@/common/collections/CouponCollection";
import { FilterCoupon } from "@/common/types/Coupon.type";
import { callApiV2 } from "@/lib/config/Api";
import {
	AddCouponProps,
	CouponCheckJson,
	CouponCheckJsonAdd,
	CouponCollectionJson,
} from "@/types/Coupon.type";
import { OrderJson } from "@/types/Orders.type";
import Helper from "@/utils/helper";

export async function getCouponList(filters: FilterCoupon) {
	const convertProps = Helper.convertParams(filters);
	const queryData = {
		params: {
			...convertProps,
		},
	};
	const response = await callApiV2<CouponCollectionJson>(
		`/customers/coupon`,
		"get",
		queryData
	);

	return response;
}

export async function checkCoupon(props: CouponCheckJsonAdd) {
	const res = await callApiV2<CouponCheckJson[]>(
		"/promotioncoupons/public/validate",
		"post",
		{ data: props }
	);
	return res;
}

export async function checkPromotionCoupon(props: CouponCheckJsonAdd) {
	const res = await callApiV2<CouponCheckJson>(
		"/promotioncoupons/public/validate",
		"post",
		{ data: props }
	);
	return res;
}

export async function addCouponUser(props: AddCouponProps) {
	const res = await callApiV2<OrderJson>(
		`/orders/cart/coupon/${props.order_id}`,
		"put",
		{
			data: props,
		}
	);
	return res;
}

export async function addCouponPublic(props: AddCouponProps) {
	const res = await callApiV2<OrderJson>(
		`/orders/cart/public/coupon/${props.order_id}`,
		"put",
		{
			data: props,
		}
	);
	return res;
}

export async function getCouponPublicList(promotionId: number) {
	const res = await callApiV2<CouponCollectionJson>(
		"/promotioncoupons/public",
		"get",
		{
			params: {
				promotion_id: promotionId,
			},
		}
	);
	return res;
}
