import BaseApi from "@/common/api/BaseApi";
import { callApiV2 } from "@/lib/config/Api";
import { LocationCollectionJson, LocationJson } from "@/types/Location.type";
import Helper from "@/utils/helper";

export async function getListLocation({
	type,
	parent_id,
	list_ids,
}: {
	type?: number;
	parent_id?: number;
	list_ids?: string;
}) {
	let queryData = {
		params: Helper.convertParams({
			type: type,
			parent_id: parent_id ? parent_id : null,
			list_ids: list_ids || "",
		}),
	};

	const response = await callApiV2<LocationCollectionJson>(
		`/locations/public/type`,
		"get",
		queryData
	);

	return response;
}

export async function getDetailsLocation({ id }: { id: number }) {
	const response = await callApiV2<LocationJson>(`/locations/${id}`, "get");

	return response;
}
