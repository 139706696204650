import { KEY_LOCAL_POS_JWT } from "@/lib/hooks/useSessionPos";
import { LoginJson } from "@/types/Auth.type";

type CookiePos = {
	name: string;
	path: "/";
	value: string;
};
export async function getSessionPos() {
	try {
		const session = await fetch("/api/session/pos", {
			method: "get",
		});

		const response = (await session.json()) as { token: CookiePos };
		const data = JSON.parse(
			decodeURIComponent(response.token.value)
		) as LoginJson;
		return data;
	} catch (error) {
		return null;
	}
}

export async function clearSessionPos() {
	try {
		localStorage.removeItem(KEY_LOCAL_POS_JWT);
		await fetch("/api/session/pos/clear", {
			method: "get",
		});
	} catch (error) {}
}
