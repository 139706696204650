import Helper from "@/utils/helper";
import React, { useEffect, useState } from "react";

type Data<D> = {
	data: D | null;
	setLocal: (value: D) => void;
	setRefetch: () => void;
};
const useLocalStorage = <D,>({ key }: { key: string }): Data<D> => {
	const [data, setData] = useState<D | null>(null);
	const [reFetch, setRefetch] = useState<boolean>(false);

	const setLocal = (value: D) => {
		if (!Helper.isServer()) {
			setData(value);
			localStorage.setItem(key, JSON.stringify(value));
		}
	};

	const reGetData = () => {
		setRefetch((prev) => !prev);
	};

	useEffect(() => {
		if (!Helper.isServer()) {
			const dataLocal = localStorage.getItem(key);
			if (dataLocal) {
				const dataLocalParse = JSON.parse(dataLocal) as D;
				setData(dataLocalParse);
			}
		}
	}, [reFetch]);
	return {
		data: data,
		setLocal,
		setRefetch: reGetData,
	};
};

export default useLocalStorage;
