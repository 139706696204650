"use client"
import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Backdrop } from '../Backdrop';

// Define the context
interface BackdropContextType {
  showBackdrop: () => void;
  hideBackdrop: () => void;
  isVisible: boolean;
}

// Create the context
const BackdropContext = createContext<BackdropContextType | undefined>(undefined);

// Provider component
export const BackdropProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);

  const showBackdrop = () => setIsVisible(true);
  const hideBackdrop = () => setIsVisible(false);

  return (
    <BackdropContext.Provider value={{ showBackdrop, hideBackdrop, isVisible }}>
      {children}
      <Backdrop />
    </BackdropContext.Provider>
  );
};

// Custom hook to use backdrop
export const useBackdrop = () => {
  const context = useContext(BackdropContext);
  if (!context) {
    throw new Error('useBackdrop must be used within a BackdropProvider');
  }
  return context;
};
