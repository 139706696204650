import { useQuery } from "@tanstack/react-query";
import { getCustomer } from "../api/account/customer";
import { useSession } from "next-auth/react";
import { useEffect, useState } from "react";

export default function useCustomer() {
	const { data, status } = useSession();
	const [isLoading, setIsLoading] = useState(false);
	const idSecction = data?.user?.company?.customer?.id;

	const query = useQuery({
		queryKey: ["customer", idSecction],
		queryFn: async () => {
			try {
				setIsLoading(true);
				if (idSecction) {
					const { data: cus } = await getCustomer(idSecction);
					return cus;
				}
			} catch (error) {
			} finally {
				setIsLoading(false);
			}
		},
		staleTime: 1000 * 60,
		enabled: !!idSecction,
		retry: 3,
	});
	useEffect(() => {
		if (
			query.isSuccess ||
			query.isError ||
			status === "unauthenticated" ||
			!idSecction
		) {
			setIsLoading(false);
		}
	}, [query.isLoading, status]);

	return {
		...query,
		isLoading: isLoading,
	};
}
