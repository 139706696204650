"use client";

import * as React from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { DayPicker } from "react-day-picker";
import { cn } from "@/utils/utils";

export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
	onConfirm?: () => void;
};

function Calendar({
	className,
	classNames,
	showOutsideDays = true,
	onConfirm,
	...props
}: CalendarProps) {
	return (
		<DayPicker
			showOutsideDays={showOutsideDays}
			className={cn("p-3 cursor-pointer", className)}
			classNames={{
				months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
				month: "space-y-4",

				caption: "flex justify-between pt-1 relative items-center gap-2 px-2",
				caption_label: "text-sm font-medium capitalize",
				caption_dropdowns: "flex justify-center gap-2 text-base font-medium",
				nav: "space-x-1 flex items-center",
				nav_button: "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100",
				table: "w-full border-collapse space-y-2",
				head_row: "flex",
				head_cell:
					"text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]",
				row: "flex w-full mt-2",
				cell: "h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
				day: "h-9 w-9 p-0 font-normal aria-selected:opacity-100 rounded",
				day_range_end: "day-range-end",
				day_selected:
					"bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
				day_today: "bg-accent text-accent-foreground",
				day_outside:
					"day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30",
				day_disabled: "text-muted-foreground opacity-50",
				day_range_middle:
					"aria-selected:bg-accent aria-selected:text-accent-foreground",
				day_hidden: "invisible",
				...classNames,
			}}
			components={{
				IconLeft: () => <ChevronLeft className="h-4 w-4" />,
				IconRight: () => <ChevronRight className="h-4 w-4" />,
				Footer: () => {
					if (!onConfirm) {
						return null;
					}
					return (
						<div className=" bottom-0 left-0">
							<button
								type="button"
								onClick={() => {
									onConfirm();
								}}
								className="py-1 px-2 bg-black text-white text-sm rounded-sm">
								Xác nhận
							</button>
						</div>
					);
				},
			}}
			{...props}
		/>
	);
}
Calendar.displayName = "Calendar";

export { Calendar };
