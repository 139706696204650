"use client";
import { useController, FieldValues, FieldPath } from "react-hook-form";
import { PropsController } from "@/types/Component.type";
import ErrorControl from "./components/ErrorControl";
type ValueOptionType = number | string;

export interface Option {
	label: string;
	value: ValueOptionType;
	disable?: boolean;
	className?: string;
}

export type SelectCoreProps<
	TFieldValues extends FieldValues,
	TName extends FieldPath<TFieldValues>
> = React.InputHTMLAttributes<HTMLSelectElement> &
	PropsController<TFieldValues, TName> & {
		options: Option[];
		placeholder?: string;
	};

function SelectControl<
	TFieldValues extends FieldValues,
	TName extends FieldPath<TFieldValues>
>(props: SelectCoreProps<TFieldValues, TName>) {
	const { options, name, control, rules, placeholder, required, ...res } =
		props;
	const { field, fieldState } = useController({ name, control, rules });
	return (
		<>
			<select
				{...field}
				{...res}
				disabled={props.disabled}
				onChange={(e) => {
					props.onChange?.(e);
					if (typeof options[0].value === "number") {
						field.onChange(Number(e.target.value));
						return;
					}
					field.onChange(e.target.value);
				}}
				value={field.value ?? placeholder}>
				{placeholder && (
					<option disabled value={placeholder}>
						{placeholder}
					</option>
				)}
				{options.map((item) => {
					return (
						<option
							{...item}
							value={item.value}
							key={item.value}
							title={item.label}>
							{item.label}
						</option>
					);
				})}
			</select>
			<ErrorControl showError={required} error={fieldState.error} />
		</>
	);
}

export default SelectControl;
