"use client";
import React from "react";

import PopupV2 from "./PopupV2";

type Props = {
	open: boolean;
	isMask?: boolean;
	// classNameWrapper?: string;
	classNameBody?: string;
	// classNameContent?: string;
	// classNameButton?: string;
	children: React.ReactNode;
	destroyOnClose?: boolean;
	onClose: () => void;
	closeOnMark?: boolean;
	isDrawerMobile?: boolean;
	closeButton?: React.ReactNode;
};

const PopupDefault = ({
	open,
	onClose,
	classNameBody = "",
	children,
	closeOnMark = true,
	isDrawerMobile,
	closeButton,
}: Props) => {
	return (
		<PopupV2
			closeButton={closeButton}
			open={open}
			onOpenChange={() => {
				onClose?.();
			}}
			classNameContent={classNameBody}
			closeOnMark={closeOnMark}
			classNameDrawerMobile={classNameBody}
			isDrawerMobile={isDrawerMobile}>
			{children}
		</PopupV2>
	);
};

export default PopupDefault;
