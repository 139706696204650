"use client";

import { QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { getPromotionsFull } from "../api/shop/promotion";
import Cart from "@/common/constants/cart";
import { PromotionJson } from "@/types/Promotion.type";
import { getTimeServer } from "../api/shop/setting";
import SettingApi from "@/common/constants/setting";
import { getPromotionValid } from "@/utils/product";

const getTypePromotion = (item: PromotionJson, timeNow: number): number => {
	const startDate = Number(item?.start_date);
	const endDate = Number(item?.end_date);
	if (isNaN(startDate) || isNaN(endDate)) {
		// Handle invalid date values if necessary
		throw new Error("Invalid promotion date");
	}
	if (startDate > timeNow) {
		return Cart.PROMOTION_TYPE.PENDING;
	}

	if ((startDate <= timeNow && endDate >= timeNow) || endDate === 0) {
		return Cart.PROMOTION_TYPE.PROCESSING;
	}

	if (endDate < timeNow) {
		return Cart.PROMOTION_TYPE.DONE;
	}

	return 0; // Default case, though ideally it should not reach here
};
export default function usePromotions(
	filter: {
		isOnlyValid?: boolean;
	} = {},
	options?: Omit<
		UseQueryOptions<PromotionJson[], any, PromotionJson[], QueryKey>,
		"queryKey" | "queryFn"
	>
) {
	return useQuery({
		queryKey: ["promotions", JSON.stringify(filter)],
		queryFn: async () => {
			const { isOnlyValid } = filter;
			const { data } = await getTimeServer(SettingApi.KEY.k_time_server);
			const currentItem = data?.time_server
				? data.time_server
				: new Date().getTime() / 1000;
			const { data: promotions } = await getPromotionsFull();

			const pros = promotions.items.map((item) => {
				return {
					...item,
					type_time: getTypePromotion(item, currentItem),
				};
			});

			if (isOnlyValid) {
				return getPromotionValid(pros, currentItem);
			}
			return pros;
		},
		staleTime: Infinity,
		refetchInterval: 60 * 1000 * 2, //2minutes
		...options,
	});
}
