import { BaseCollectionJson } from "@/common/types/BaseCollection.type";
import { callApiV2 } from "@/lib/config/Api";


interface ShippingCarriers {
  company_id: number,
  creator_id: number,
  id: number,
  name: string,
  description: string,
  status: number,
  display_order: number,
  api_type: number,
  detail: "",
  date_created: number,
  date_modified: number,
  api_setting: null
}
export async function getHomeDeliveries(params?: any) {

  const res = await callApiV2<BaseCollectionJson<ShippingCarriers>>("/shippingcarriers", "get" , {
    params : params
  })

  return res
}
export async function getHomeDelivery(id : number) {

  const res = await callApiV2<ShippingCarriers>(`/shippingcarriers/${id}`, "get")

  return res
}