"use client";
import { useRouter, useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";

import CustomerConstants from "@/common/constants/customer";
import Loyalty from "@/common/constants/loyalty";

import Countdown from "@/components/Countdown";
import Button from "@/components/ui/Button";
import InputBorder from "@/components/ui/InputBorder";
import { getSettingV2 } from "@/lib/api/shop/setting";
import { useAppDispatch } from "@/redux/hook";
// import AuthTitle from "@/app/account/_components/authTitle/AuthTitle";
// import NTVerifyOTPHeader from "@/app/account/_components/profile/NTVerifyOTPHeader";
import { senderNewsLetter, verifyNewsLetter } from "@/lib/api/shop/newsletter";
import { setErrorsAuth } from "@/redux/features/LoyaltySlice";
import SettingApi from "@/common/constants/setting";
import AuthTitle from "@/app/account/(auth)/_components/authTitle/AuthTitle";
import NTVerifyOTPHeader from "@/app/account/(private)/_components/profile/NTVerifyOTPHeader";
import { getDataError } from "@/lib/config/apiHandler";
import CountdownOTP from "@/components/ui/CountdownOTP";
import useTranslationApi from "@/lib/hooks/useTranslationApi";

type IFormInput = {
	otp: string;
};

type Props = {
	type: number;
	account_id: string;
	typeAccountid?: string;
	handleSubmitSuccess?: any;
	navigateHeaderCustom?: () => void;
	styleWrapper?: React.CSSProperties;
};

const NTVerifyOTPNewsLetter = ({
	type,
	typeAccountid,
	account_id,
	handleSubmitSuccess,
	navigateHeaderCustom,
	styleWrapper,
}: Props) => {
	const { t } = useTranslationApi([
		"is_establist_pw2",
		"forgot_password_heading",
		"register_heading",
		"otp_btn_text",
		"otp_btn_refresh",
		"customer_ERROR_OTP_INVALID",
		"error_required",
		"error_otp_required",
		"change_info_heading",
		"customer_ERROR_OTP_INVALID",
		"loading_page",
		"newsletter_title",
		...CustomerConstants.ERROR_TRANSLATE,
	]);
	//////////////////////////
	const {
		handleSubmit,
		setError: setErrorsForm,
		getValues,
		setValue,
		formState: { errors: errorsForm },
		control,
		clearErrors,
		reset,
	} = useForm<IFormInput>({
		reValidateMode: "onChange",
		mode: "onSubmit",
		defaultValues: {
			otp: "",
		},
	});
	const router = useRouter();
	const searchParams = useSearchParams();
	const dispatch = useAppDispatch();
	const [cookies, setCookies, removeCookies] = useCookies(["used"]);

	/////////////////////////
	//state
	const [errors, setErrors] = useState<string[]>([]);
	// const [errorForm, setErrorForm] = useState<string[]>([]);

	const [loading, setLoading] = useState<boolean>(false);
	const [senderLoading, setSenderLoading] = useState<boolean>(false);
	const [title, setTitle] = useState<string>("");
	//paramURL
	const [seconds, setSeconds] = useState<number>(0);
	const [countOTP, setCountOTP] = useState<number>(0);
	const [contactType, setContactType] = useState<string>(
		searchParams.get("contact_type") || ""
	);
	const [limitOTP, setLimitOTP] = useState<number>(Loyalty.VERIFY_LOCK.QUOTA);
	const [accountId, setAccountId] = useState<string>(account_id || "");

	const [quotaOTP, setQuotaOTP] = useState(5);
	const [status] = useState(searchParams.get("status") || "");
	const [code, setCode] = useState(searchParams.get("code") || "");
	const [tax, setTax] = useState("");

	//load status
	const [isResend, setIsResend] = useState<boolean>(false);
	const [isSubmit, setIsSubmit] = useState<boolean>(false);

	/////////////////////////
	//functions

	const handleGetSetting = async () => {
		try {
			const link_login = await getSettingV2(
				SettingApi.LOYALTY.setting_limit_otp
			);
			setLimitOTP(Number(link_login.data.value));
		} catch (error) {
			setErrors(["error_get_setting"]);
		}
	};

	const handleSender = async () => {
		setLoading(true);
		setSenderLoading(true);
		try {
			const res = await senderNewsLetter({
				newsletter_contact_id: accountId.trim(),
				time: Math.floor(Math.random() * 100),
			});
			const response = res.data;
			setQuotaOTP(response.otp.otp_quota);
			setSeconds(response.otp.otp_timeout);
			setCountOTP(response.otp.otp_used);

			setCookies("used", response.otp.otp_used, { path: "/" });
		} catch (err) {
			const convertError = getDataError(err);
			if (
				convertError.errors.some((err: string) =>
					err.includes(CustomerConstants.ERRORS.ERROR_OTP_ALREADY)
				)
			) {
				setCountOTP(cookies["used"]);
				// setQuotaOTP(cookies["quota"]);
				setQuotaOTP(limitOTP);
				setSeconds(convertError.time || 0);
			} else if (
				convertError.errors.some((err: string) =>
					err.includes(CustomerConstants.ERRORS.ERROR_OVER_LIMIT)
				)
			) {
				setErrors(
					convertError.errors.map((item: string) => item.toUpperCase())
				);
				setQuotaOTP(limitOTP);
				setCountOTP(limitOTP);
			} else if (
				convertError.errors.includes(
					CustomerConstants.ERRORS.ERROR_TIMECHANGE_USERNAME
				)
			) {
				dispatch(
					setErrorsAuth({
						errors: [CustomerConstants.ERRORS.ERROR_TIMECHANGE_USERNAME],
					})
				);
				router.push("/account");
			} else if (
				convertError.errors.includes(
					CustomerConstants.ERRORS.ERROR_TIMECHANGE_EMAIL
				)
			) {
				dispatch(
					setErrorsAuth({
						errors: [CustomerConstants.ERRORS.ERROR_TIMECHANGE_EMAIL],
					})
				);
				router.push("/account");
			} else if (
				convertError.errors.includes(
					CustomerConstants.ERRORS.ERROR_TIMECHANGE_PHONE
				)
			) {
				dispatch(
					setErrorsAuth({
						errors: [CustomerConstants.ERRORS.ERROR_TIMECHANGE_PHONE],
					})
				);
				router.push("/account");
			} else if (
				convertError.errors.includes(CustomerConstants.ERRORS.ERROR_TIMECHANGE)
			) {
				dispatch(
					setErrorsAuth({
						errors: [CustomerConstants.ERRORS.ERROR_TIMECHANGE],
					})
				);
				router.push("/account");
			} else {
				setErrors(
					convertError.errors.map((item: string) => item.toUpperCase())
				);
			}
		}
		setLoading(false);
		setSenderLoading(false);
	};

	const onVerify = async (formData: IFormInput) => {
		setIsSubmit(true);
		setLoading(true);
		if (seconds > 0) {
			try {
				const response = await verifyNewsLetter({
					contact_id: accountId.trim(),
					otp: formData.otp.trim(),
				});
				if (response.data) {
					handleSubmitSuccess && handleSubmitSuccess();
					setIsSubmit(false);
				}
			} catch (err) {
				const convertError = getDataError(err);
				setIsSubmit(false);
				if (
					convertError.errors.includes(
						CustomerConstants.ERRORS.ERROR_TIMECHANGE_USERNAME
					)
				) {
					dispatch(
						setErrorsAuth({
							errors: [CustomerConstants.ERRORS.ERROR_TIMECHANGE_USERNAME],
						})
					);
					router.push("/account");
				} else if (
					convertError.errors.includes(
						CustomerConstants.ERRORS.ERROR_TIMECHANGE_EMAIL
					)
				) {
					dispatch(
						setErrorsAuth({
							errors: [CustomerConstants.ERRORS.ERROR_TIMECHANGE_EMAIL],
						})
					);
					router.push("/account");
				} else if (
					convertError.errors.includes(
						CustomerConstants.ERRORS.ERROR_TIMECHANGE_PHONE
					)
				) {
					dispatch(
						setErrorsAuth({
							errors: [CustomerConstants.ERRORS.ERROR_TIMECHANGE_PHONE],
						})
					);
					router.push("/account");
				} else if (
					convertError.errors.includes(
						CustomerConstants.ERRORS.ERROR_TIMECHANGE
					)
				) {
					dispatch(
						setErrorsAuth({
							errors: [CustomerConstants.ERRORS.ERROR_TIMECHANGE],
						})
					);
					router.push("/account");
				} else {
					if (
						convertError.errors.includes(
							CustomerConstants.ERRORS.ERROR_OTP_INVALID
						)
					) {
						setErrorsForm("otp", {
							message: "Mã xác thực chưa đúng. Vui lòng nhập lại.",
						});
					} else {
						setErrors(convertError.errors);
					}
				}
			}
		}

		setLoading(false);
	};

	const handleRefresh = async () => {
		setErrors([]);
		setIsResend(true);
		dispatch(setErrorsAuth({ errors: [] }));
		clearErrors();
		reset();
		setLoading(true);
		try {
			const res = await senderNewsLetter({
				newsletter_contact_id: accountId.trim(),
				time: Math.floor(Math.random() * 100),
			});
			const response = res.data;
			setErrors([]);
			setSeconds(response.otp.otp_timeout);
			setCountOTP(response.otp.otp_used);
			setQuotaOTP(response.otp.otp_quota);
			setCookies("used", response.otp.otp_used, { path: "/" });
			setIsResend(false);
			setIsSubmit(false);
		} catch (err: any) {
			const convertError = err.response.data;
			setErrors(convertError.error.map((item: string) => item.toUpperCase()));
		}
		setLoading(false);
	};

	const handleFinishCountdown = () => {
		clearErrors();
		setErrors([]);
		setSeconds(0);
		setIsResend(false);
		setIsSubmit(false);
	};

	//
	const handleFinishFailed = () => {
		// if (errorsForm.otp && errorsForm.otp.message) {
		//   setErrorForm([errorsForm.otp.message]);
		// }
	};

	/////////////////////////
	//fetch title

	useEffect(() => {
		if (type === Loyalty.VERIFY.TYPE_CUSTOMER_CREATEPASSWORD) {
			setTitle(t("is_establist_pw2"));
		} else if (
			type === Loyalty.VERIFY.TYPE_CUSTOMER_ACTIVE &&
			status === Loyalty.STATUS_CUSTOMER.CUSTOMER_UNACTIVATED_FORGOT
		) {
			setTitle(t("forgot_password_heading"));
		} else if (type === Loyalty.VERIFY.TYPE_CUSTOMER_ACTIVE) {
			setTitle(t("register_heading"));
		} else if (type === Loyalty.VERIFY.TYPE_CUSTOMER_FORGOTPASSWORD) {
			setTitle(t("forgot_password_heading"));
		} else if (type === Loyalty.VERIFY.TYPE_CUSTOMER_CHANGECONTACT) {
			setTitle(t("change_info_heading"));
		} else if (type === CustomerConstants.NTACCOUNTID.TYPE_CHANGE_EMAIL) {
			setTitle(t("change_info_heading"));
		} else if (type === CustomerConstants.SENDER_TYPE.VERIFY) {
			setTitle(t("change_info_heading"));
		} else if (type === CustomerConstants.SENDER_TYPE.NEWSLETTER) {
			setTitle(t("newsletter_title"));
		}
	}, [type, status, t]);
	/////////////////////////

	useEffect(() => {
		// if (accountId.length > 0) {
		setAccountId(account_id);
		// }
	}, [account_id]);

	useEffect(() => {
		handleGetSetting();
	}, []);

	useEffect(() => {
		if (account_id.length > 0) {
			handleSender();
		}
	}, []);

	/////////////////////////

	return (
		<>
			<div className="w-full my-[24px] max-w-[396px] mx-auto">
				<div className="auth_verify">
					{/* {contextHolder} */}
					<div className="">
						<AuthTitle
							showArrow={false}
							title={title}
							navigateCustom={navigateHeaderCustom}></AuthTitle>
						{senderLoading ? (
							<>
								<div className="flex justify-center items-center">
									<div className="text-center mb-[40px]">
										<p className="text-gray-500">{t("loading_page")}</p>
									</div>
								</div>
							</>
						) : (
							<>
								<div className="text-sm text-center text-gray-500">
									<NTVerifyOTPHeader
										seconds={seconds}
										type={type.toString()}
										status={searchParams.get("status") || ""}
										used={countOTP}
										quota={quotaOTP}
										account_id={accountId}
										isResend={isResend}
										isSubmit={isSubmit}
										error={errors}
										tax={tax}
									/>
								</div>
								<form onSubmit={handleSubmit(onVerify, handleFinishFailed)}>
									<InputBorder
										inputClassName="text-center"
										className="border w-full text-center py-[8px] max-h-[34px] rounded text-sm mb-4"
										placeholder={t("error_otp_required")}
										name="otp"
										showError={false}
										control={control}
										rules={
											seconds > 0
												? {
														required: {
															value: true,
															message: t("error_required"),
														},
												  }
												: {}
										}
									/>

									<Button
										type="submit"
										className="w-full"
										mode="dark"
										// disabled={loading}
										// loading={loading}
										label={
											<div className="flex justify-center items-center">
												{/* <span> */}
												<span className="mr-[2px]">
													{t("otp_btn_text") + " "}
												</span>{" "}
												{seconds > 0 && (
													<>
														{" ("}
														<CountdownOTP
															initialSeconds={seconds}
															onFinish={() => {
																handleFinishCountdown();
															}}
														/>{" "}
														{" )"}
													</>
												)}
												{/* </span> */}
											</div>
										}
										onClick={() => {
											setErrorsForm(
												"otp",
												{ type: "focus" },
												{ shouldFocus: true }
											);
										}}></Button>
									{/* {errorForm.length > 0 &&
              seconds > 0 &&
              (errorForm.includes(Loyalty.ERRORS.ERROR_OTP_EMPTY) ||
                errorForm.includes(Loyalty.ERRORS.ERROR_OTP_INVALID)) ? (
                <p className="text-sm text-red-500 mt-4">{errorForm[0]}</p>
              ) : null} */}
									{errorsForm.otp && (
										<p className="text-red-500 text-sm mt-4 absolute">
											{errorsForm.otp.message}
										</p>
									)}

									{/* {(errors[0] !== Loyalty.ERRORS.ERROR_OTP_ALREADY ||
                errors[0] !== Loyalty.ERRORS.ERROR_OVER_LIMIT) && ( */}
									<button
										type="button"
										className="bg-transparent text-[#004b8f] border-none outline-none text-sm font-medium mt-4 text-right float-right"
										disabled={seconds > 0 || countOTP === 0}
										onClick={(e) => {
											e.preventDefault();
											handleRefresh();
										}}>
										{t("otp_btn_refresh")}{" "}
										{countOTP > 0 ? `(${countOTP}/${quotaOTP})` : ""}
									</button>
									{/* )} */}
								</form>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};
export default NTVerifyOTPNewsLetter;
