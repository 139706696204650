"use client";
import usePromotions from "@/lib/hooks/usePromotions";
import { formatVietnameseCurrency, getRemainingTime } from "@/utils/utils";
import React, { useState } from "react";
import BgPromotionItem from "./BgPromotionItem";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/Tooltip";
import moment from "moment";
import { PromotionGroupType, PromotionJson } from "@/types/Promotion.type";
import { LoadingIcon } from "@/components/icons";
import useGetCodePromotion from "@/lib/hooks/useGetCodePromotion";
import Money from "@/components/ui/Money";
import PromotionConditionInfo from "./PromotionConditionInfo";

export default function PromotionListView({
	promotions,
}: {
	promotions: PromotionJson[];
}) {
	// const { data: promotions, isLoading, isSuccess } = usePromotions({ isOnlyValid: true })

	// if (isLoading) {
	//     return <p>
	//         <LoadingIcon />
	//         Đang tải khuyến mãi
	//     </p>
	// }
	if (!promotions.length) {
		return null;
	}
	return (
		<div className=" flex gap-4">
			<span className=" flex-shrink-0">Ưu đãi</span>
			<ul className=" flex  gap-4 flex-wrap">
				{promotions.map((promotion) => {
					return <PromotionListViewItem promotion={promotion} />;
				})}
			</ul>
		</div>
	);
}

function PromotionListViewItem({ promotion }: { promotion: PromotionJson }) {
	const { data: codes, isFetched } = useGetCodePromotion(promotion.id, {
		enabled: promotion.group === PromotionGroupType.coupon,
	});

	return (
		<Tooltip placement="bottom">
			<TooltipTrigger>
				{promotion.discount_value_type === "amount" ? (
					<BgPromotionItem>
						<span className=" text-red-500 font-semibold">
							{formatVietnameseCurrency(promotion.discount_value)}
						</span>
					</BgPromotionItem>
				) : (
					<BgPromotionItem>
						<span className=" text-red-500 font-semibold">
							{promotion.discount_value}%
						</span>
					</BgPromotionItem>
				)}
			</TooltipTrigger>
			<TooltipContent className="Tooltip">
				<div className="overflow-y-auto  text-gray-500  no-scrollbar  w-full md:w-[400px] h-auto bg-white rounded-lg   shadow-2xl   p-5">
					<ul>
						<li className=" flex">
							<p className="  min-w-[140px] text-gray-400">CT khuyến mãi:</p>{" "}
							<p className=" line-clamp-2 text-red-500 font-bold">
								{promotion.campaign_info.name}
							</p>
						</li>
						<li className=" flex">
							<p className="  min-w-[140px] text-gray-400">
								Thời gian áp dụng:
							</p>{" "}
							<p>
								{promotion.start_date
									? `${moment(promotion.start_date * 1000).format(
											"HH:mm DD/MM/"
									  )} - ${moment(promotion.end_date * 1000).format(
											"DD/MM/YYYY"
									  )}`
									: null}
							</p>
						</li>
						<li className=" flex">
							<p className="  min-w-[140px] text-gray-400">Còn lại:</p>{" "}
							<p className=" font-bold">{getRemainingTime(promotion)} </p>
						</li>

						<li className=" flex">
							<p className="  min-w-[140px] text-gray-400">Giảm:</p>
							{codes && codes.length ? (
								<li>
									Nhập code {codes.map((code) => code.code).join(",")} giảm ngay{" "}
									{promotion.discount_value_type === "amount" ? (
										<Money
											className=" font-bold"
											value={promotion.discount_value}
										/>
									) : (
										<span className=" font-bold">
											{promotion.discount_value}%
										</span>
									)}{" "}
									trên giá trị{" "}
									{promotion.discount_type === "order"
										? "Đơn hàng"
										: "Sản phẩm"}
								</li>
							) : null}
							{promotion.group === PromotionGroupType.seasonal ? (
								<li>
									{" "}
									{promotion.discount_value_type === "amount" ? (
										<Money
											className=" font-bold"
											value={promotion.discount_value}
										/>
									) : (
										<span className=" font-bold">
											{promotion.discount_value}%
										</span>
									)}{" "}
									trên giá trị{" "}
									{promotion.discount_type === "order"
										? "Đơn hàng"
										: "Sản phẩm"}
								</li>
							) : null}
						</li>
					</ul>
					<p className=" text-gray-400">Chi tiết:</p>
					<div className=" pl-6 text-gray-500">
						<PromotionConditionInfo promotion={promotion} />
					</div>
				</div>
			</TooltipContent>
		</Tooltip>
	);
}
