import { createSlice } from "@reduxjs/toolkit";
import { ConfigDealthom, PromotionJson } from "@/types/Promotion.type";
import { RootState } from "../store";

type InitialState = {
	promotion: PromotionJson | null;
	promotionAll: PromotionJson[];

	promotionCouponItem: PromotionJson[];

	promotionCouponOrder: PromotionJson[];

	promotionSeasonalOrder: PromotionJson[];

	promotionSeasonalItem: PromotionJson[];

	promotionCanUses: PromotionJson[];
	promotionCouponOnItems: PromotionJson[];
	settingDealthom: ConfigDealthom[] | null;
};
const initialState: InitialState = {
	promotionAll: [],
	promotionCouponOrder: [],
	promotionCouponItem: [],
	promotionSeasonalOrder: [],
	promotionSeasonalItem: [],

	promotion: null,
	promotionCanUses: [],
	promotionCouponOnItems: [],
	settingDealthom: null,
};

const PromotionSlice = createSlice({
	initialState: initialState,
	name: "promotion",
	reducers: {
		setPromotionAll: (state, action) => {
			state.promotion = action.payload;
		},
		setPromotionCouponOrder: (state, action) => {
			state.promotionCouponOrder = action.payload;
		},
		setPromotionCouponItem: (state, action) => {
			state.promotionCouponOnItems = action.payload;
		},
		setPromotionSeasonalOrder: (state, action) => {
			state.promotionSeasonalOrder = action.payload;
		},
		setPromotionSeasonalItem: (state, action) => {
			state.promotionSeasonalItem = action.payload;
		},
		setPromotion: (state, action) => {
			state.promotion = action.payload;
		},
		setPromotionCanUses: (state, action) => {
			state.promotionCanUses = action.payload;
		},
		setSettingDealthom: (state, { payload }: { payload: ConfigDealthom[] }) => {
			state.settingDealthom = payload;
		},
		setPromotionCouponOnItems: (
			state,
			{ payload }: { payload: PromotionJson[] }
		) => {
			state.promotionCouponOnItems = payload;
		},
	},
});

export const {
	setPromotionAll,
	setPromotionCouponOrder,
	setPromotionCouponItem,
	setPromotionSeasonalOrder,
	setPromotionSeasonalItem,
	setPromotion,
	setPromotionCanUses,
	setSettingDealthom,
	setPromotionCouponOnItems,
} = PromotionSlice.actions;
export const getPromotion = (state: RootState) => {
	return state.PromotionReducer.promotion;
};
export const getPromotionAll = (state: RootState) => {
	return state.PromotionReducer.promotionAll;
};

export const getPromotionCouponOrder = (state: RootState) => {
	return state.PromotionReducer.promotionCouponOrder;
};
export const getPromotionCouponItem = (state: RootState) => {
	return state.PromotionReducer.promotionCouponItem;
};
export const getPromotionSeasonalOrder = (state: RootState) => {
	return state.PromotionReducer.promotionSeasonalOrder;
};
export const getPromotionSeasonalItem = (state: RootState) => {
	return state.PromotionReducer.promotionSeasonalItem;
};
export const getSettingDealthom = (state: RootState) => {
	return state.PromotionReducer.settingDealthom;
};
export const getPromotionCouponOnItems = (state: RootState) => {
	return state.PromotionReducer.promotionCouponOnItems;
};
export default PromotionSlice.reducer;
