import { callApiV2 } from "@/lib/config/Api";
import {
	CashflowFetchJson,
	CashflowreceiptJson,
	CashflowreceiptList,
	CashflowreceiptsCreateInput,
	CashflowreceiptsDirection,
	CashflowreceiptsMethod,
} from "@/types/Cashflowreceipts.type";
import Helper from "@/utils/helper";
import { CancelToken } from "axios";

const URL = "/cashflowreceipts";
export async function createCashflowreceipts(
	payload: Partial<CashflowreceiptJson>
) {
	const dataPost = Helper.convertParams(payload, false, [
		"value",
		"target_id",
		"target",
	]);
	const res = await callApiV2<CashflowreceiptJson>(
		"/cashflowreceipts",
		"post",
		{
			data: dataPost,
		}
	);
	return res.data;
}

export async function createCashflowreceiptsPublic(
	payload: Partial<CashflowreceiptJson>
) {
	const dataPost = Helper.convertParams(payload);
	const res = await callApiV2<CashflowreceiptJson>(
		"/cashflowreceipts/public/order",
		"post",
		{
			data: dataPost,
		}
	);
	return res.data;
}

export async function updateCashflowreceipts(
	payload: Partial<CashflowreceiptJson>
) {
	const data: any = { ...payload };

	delete data.id;
	const dataPost = Helper.convertParams(data);
	const res = await callApiV2<CashflowreceiptJson>(
		`/cashflowreceipts/${payload.id}`,
		"put",
		{
			data: dataPost,
		}
	);
	return res.data;
}

export async function deleteCashflowreceipt(id: number | string) {
	const res = await callApiV2<CashflowreceiptJson>(
		`/cashflowreceipts/${id}`,
		"delete"
	);
	return res.data;
}

export async function getCashFlowreceipts({
	source_id,
	cancelToken,
}: {
	source_id: string | number;
	cancelToken?: CancelToken;
}) {
	return callApiV2<CashflowreceiptList>(`/cashflowreceipts`, "get", {
		params: {
			source_id: source_id.toString(),
			source: 1,
			direction: CashflowreceiptsDirection.RECEIPT,
		},
		cancelToken: cancelToken,
	});
}

export async function fetchMPost({
	action,
	id,
	cancelTolen,
}: {
	action: "fetch" | "add" | "cancel";
	id: number | string;
	cancelTolen?: CancelToken;
}) {
	return callApiV2<CashflowFetchJson>(URL + `/mposgateways/` + id, "post", {
		data: {
			action: action,
		},
		cancelToken: cancelTolen,
	});
}
