"use client";
import React, { useCallback, useEffect, useId, useMemo, useState } from "react";
import { ProductJson } from "@/types/Product.type";
import { cn } from "@/utils/utils";
import ProductCard from "~components/product/ProductCard";
import { CalcPricePromotionJson, PricePromotionJson } from "@/types/Cart.type";
import Carousel from "@/components/Carousel";
import { Device } from "@/types/Shop.type";
import { SlideNextIcon, SlidePrevIcon } from "@/components/icons";
import useTranslationApi from "@/lib/hooks/useTranslationApi";

type Props = {
	data: ProductJson[];
	collection_handle?: string;
	className?: string;
	isAddCartPopup?: boolean;
	isAnimate?: boolean;
	navigation?: boolean;
	device?: Device;
	showCountChild?: boolean;
	showWishlist?: boolean;
	pricePreview?: PricePromotionJson;
};

const translate = {
	quick_view: "quick_view",
	loading: "loading",
	add_compared: "add_compared",
};

const CollectionList = ({
	data,
	className,
	collection_handle,
	isAddCartPopup,
	isAnimate = true,
	navigation = true,
	device,
	showCountChild,
	showWishlist,
	pricePreview,
}: Props) => {
	const { t } = useTranslationApi(Object.keys(translate));

	const [previewPromotions, setPreviewPromotions] =
		useState<CalcPricePromotionJson>([]);

	const handleRender = (item: ProductJson, index: number) => {
		// const p = promotionForProductId(item.id);
		// const data = getPromotions(item);
		return (
			<ProductCard
				key={index}
				data={item}
				showCountChild={showCountChild}
				showWishlist={showWishlist}
				collectionHandle={collection_handle}
			/>
		);
	};

	return (
		<div
			data-aos={isAnimate ? "fade-up" : null}
			className={cn("collection_wrapper w-full  ", className)}>
			<Carousel
				data={data}
				itemSlider={handleRender}
				freeMode={true}
				breakpoints={{
					0: { slidesPerView: 2, spaceBetween: 0 },
					375: { slidesPerView: 2, spaceBetween: 0 },
					414: { slidesPerView: 2, spaceBetween: 0 },
					640: { slidesPerView: 2.3, spaceBetween: 0 },
					768: { slidesPerView: 4, spaceBetween: 12 },
					1024: { slidesPerView: isAddCartPopup ? 4 : 6, spaceBetween: 12 },
					1200: { slidesPerView: isAddCartPopup ? 4 : 6, spaceBetween: 12 },
				}}
				loop={true}
				centeredSlides={{ desktop: false, mobile: true }}
				navigation={navigation}
				navigationButton={{
					iconNext: <SlideNextIcon className="mt-[-60px]" />,
					iconPrev: <SlidePrevIcon className="mt-[-60px]" />,
				}}
				device={device}
			/>
		</div>
	);
};

export default CollectionList;
