export type ErrorType =
	| "global"
	| "checkouts"
	| "cart"
	| "products"
	| "home"
	| "collections"
	| "brands"
	| "pages"
	| "search"
	| "vnpay"
	| "web"
	| "pos"
	| "system_setting";

export type DataSystemEventPos = {
	type: "single";
	data: ErrorSystemEvent;
};
export interface ErrorSystemEvent {
	ip?: string | number;
	company_id: number;
	resource_id: string | number; // brower id hoac cus id
	resource_type: ErrorType; // page nào
	action: string; // mã lỗi
	event: "error";
	details: {
		time: number;
		message: string;
		location: string;
		tracing?: string | unknown;
		ip?: string | number;
		name?: string;
	}; // chi tiết của lỗi đó, nhập json lỗi ở đây
}
export async function postErrorToSystemEvents(body: ErrorSystemEvent) {
	const data = { ...body, details: JSON.stringify(body.details) };
	const res = await fetch("/api/system/error", {
		method: "post",
		body: JSON.stringify(data),
	});
	return res;
}
