"use client";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import { ReactNode } from "react";
import { cn } from "@/utils/utils";
import useDeviceSize from "@/lib/hooks/useDeviceSize";
import DrawerMobile from "./DrawerMobile";

interface PopupProps {
	animate?: "tran" | "fade" | "tran-up";
	classNameContent?: string;
	classNameTitle?: string;
	children?: ReactNode;
	title?: string | React.ReactNode;
	open: boolean;
	onOpenChange: () => void;
	closeOnMark?: boolean;
	isDrawerMobile?: boolean;
	classNameDrawerMobile?: string;
	closeButton?: React.ReactNode;
	modal?: boolean;
	id?: string | number;
}

export default function PopupV2({
	children,
	title,
	open,
	onOpenChange,
	classNameContent,
	animate = "tran",
	closeOnMark = true,
	isDrawerMobile,
	classNameDrawerMobile,
	closeButton,
	classNameTitle,
	id,
	modal = true,
}: PopupProps) {
	const { isMobile } = useDeviceSize();

	if (isMobile && isDrawerMobile) {
		return (
			<DrawerMobile
				open={open}
				onClose={onOpenChange}
				className={classNameDrawerMobile}
				classNameContent={classNameContent}
				title={title}
				classNameTitle={classNameTitle}>
				{children}
			</DrawerMobile>
		);
	}
	return (
		<Dialog open={open} onOpenChange={onOpenChange} modal={modal} key={id}>
			<DialogContent
				closeButton={closeButton}
				onInteractOutside={(e: any) => {
					e.preventDefault();
					if (document.querySelector(".popover-class-open")) {
						e.preventDefault();
						return;
					}
					if (closeOnMark) {
						return;
					}
					e.preventDefault();
					if (closeOnMark) {
						return;
					}
					e.preventDefault();
				}}
				className={cn(
					"  sm:max-w-[425px] md:max-w-[900px] lg-max-w-[1200px] max-h-screen rounded-[8px]  border-none",
					{
						"data-[state=open]:animate-modal-down data-[state=closed]:animate-modal-up":
							animate === "tran",

						"data-[state=open]:animate-drawer-up data-[state=closed]:animate-drawer-down   top-auto translate-y-0 bottom-0":
							animate === "tran-up",

						"data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]":
							animate === "fade",
						"max-w-[330px]": animate !== "tran-up",
					},
					classNameContent
				)}>
				<DialogHeader>
					<DialogTitle className={cn(" text-center", classNameTitle)}>
						{title}
					</DialogTitle>
				</DialogHeader>
				{children}
			</DialogContent>
		</Dialog>
	);
}
