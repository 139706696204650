"use client";
import { useQuery } from "@tanstack/react-query";
import { getListStore } from "../api/shop/stores";
import { FilterStore } from "@/types/Store.type";

export default function useStores(filter?: FilterStore) {
	return useQuery({
		queryKey: ["stores", filter],
		queryFn: async () => {
			const { data } = await getListStore(filter);
			return data;
		},
		staleTime: 0,
		retry: 2,
	});
}
