import React from "react";
import { cn } from "@/utils/utils";

const NavIcon = ({ className }: { className?: string }) => {
	return (
		<span className={cn("block h-4 w-4", className)}>
			<svg
				viewBox="64 64 896 896"
				focusable="false"
				width="1em"
				height="1em"
				fill="currentColor"
				aria-hidden="true">
				<path d="M904 160H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0 624H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0-312H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8z"></path>
			</svg>
		</span>
	);
};

export default NavIcon;
