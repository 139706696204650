"use client";

import Link from "next/link";
import { signOut, useSession } from "next-auth/react";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/redux/hook";
import { setSidebarMobile } from "@/redux/features/ShopSlice";
import useTranslationApi from "@/lib/hooks/useTranslationApi";

import Website from "@/common/constants/webstite";

import HeartIcon from "@/components/icons/HeartIcon";
import InstagramIcon from "@/components/icons/InstagramIcon";
import UserCircleIcon from "@/components/icons/UserCircleIcon";
import NamperfumeIcon from "@/components/icons/NamperfumeIcon";
import ChevronRightIcon from "@/components/icons/ChevronRightIcon";
import TiktokOutlineIcon from "@/components/icons/TiktokOutlineIcon";
import YoutubeOutlineIcon from "@/components/icons/YoutubeOutlineIcon";
import QuestionCircleIcon from "@/components/icons/QuestionCircleIcon";
import FacebookOutlineIcon from "@/components/icons/FacebookOutlineIcon";
import LinkElement from "@/components/LinkElement";
import useCustomer from "@/lib/hooks/useCustomer";
import { LoadingIcon } from "@/components/icons";

type Category = {
	head: React.ReactNode;
	icon: React.ReactNode;
	children: {
		title: React.ReactNode;
		link: string;
	}[];
};

export default function SidebarAccountMobile() {
	const { t } = useTranslationApi([
		"support",
		"contact",
		"careers",
		"about_us",
		"profile",
		"account",
		"address",
		"coupon",
		"order",
		"membership",
		"frequent_questions",
		"how_to_buy",
		"ordering_guide",
		"shipping_method",
		"payment_methods",
		"order_tracking",
		"about_namperfume",
		"pricing_policy",
		"return_policy",
		"privacy_policy",
	]);
	const dispatch = useAppDispatch();
	const sidebarMobile = useAppSelector(
		(state) => state.ShopReducer.sidebarMobile
	);
	// const userInfo = useAppSelector((state) => state.LoyaltyReducer.user);
	const { data: user } = useCustomer();

	// const { data } = useSession()
	// const user = data?.user.user
	const [logged, setLogged] = useState<boolean>(false);
	const [loading, setLoading] = useState(false);

	const onLogout = async () => {
		try {
			setLoading(true);
			await signOut({ redirect: true, callbackUrl: "/" });
		} catch (err) {
			setLoading(false);
		} finally {
			dispatch(setSidebarMobile(false));
		}
	};

	useEffect(() => {
		if (user && user.id > 0) {
			setLogged(true);
		} else {
			setLogged(false);
		}
	}, [user?.id]);

	//
	const categories: Category[] = [
		{
			head: <>{t("profile")}</>,
			icon: <UserCircleIcon className="w-[24px] h-[24px] min-w-[24px]" />,
			children: [
				{
					title: <>{t("account")}</>,
					link: Website.LINK.PAGE.PROFILE,
				},
				{
					title: <>{t("address")}</>,
					link: Website.LINK.PAGE.ADDRESS,
				},
				{
					title: <>{t("order")}</>,
					link: Website.LINK.PAGE.ORDER_PAGE,
				},
				// {
				//   title: <>{t("coupon")}</>,
				//   link: Website.LINK.PAGE.COUPON,
				// },
				// {
				//   title: <>{t("membership")}</>,
				//   link: Website.LINK.PAGE.REWARD_VIEW,
				// },
			],
		},
		{
			head: <>{t("support")}</>,
			icon: <QuestionCircleIcon className="w-[24px] h-[24px] min-w-[24px]" />,
			children: [
				{
					title: <>{t("frequent_questions")}</>,
					link: Website.LINK.PAGE.CAU_HOI_THUONG_GAP,
				},
				{
					title: <>{t("how_to_buy")}</>,
					link: Website.LINK.PAGE.CACH_THUC_MUA_HANG,
				},
				{
					title: <>{t("ordering_guide")}</>,
					link: Website.LINK.PAGE.HUONG_DAN_DAT_HANG,
				},
				{
					title: <>{t("shipping_method")}</>,
					link: Website.LINK.PAGE.PHUONG_THUC_VAN_CHUYEN,
				},
				{
					title: <>{t("payment_methods")}</>,
					link: Website.LINK.PAGE.PHUONG_THUC_THANH_TOAN,
				},
				{
					title: <>{t("order_tracking")}</>,
					link: Website.LINK.PAGE.THEO_DOI_DON_HANG,
				},
				{
					title: <>{t("pricing_policy")}</>,
					link: Website.LINK.PAGE.CHINH_SACH_GIA_CA,
				},
				{
					title: <>{t("return_policy")}</>,
					link: Website.LINK.PAGE.CHINH_SACH_DOI_TRA,
				},
				{
					title: <>{t("privacy_policy")}</>,
					link: Website.LINK.PAGE.CHINH_SACH_BAO_MAT,
				},
			],
		},
		{
			head: <>{t("about_namperfume")}</>,
			icon: <NamperfumeIcon className="w-[20px] h-[20px] min-w-[20px]" />,
			children: [
				{
					title: <>{t("about_us")}</>,
					link: Website.LINK.PAGE.ABOUT_US,
				},
				{
					title: <>{t("contact")}</>,
					link: Website.LINK.PAGE.LIEN_HE,
				},
				{
					title: <>{t("careers")}</>,
					link: Website.LINK.PAGE.TUYEN_DUNG,
				},
			],
		},
	];
	//

	return (
		<div
			className={`${
				sidebarMobile ? "block" : "hidden"
			} md:hidden fixed top-[70px] left-0 bottom-[55px] right-0 bg-white h-[100vh] overflow-y-scroll pb-[150px]`}>
			<div className="text-base text-gray-500 leading-[17px]">
				{/* Credential */}
				<div className="bg-gray-200 flex flex-col justify-center gap-2 px-4 h-[97px]">
					{logged ? (
						<>
							<p>
								Chào bạn, <span className="font-bold">{user?.full_name}</span>
							</p>
							<p
								className="underline underline-offset-4 leading-[15px] mt-1 font-bold"
								onClick={() => onLogout()}>
								{loading && <LoadingIcon></LoadingIcon>}
								Đăng xuất
							</p>
						</>
					) : (
						<>
							<div className="flex items-center gap-4 font-bold">
								<LinkElement
									href={Website.LINK.PAGE.LOGIN}
									className="underline underline-offset-4 capitalize">
									đăng nhập
								</LinkElement>
								<p className="h-[14px] w-[1px] bg-gray-500"></p>
								<LinkElement
									href={Website.LINK.PAGE.REGISTER}
									className="underline underline-offset-4 capitalize">
									đăng ký
								</LinkElement>
							</div>
							<p className="text-sm leading-[15px] mt-1">
								để có thêm nhiều trải nghiệm dành riêng cho bạn
							</p>
						</>
					)}
				</div>
				{/* Category */}
				{categories.map((item: Category, index) => (
					<div className="mb-4" key={index}>
						<div className="bg-gray-200 px-4 py-2 font-bold flex items-center gap-1 mb-2">
							{item.icon}
							<span>{item.head}</span>
						</div>
						<div className="flex flex-col gap-4">
							{item.children.map((child, i) => (
								<div className="flex flex-col gap-4 px-4" key={i}>
									<LinkElement
										href={child.link}
										className="flex items-center justify-between gap-4">
										<span>{child.title}</span>
										<ChevronRightIcon className="min-w-[24px]" />
									</LinkElement>
								</div>
							))}
						</div>
					</div>
				))}
				<div className="mb-4">
					<div className="bg-gray-200 px-4 py-2 font-bold flex items-center gap-1 mb-2">
						<HeartIcon className="w-[24px] h-[24px] min-w-[24px]" />
						<span>Theo dõi chúng tôi</span>
					</div>
					<div className="flex items-center gap-4 px-4">
						<LinkElement
							href={Website.LINK.MEDIA.INSTAGRAM}
							target="_blank"
							className="flex items-center justify-between gap-4">
							<InstagramIcon className="w-[24px] h-[24px] min-w-[24px] text-gray-500" />
						</LinkElement>
						<LinkElement
							href={Website.LINK.MEDIA.FACEBOOK}
							target="_blank"
							className="flex items-center justify-between gap-4">
							<FacebookOutlineIcon className="w-[24px] h-[24px] min-w-[24px] text-gray-500" />
						</LinkElement>
						<LinkElement
							href={Website.LINK.MEDIA.YOUTUBE}
							target="_blank"
							className="flex items-center justify-between gap-4">
							<YoutubeOutlineIcon className="w-[24px] h-[24px] min-w-[24px] text-gray-500" />
						</LinkElement>
						<LinkElement
							href={Website.LINK.MEDIA.TIKTOK}
							target="_blank"
							className="flex items-center justify-between gap-4">
							<TiktokOutlineIcon className="w-[24px] h-[24px] min-w-[24px] text-gray-500" />
						</LinkElement>
					</div>
				</div>
			</div>
		</div>
	);
}
