const SettingApi = {
	KEY: {
		k_section_footer_namperfume: "section_footer_namperfume",
		k_menu_home: "menu_home",
		k_banner_home: "banner_home",
		k_about_namperfume_1: "about_namperfume_1",
		k_about_namperfume_2: "about_namperfume_2",
		k_categories: "banner_nnn",
		k_settings_website_namperfume_net: "settings_website_namperfume_net",
		k_banner_brand: "banner_brand",
		k_section_brand_namperfume: "section_brand_namperfume",
		k_blog_banner: "shop_blog_banner",
		k_meta_data_home: "meta_data_home",
		k_map_namperfume_store: "map_namperfume_store",
		k_setting_id_store_web_shop: "setting_id_store_web_shop",
		k_setting_web_popup: "setting_web_popup",

		k_images_namperfume_store: "image_shop_namperfume",
		k_setting_info_transfer: "setting_info_transfer",

		k_setting_id_tag_view_product: "setting_id_tag_view_product",
		k_setting_id_tag_view_product_detail: "setting_id_tag_view_product_detail", // setting for shipping and detail product
		k_setting_pagination_limit: "setting_pagination_limit",
		k_setting_timing_limit: "setting_timing_limit",
		k_setting_nmagazine: "settings_website_namperfume_nmagazine",
		k_setting_website_maintain: "setting_website_maintain",
		k_setting_menu_mobile: "setting_menu_mobile",

		k_setting_language: "setting_language",
		K_data_language_label: "data_language_label",
		k_shop_setting: process.env.REACT_APP_BASE_SETTING_CONFIG || "",
		k_setting_items_sort_product: "setting_items_sort_product_2",
		k_footer_menu: "footer_menu",
		k_footer_bottom: "footer_bottom",
		k_setting_sidebar_pages: "setting_sidebar_pages",
		k_time_server: "timesever",
		k_top_brand: "top_brand_setting",

		k_collection_ads: "collection_ads",
		id_ecomplatforms_for_web: "id_ecomplatforms_for_web",
		id_ecomplatforms_for_pos: "id_ecomplatforms_for_pos",
		setting_pos_checkouts: "setting_pos_checkouts",
	},
	LOYALTY: {
		loyalty_popup_wellcome_desk: {
			default: "",
			key: "loyalty_popup_wellcome_desk",
		},
		loyalty_popup_wellcome_mob: {
			default: "",
			key: "loyalty_popup_wellcome_mob",
		},
		after_login_url: {
			default: "https://namperfume.net",
			key: "after_login_url",
		},
		after_login_first_url: {
			default: "/me/myprofile",
			key_mobile: "after_login_first_mobile_url",
			key_desktop: "after_login_first_desktop_url",
		},
		after_verify_url: { default: "", key: "after_verify_url" },
		after_establish_url: "after_establish_url",
		setting_limit_otp: "setting_limit_otp",
	},
	QUICKCONTACT: {
		k_title_live: "sociallive_textbox_live",
		k_title_contact: "sociallive_textbox_messenger",
	},
	FEEDBACK: {
		get_comment_on_select_options: "setting_stores_feedback",
		get_comment_on_select_options_contents: "setting_stores_feedback_contents",
	},
};

export default SettingApi;
