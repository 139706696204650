"use client";
import React from "react";
import { useEffect } from "react";
import ImageConstant from "@/common/constants/image";
import Image from "@/components/ui/Image";
import LinkElement from "@/components/LinkElement";

// import useBrowerId from "@/lib/hooks/useBrowerId";
// import useCustomer from "@/lib/hooks/useCustomer";
import useTimeServer from "@/lib/hooks/useTimeServer";
import {
	ErrorType,
	postErrorToSystemEvents,
} from "@/lib/api/shop/systemEvents";
import { getDataError } from "@/lib/config/apiHandler";
import ApiError from "@/common/api/Error";
import NotFoundCustom from "./NotFoundCustom";
import { getTimeServer } from "@/lib/api/shop/setting";
import SettingApi from "@/common/constants/setting";
type Props = {
	error?: Error & {
		degest?: string | undefined;
	};
	reset?: () => void;
	from: ErrorType;
	statusCode?: number;
};

export default function ErrorCustom({ error, reset, from, statusCode }: Props) {
	// await postSystem(error, from);

	async function postSystem() {
		try {
			const { data: timeServer } = await getTimeServer(
				SettingApi.KEY.k_time_server
			);

			let message = "";
			let name = "";

			if (error?.message) {
				message = error.message;
				name = error?.name || "";
			} else if (error) {
				const err = getDataError(error);
				message = err.errors.join(",");
				name = err?.url || "";
			}

			if (message) {
				await postErrorToSystemEvents({
					action: "error_on_page",
					company_id: 0,
					details: {
						name,
						location: `web-${from}`,
						message,
						time: timeServer.time_server,
					},
					event: "error",
					resource_type: "web",
					resource_id: "",
				});
			}
		} catch (error) {
			console.error("Error in postSystem:", error);
		}
	}

	useEffect(() => {
		postSystem();
	}, []);
	if (statusCode === 404) {
		return <NotFoundCustom></NotFoundCustom>;
	}

	return (
		<div className="w-full min-h-[100vh] flex flex-col justify-center items-center gap-6 md:gap-8 md:container mx-auto p-4">
			<Image
				src={"/assets/images/page500.png"}
				alt={"img"}
				width={1500}
				height={1500}
				className="w-full max-h-[200px] sm:max-h-[350px] 2xl:max-h-[500px] object-contain"
				placeholder={"blur"}
				blurDataURL={ImageConstant.BlurDataURL}
			/>
			<p className="font-medium text-sm md:text-xl text-gray-500 text-center">
				Rất tiếc, webiste đang tạm thời gián đoạn. Quý khách vui lòng truy cập
				lại sau.
			</p>
			<LinkElement
				href={"/"}
				className="bg-red-500 text-white py-2 px-5 text-sm flex items-center justify-center w-[182px] md:w-[293px] rounded border border-x-red-500 whitespace-nowrap">
				Quay về trang chủ
			</LinkElement>
		</div>
	);
}
