import BaseModel from "./BaseModel";
import { BaseModelJson } from "../types/BaseModelJson.type";
import { CartOrderJson, CartPreOrderJson } from "@/types/Cart.type";
import { ORDER_STATUS, OrderJson, SERVICE_TYPE } from "@/types/Orders.type";
import {
	PaymentCouponDetail,
	PaymentMethodDetailUpdate,
} from "@/types/Payment.type";
import { PromotionJson } from "@/types/Promotion.type";
import { DeliveryDetail } from "@/types/Invoice.type";
import { ORDER_DISCOUNT_TYPE } from "../constants/order";

// Default data object
const defaultData: OrderJson = {
	company_id: 0,
	creator_id: 0,
	id: "",
	code: "",
	store_id: 0,
	customer_id: 0,
	customer_token: "",
	coupons: [],
	cancel_reason: 0,
	price_sell: 0,
	price_shipping: 0,
	price_discount: 0,
	price_discount_coupon: 0,
	price_final: 0,
	price_cost: 0,
	price_deposit: 0,
	price_debt: 0,
	price_tax: 0,
	promotion_id: 0,
	promotions: [],
	contact_email: "",
	billing_fullname: "",
	billing_phone: "",
	billing_email: "",
	billing_address: "",
	billing_ward: 0,
	billing_district: 0,
	billing_province: 0,
	billing_country: 0,
	billing_company: "",
	billing_sub_sub_region_id: 0,
	billing_sub_region_id: 0,
	billing_region_id: 0,
	shipping_fullname: "",
	shipping_phone: "",
	shipping_address: "",
	shipping_ward: 0,
	shipping_district: 0,
	shipping_province: 0,
	shipping_country: 0,
	shipping_company: "",
	warehouse_id: 0,
	shipping_carrier: 0,
	product_receipt_id_list: [],
	cashflow_receipt_id_list: [],
	tax_invoice_id: 0,
	quantity: 0,
	note: "",
	cod_amount: 0,
	status: ORDER_STATUS.NEW,
	vnpay_status: false,
	tag: "",
	ecom_platform_id: 0,
	ecom_platform_type: 0,
	ecom_platform_order_id: "",
	ecom_platform_invoice_id: "",
	date_arrived: 0,
	date_created: 0,
	date_modified: 0,
	payment_method: 0,
	resource_type: 0,
	resource_id: "",
	resource_number: "",
	order_detail: "",
	order_description: "",
	kiotviet_code: "",
	details: {
		data: [],
		total: 0,
	},
	total_payment: 0,
	debt: 0,
	payments: [],
	has_invoice: false,
	order_custom_discount: 0,
	order_custom_value: 0,

	billing_firstname: "",
	billing_lastname: "",
	coupondetail: null,
	date_completed: 0,
	date_deleted: 0,
	deposit: 0,
	ipaddress: 0,
	is_deleted: 0,
	kiotviet_id: "",
	note_invoice: "",
	promotion_detail: [],
	shipping_firstname: "",
	shipping_lastname: "",
	type: 0,
	delivery_detail: [],
	order_id: 0,
	item_discount: 0,
	order_discount: 0,
	order_custom_type: ORDER_DISCOUNT_TYPE.ORDER_DISCOUNT_TYPE_AMOUNT,
	delivery_lits_id: "",
	item_total: 0,
	service_type: SERVICE_TYPE.PURTCHASE_METHOD_IN_STORE,
	seller_id: 0,
	// Field custom

	label_seller: "",
};

class CartOrderModel extends BaseModel implements BaseModelJson<OrderJson> {
	billing_address: string = defaultData.billing_address;
	billing_country: number = defaultData.billing_country;
	billing_district: number = defaultData.billing_district;
	billing_fullname: string = defaultData.billing_fullname;
	billing_phone: string = defaultData.billing_phone;
	billing_province: number = defaultData.billing_province;
	billing_ward: number = defaultData.billing_ward;
	billing_email: string = defaultData.billing_email;
	billing_firstname: string = defaultData.billing_firstname;
	billing_lastname: string = defaultData.billing_lastname;
	customer_token: string = defaultData.customer_token;
	cod_amount: number = defaultData.cod_amount;
	code: string = defaultData.code;
	company_id: number = defaultData.company_id;
	coupondetail: PaymentCouponDetail[] | null = defaultData.coupondetail;
	creator_id: number = defaultData.creator_id;
	customer_id: number = defaultData.customer_id;
	date_arrived: number = defaultData.date_arrived;
	date_completed: number = defaultData.date_completed;
	date_created: number = defaultData.date_created;
	date_deleted: number = defaultData.date_deleted;
	date_modified: number = defaultData.date_modified;
	debt: number = defaultData.debt;
	deposit: number = defaultData.deposit;
	details: { total: number; data: CartOrderJson[] } = defaultData.details;
	id: string = defaultData.id;
	ipaddress: number = defaultData.ipaddress;
	is_deleted: number = defaultData.is_deleted;
	kiotviet_code: string = defaultData.kiotviet_code;
	kiotviet_id: string = defaultData.kiotviet_id;
	note: string = defaultData.note;
	note_invoice: string = defaultData.note_invoice;
	payments: PaymentMethodDetailUpdate[] = defaultData.payments;
	price_cost: number = defaultData.price_cost;
	price_discount: number = defaultData.price_discount;
	price_discount_coupon: number = defaultData.price_discount_coupon;
	price_final: number = defaultData.price_final;
	price_sell: number = defaultData.price_sell;
	price_shipping: number = defaultData.price_shipping;
	price_tax: number = defaultData.price_tax;
	promotion_detail: PromotionJson[] = defaultData.promotion_detail;
	promotion_id: number = defaultData.promotion_id;
	quantity: number = defaultData.quantity;
	shipping_address: string = defaultData.shipping_address;
	shipping_carrier: number = defaultData.shipping_carrier;
	shipping_country: number = defaultData.shipping_country;
	shipping_district: number = defaultData.shipping_district;
	shipping_fullname: string = defaultData.shipping_fullname;
	shipping_phone: string = defaultData.shipping_phone;
	shipping_province: number = defaultData.shipping_province;
	shipping_ward: number = defaultData.shipping_ward;
	shipping_firstname: string = defaultData.shipping_firstname;
	shipping_lastname: string = defaultData.shipping_lastname;
	status: ORDER_STATUS = defaultData.status;
	store_id: number = defaultData.store_id;
	tag: string = defaultData.tag;
	total_payment: number = defaultData.total_payment;
	type: number = defaultData.type;
	warehouse_id: number = defaultData.warehouse_id;
	delivery_detail: DeliveryDetail[] = defaultData.delivery_detail;
	has_invoice: boolean = defaultData.has_invoice;
	item_discount: number = defaultData.item_discount;
	order_discount: number = defaultData.order_discount;
	item_total: number = defaultData.item_total;
	service_type: SERVICE_TYPE = defaultData.service_type;
	seller_id: number = defaultData.seller_id;
	order_custom_value: number = defaultData.order_custom_value;
	order_custom_discount: number = defaultData.order_custom_discount;
	label_seller: string = defaultData.label_seller;

	constructor(json: OrderJson) {
		super();
		Object.assign(this, { ...defaultData, ...json });
	}

	static getDefaultData(): OrderJson {
		return { ...defaultData };
	}

	toJson(): OrderJson {
		return JSON.parse(JSON.stringify(this));
	}
}

export default CartOrderModel;
