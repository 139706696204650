import { callApiV2 } from "@/lib/config/Api";
import { CartAddEditJson, CartJson } from "@/types/Cart.type";
import { OrderBodyListJson, OrderFilter, OrderJson } from "@/types/Orders.type";
import Helper from "@/utils/helper";

const URL = "/orders/invoice";
const URL_MY_ORDER = "/orders/cart/myoder";

async function getOrders(filter: OrderFilter) {
	return callApiV2<OrderBodyListJson>(URL, "get", {
		params: filter,
	});
}

async function getOrder(id: CartJson["id"]) {
	return callApiV2<OrderJson>(URL + "/" + id, "get");
}

async function deleteOrderUser(orderId: string | number) {
	return callApiV2(URL + "/orders/cart/order/" + orderId, "put", {
		data: {
			action: "delete",
		},
	});
}

async function editOrderUser(
	orderId: string | number,
	data: Omit<CartAddEditJson, "cart_id">
) {
	const dataConvert = Helper.convertParams(data);
	return callApiV2<OrderJson>("/orders/" + orderId, "put", {
		data: dataConvert,
	});
}

export { getOrders, getOrder, deleteOrderUser, editOrderUser };
