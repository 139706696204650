"use client";
import { debounce } from "lodash";
import { useAppSelector } from "@/redux/hook";
import { getListProduct } from "@/lib/api/shop/products";
import React, { useCallback, useEffect, useState } from "react";
import { getDetailCollectionV2 } from "@/lib/api/shop/collection";
import {
	useParams,
	usePathname,
	useRouter,
	useSearchParams,
} from "next/navigation";

import { CloseIcon, SearchIcon, LoadingIcon } from "@/components/icons";
import ProductItem from "./children/ProductItem";
import { useDispatch } from "react-redux";
import { setIsShowSearchMobile } from "@/redux/features/ShopSlice";
import Helper from "@/utils/helper";
import { ProductJson } from "@/types/Product.type";
import { CollectionJson } from "@/types/Collection.type";
import useTranslationApi from "@/lib/hooks/useTranslationApi";
import { cn } from "@/utils/utils";
import LinkElement from "@/components/LinkElement";
import useStoreId from "@/lib/hooks/useStoreId";

export default function HeaderMidSearch({ className }: { className?: string }) {
	const { t } = useTranslationApi([
		"lable_view_more",
		"product",
		"products_empty",
		"search",
	]);
	const router = useRouter();
	const pathname = usePathname();
	const params = useParams();
	const searchParams = useSearchParams();
	// const store_id = useAppSelector((state) => state.ShopReducer.storeId);
	const { data: store_id } = useStoreId();

	const [keyword, setKeyword] = useState(searchParams.get("keyword") || "");

	const [isLoading, setIsLoading] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const dispatch = useDispatch();

	const [searchProducts, setSearchProducts] = useState<ProductJson[]>([]);
	const [numberProductsMore, setNumberProductsMore] = useState<number>(0);

	//collection
	const [collection, setCollection] = useState<CollectionJson | null>(null);

	const pathnameArray = pathname.split("/").slice(1);
	let collectionHandle = "";
	const collectionIdInSearch = searchParams.get("collection_id") || "";

	if (params?.handle?.length > 0 && pathnameArray[0] === "collections") {
		const [, extractedHandle] = pathnameArray;
		collectionHandle = extractedHandle || "";
	} else if (collectionIdInSearch.length > 0) {
		collectionHandle = collectionIdInSearch;
	}

	const fetchCollection = async (handle: string) => {
		try {
			const res = await getDetailCollectionV2(handle);
			setCollection(res.data);
		} catch (error) {}
	};

	//products

	async function handleSearch(keyword: string) {
		if (!keyword) {
			setSearchProducts([]);
			setNumberProductsMore(0);
			return;
		}
		try {
			const res = await getListProduct({
				keyword: keyword,
				page: 1,
				limit: 5,
				store_id: store_id,
				collection_id:
					collectionHandle.toString().length > 0 ? collectionHandle : undefined,
			});

			setSearchProducts(res.data.items);
			setNumberProductsMore(res.data.total - res.data.items.length);
		} finally {
			setIsLoading(false);
		}
	}

	const debounceSearch = useCallback(
		debounce((nextValue) => handleSearch(nextValue), 500),
		[collection, store_id]
	);

	function handleInputOnchange(e: React.ChangeEvent<HTMLInputElement>) {
		setIsLoading(true);
		const { value } = e.target;
		setKeyword(value);
	}

	function handleInputEnter(e: React.KeyboardEvent<HTMLInputElement>) {
		const key = e.key;
		const value = e.currentTarget.value;

		if (!value || key !== "Enter") return;
		// const paramsUrl = new URLSearchParams({
		// 	keyword: value,
		// 	collection_id: collection.id.toString(),
		// });
		let objectFilters = {
			keyword: value,
			collection_id: undefined,
		} as {
			keyword: string;
			collection_id: undefined | number | string;
		};
		if (collection) {
			objectFilters.collection_id = collection.id;
		}

		const paramsUrl = Helper.convertFilterToParams(objectFilters);
		router.push(`/search${paramsUrl}`);
	}

	function handleClear() {
		setKeyword("");
		setSearchProducts([]);
		setNumberProductsMore(0);
	}

	// function clearCollectionTag() {
	//   setCollection(new CollectionModel(CollectionModel.getDefaultData()));
	// }

	useEffect(() => {
		if (keyword) {
			debounceSearch(keyword);
		}
	}, [keyword]);

	useEffect(() => {
		if (
			collectionHandle &&
			collectionHandle.length > 0
			// (collectionHandle.includes("-") || collectionHandle.includes("_"))
		) {
			fetchCollection(collectionHandle);
		}
	}, [collectionHandle]);

	return (
		<div className={cn("h-[32px] w-full", className)}>
			<div className="relative">
				<div className="border border-gray-400 rounded flex items-center gap-2">
					<SearchIcon className="min-w-[20px] ml-2" />
					{/* {collection && (
						<TagsCollection
							onClick={clearCollectionTag}
							collection={collection}
						/>
					)} */}
					<div className=" relative flex-1">
						<input
							type="text"
							className="pr-8 h-[32px] leading-[32px] block w-full text-sm focus:outline-none text-gray-500 placeholder:text-gray-400"
							placeholder="Tìm kiếm"
							value={keyword}
							onChange={(e) => {
								handleInputOnchange(e);
							}}
							onKeyUp={(e) => handleInputEnter(e)}
							onFocus={() => setIsOpen(true)}
							onBlur={() => {
								setTimeout(() => {
									setIsOpen(false);
								}, 200);
							}}
						/>
						{keyword && (
							<div
								className="absolute inset-y-0 right-2 top-0 bottom-0 flex items-center justify-center cursor-pointer"
								onClick={handleClear}>
								<CloseIcon className="min-w-4 w-4 h-4" />
							</div>
						)}
					</div>
				</div>

				{keyword && isOpen ? (
					<ul className="absolute z-10 top-full left-0 right-0 h-auto bg-white shadow-lg rounded">
						{isLoading ? (
							<div className="h-40 flex justify-center items-center">
								<LoadingIcon />
							</div>
						) : searchProducts.length ? (
							<div>
								{searchProducts.map((product, index) => {
									return <ProductItem product={product} key={index} />;
								})}
								{numberProductsMore > 0 ? (
									<div>
										<LinkElement
											onClick={() => dispatch(setIsShowSearchMobile(false))}
											href={`/search?keyword=${keyword}${
												collection && collection.id > 0
													? `&collections_id=${collection.id}`
													: ""
											}`}
											className="text-center block py-2 text-sm text-gray-500 cursor-pointer hover:text-red-500 transition">
											Xem thêm {numberProductsMore} sản phẩm
										</LinkElement>
									</div>
								) : null}
							</div>
						) : (
							<div className="text-center p-2">Không có sản phẩm nào... </div>
						)}
					</ul>
				) : null}
			</div>
		</div>
	);
}
