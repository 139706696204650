import {
	CartDeliveryFormType,
	PriceShippingType,
} from "@/app/pos/_components/posCart/CartDeliveryForm";
import {
	CashflowGroup,
	CashflowreceiptJson,
	CashflowreceiptsCreateInput,
	CashflowreceiptsDirection,
	CashflowreceiptsMethod,
	CashflowreceiptSource,
	CashflowreceiptsStatus,
	CashflowreceiptTarget,
} from "@/types/Cashflowreceipts.type";
import { PaymentMethod } from "@/types/Payment.type";
import { createCashflowreceipts } from "../api/shop/cashflowreceipts";
import { getDataError } from "../config/apiHandler";

class CheckoutRepo {
	///////////////////////////////
	//calc

	///////////////////////////////
	static swtichPaymetMethodToNumber(method: PaymentMethod | number) {
		if (typeof method === "number") return method;

		switch (method) {
			case "card":
				return CashflowreceiptsMethod.CARD;

			case "transfer":
				return CashflowreceiptsMethod.TRASNFER;
			case "cash":
				return CashflowreceiptsMethod.CASH;
			case "cod":
				return CashflowreceiptsMethod.COD;
			case "vnpay":
				return CashflowreceiptsMethod.VNPAY;

			default:
				return 0;
		}
	}

	static convertCashFlowsFromPaymentMethod(
		data: {
			amount: number;
			method: number | PaymentMethod;
			direction?: CashflowreceiptsDirection;
		}[]
	): Partial<CashflowreceiptJson>[] {
		return data.map(({ amount, method, direction }) => {
			return {
				cashflow_group: 101,
				direction: direction ?? CashflowreceiptsDirection.RECEIPT,
				method: CheckoutRepo.swtichPaymetMethodToNumber(method),
				value: amount,
			};
		});
	}

	static async createCashflowForDelivery(
		id: number,
		data: CartDeliveryFormType,
		storeId: number,
		targetId: number
	) {
		try {
			if (data?.priceShippingType) {
				const create = async (dir: CashflowreceiptsDirection) => {
					const value =
						data?.shipping_cost_by_customer ??
						data.shipping_cost_covered_by_shop ??
						0;
					const input: CashflowreceiptsCreateInput = {
						cashflow_group:
							dir === CashflowreceiptsDirection.RECEIPT
								? CashflowGroup.cashflow_group
								: CashflowGroup.GROUP_OUT_OTHER,
						direction: dir,
						method: CashflowreceiptsMethod.COD,
						source: CashflowreceiptSource.DELIVERY,
						source_id: id,
						status: CashflowreceiptsStatus.DRAFT,
						store_id: storeId,
						target: CashflowreceiptTarget.ANOTHER,
						target_id: 0,
						value: value,
					};

					return await createCashflowreceipts(input);
				};

				switch (data.priceShippingType) {
					case PriceShippingType.StorePays:
						await Promise.all([create(CashflowreceiptsDirection.PAYMENT)]);
						break;

					case PriceShippingType.PrepaidCustomer:
						await Promise.all([
							create(CashflowreceiptsDirection.PAYMENT),
							create(CashflowreceiptsDirection.RECEIPT),
						]);

						break;

					case PriceShippingType.ReceiverPays:
						break;

					default:
						break;
				}
			}
		} catch (error) {
			throw Error("");
		}
	}
}

export default CheckoutRepo;
