import {
	useState,
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
} from "react";
import InputLine from "@/components/ui/InputLine";
import { useForm, useWatch } from "react-hook-form";
import SelectLine from "@/components/ui/SelectLine";
import useGetHomeDeliveries from "@/lib/hooks/useGetHomeDeliveries";
import LabelControl from "@/components/core/components/LabelControl";
import {
	DeliveryInternalAddEditJson,
	DeliveryJson,
} from "@/types/Delivery.type";
import moment from "moment";
import Checkbox from "@/components/form/Checkbox";
import LoadingElement from "@/components/LoadingElement";
import { LoadingIcon } from "@/components/icons";
import TextArea from "@/components/ui/TextArea";
import { useDebounce } from "use-debounce";
import Input from "@/components/form/Input";
import Helper from "@/utils/helper";
import { debounce } from "lodash";
import DatePickerForm from "@/components/form/DatePicker";
import Radio from "@/components/form/Radio";

export type CartDeliveryFormType = Partial<DeliveryInternalAddEditJson> & {
	date_started?: number;
	isValid?: boolean;
	buyerFee?: boolean;
	priceShip?: number;
	priceShippingType?: PriceShippingType;
};

interface CartShippingViewProps {
	defaultValue?: Partial<CartDeliveryFormType>;
	onSubmit?: (data: CartDeliveryFormType) => void;
	priceShipping?: number;
	loading?: boolean;
	disabled?: boolean;
}
export enum PriceShippingType {
	PrepaidCustomer = 1, //'Khách trả phí trước',
	ReceiverPays = 2, // 'Người nhận hàng trả phí',
	StorePays = 3, // 'Cửa hàng trả phí',
}
const CartDeliveryForm = forwardRef(function CartShippingView(
	{
		defaultValue,
		onSubmit,
		priceShipping,
		loading,
		disabled,
	}: CartShippingViewProps,
	ref
) {
	const { data: homeDeliveries, isLoading } = useGetHomeDeliveries();

	const [isValid, setIsValid] = useState(false);

	const defaultVa: CartDeliveryFormType = {
		resource_type: defaultValue?.resource_type || 1,
		note: defaultValue?.note || "",
		cod: defaultValue?.cod || 0,
		cost_total: defaultValue?.cost_total || 0,
		date_started: defaultValue?.date_started || new Date().getTime(),
		partner_delivery: defaultValue?.partner_delivery,
		code: defaultValue?.code || "",
		buyerFee: defaultValue?.buyerFee || true,
		priceShip:
			priceShipping ||
			defaultValue?.shipping_cost_by_customer ||
			defaultValue?.shipping_cost_covered_by_shop ||
			0,
		priceShippingType: PriceShippingType.PrepaidCustomer,
	};

	const formRef = useRef<HTMLFormElement | null>(null);

	const {
		handleSubmit,
		control,
		formState,
		getFieldState,
		setValue,
		trigger,
		watch,
		getValues,
	} = useForm<CartDeliveryFormType>({
		defaultValues: defaultVa,
		mode: "onChange",
	});

	const [priceShippingType, setPriceShippingType] = useState(
		PriceShippingType.PrepaidCustomer
	);

	const [dataPost, setDataPost] = useState<CartDeliveryFormType>(defaultVa);

	////////////////////////////////////
	const onSubmitForm = (data: any) => {
		const isDirty = getFieldState("date_started").isDirty;
		const date_delivery =
			data.date_started && isDirty
				? ` - giao hàng lúc ${moment(data.date_started).format(
						"HH:mm DD-MM-YYYY"
				  )}`
				: "";

		const dataDeliveryPost: CartDeliveryFormType = {
			...data,
			priceShip: Helper.convertCurencyToNumber(data.priceShip),
			cod: data.cod,
			note: dataPost.note + date_delivery,
			payment_method: 1,
			resource_type: data.resource_type,
			resource_id: data.resource_id,
			partner_delivery: data.partner_delivery,
			code: data.code,
			cost_note_transfer: 0,
			cotips: 0,
			isValid: true,
			shipping_cost_by_customer:
				priceShippingType !== PriceShippingType.StorePays
					? Helper.convertCurencyToNumber(data.priceShip)
					: undefined,
			shipping_cost_covered_by_shop:
				priceShippingType === PriceShippingType.StorePays
					? Helper.convertCurencyToNumber(data.priceShip)
					: undefined,
			priceShippingType: Number(priceShippingType),
		};
		setDataPost(dataDeliveryPost);
		onSubmit?.(dataDeliveryPost);
	};

	const setValueNote = debounce((val: string) => {
		setDataPost((prev) => ({ ...prev, note: val }));
	}, 300);
	useEffect(() => {
		setIsValid(formState.isValid);
	}, [formState]);

	useEffect(() => {
		setValue("priceShip", priceShipping);
	}, [priceShipping]);

	useEffect(() => {
		if (homeDeliveries && homeDeliveries.length > 0) {
			// setValue("partner_delivery", homeDeliveries[0].id);
		}
	}, [typeof homeDeliveries]);

	useImperativeHandle(ref, () => ({
		triggerSubmitForm: () => {
			// Đặt lại tất cả state
			trigger("partner_delivery", { shouldFocus: true });
		},
	}));

	return (
		<div className="mt-2">
			<LoadingElement
				fallback={<LoadingIcon></LoadingIcon>}
				loading={isLoading}>
				<form ref={formRef} onChange={handleSubmit(onSubmitForm)}>
					<div className=" grid lg:gap-4 grid-cols-1 md:grid-cols-2  mb-2 gap-1">
						{isLoading && <p>loading...</p>}
						{homeDeliveries && (
							<SelectLine
								placeholder="Chọn đơn vị"
								label="Đơn vị vận chuyển"
								control={control}
								required
								rules={{ required: "Yêu cầu nhập" }}
								options={homeDeliveries.map((home) => ({
									label: home.name,
									value: home.id,
								}))}
								name="partner_delivery"
							/>
						)}
						<InputLine control={control} name="code" label="Mã vận đơn" />
					</div>
					{/* <div className=" grid lg:gap-4 grid-cols-1 lg:grid-cols-2 mb-2">
						<SelectLine
							label="Loại dịch vụ"
							control={control}
							options={[
								{ label: "Giao thường", value: 1 },
								{ label: "Giao nhanh", value: 2 },
							]}
							name="resource_type"
						/>
					</div> */}
					<div className=" grid lg:gap-4 grid-cols-1 lg:grid-cols-2 mb-2 gap-1">
						<Input
							disabled={disabled}
							mode="line"
							label={{
								children: "Phí vận chuyển",
							}}
							typeInput="currency"
							control={control}
							name="priceShip"
							className="border-gray-400"
							rules={{
								required: "Vui lòng nhập phí vận chuyển",
							}}
						/>
						<div>
							<LabelControl>Thời gian giao</LabelControl>
							<DatePickerForm
								placeholder=""
								onChange={(val) => {
									onSubmitForm({ ...getValues(), date_started: val });
								}}
								showIn="default"
								control={control}
								name="date_started"
								triggerProps={{ className: "pb-1" }}
								showTime
							/>
						</div>
					</div>
				</form>

				<div className=" mt-2 flex flex-col gap-2">
					{/* <InputLine control={control} name="note" label="Ghi chú" /> */}
					<label htmlFor="note">Ghi chú</label>
					<textarea
						onChange={(e) => {
							setValueNote(e.target.value);
						}}
						rows={3}
						name="note"
						className="border border-gray-400 rounded-sm p-1">
						{" "}
					</textarea>
					{/* <TextArea
						maxLength={200}
						rows={2}
						label="Ghi chú"
						name="note"></TextArea> */}

					{/* <div>
							<label className="text-sm text-gray-400" htmlFor="note">
								Ghi chú
							</label>
							<textarea
								className="w-full border p-4 pr-0 rounded border-gray-400 leading-5 focus:outline-none text-gray-500 text-base   resize-none scrollbarCustomer "
								name=""
								rows={2}
								id="note"
								onChange={(e) => {
									const val = e.target.value;
									setValue("note", val);
								}}></textarea>
						</div> */}
				</div>
				<div className=" flex items-center gap-2 mt-4">
					{/* <Checkbox
						name="feee"
						id="feee"
						label={{
							children: "Người nhận trả phí",
						}}></Checkbox> */}

					<Radio
						name="price_shipping_type"
						value={priceShippingType}
						onChange={(e) => {
							// e.stopPropagation();
							// e.preventDefault();

							setPriceShippingType(e.target.value);
							setDataPost((prev) => ({
								...prev,
								priceShippingType: e.target.value,
							}));
							onSubmit?.({
								...dataPost,
								priceShippingType: Number(e.target.value),
							});
						}}
						defaultValue={PriceShippingType.PrepaidCustomer}
						options={[
							{
								label: "Khách trả phí trước",
								value: PriceShippingType.PrepaidCustomer,
							},
							{
								label: "Người nhận hàng trả phí",
								value: PriceShippingType.ReceiverPays,
							},
							{
								label: "Cửa hàng trả phí",
								value: PriceShippingType.StorePays,
							},
						]}></Radio>
				</div>
			</LoadingElement>
		</div>
	);
});
export default CartDeliveryForm;
