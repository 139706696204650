import Money from "@/components/ui/Money";
import { PromotionJson } from "@/types/Promotion.type";
import { cn } from "@/utils/utils";
import React from "react";

export default function PromotionConditionInfo({
	promotion,
	className,
}: {
	promotion: PromotionJson;
	className?: string;
}) {
	return (
		<ul
			className={cn("list-disc text-sm md:text-base", className)}
			style={{ listStyle: "inside" }}>
			{promotion.req_subtotal ? (
				<li>
					Giá trị đơn hàng tối thiếu đạt{" "}
					<Money value={promotion.req_subtotal} />
				</li>
			) : null}

			{promotion.max_applied ? (
				<li>
					Giảm tối đa <Money value={promotion.max_applied} />
				</li>
			) : null}
			{promotion.req_quantity ? (
				<li>Số lượng mua tối thiểu {promotion.req_quantity} sản phẩm</li>
			) : null}
			{promotion.req_collectionids.length > 0 ? (
				<li>
					Không áp dụng cho các sản phẩm khác, chỉ áp dụng cho các sản phẩm tham
					gia chương trình khuyến mãi <b>{promotion.campaign_info.name}</b>.
				</li>
			) : null}
		</ul>
	);
}
