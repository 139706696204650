"use client";
import React, { memo, useCallback, useEffect, useState } from "react";
import COOKIES from "@/common/constants/cookies";
// import Cookies from "js-cookie";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { LoginJson } from "@/types/Auth.type";
import Helper from "@/utils/helper";

type CookiePos = {
	name: string;
	path: string;
	value: string;
};
type Status = "authenticated" | "loading" | "unauthenticated";

type Props = {
	pending?: boolean;
};

export const KEY_LOCAL_POS_JWT = "MAIN-POS-JWT";
const useSessionPos = ({ pending = false }: Props) => {
	const queryClient = useQueryClient();
	const get = async () => {
		try {
			const res = await fetch("/api/session/pos", {
				method: "get",
			});
			const data = (await res.json()) as { token: CookiePos };

			const dataUser = JSON.parse(
				decodeURIComponent(data.token.value)
			) as LoginJson;
			localStorage.setItem(KEY_LOCAL_POS_JWT, JSON.stringify(dataUser));
			return dataUser;
		} catch (error) {}
	};

	const set = async (token: string) => {
		try {
			const valueDecode = encodeURIComponent(token);
			await fetch("/api/session/pos", {
				method: "post",
				body: JSON.stringify({ token: valueDecode }),
			});

			// await queryClient.refetchQueries({
			// 	queryKey: [COOKIES.POS.AUTH],
			// });
		} catch (error) {
			console.log("erorr", error);
		} finally {
		}
	};

	const { data, isLoading, isFetching, refetch, status, fetchStatus } =
		useQuery({
			queryKey: [COOKIES.POS.AUTH],
			queryFn: async () => {
				let defaultLocal = null;
				let defaultLocalParse = null;
				if (!Helper.isServer()) {
					defaultLocal = localStorage.getItem(KEY_LOCAL_POS_JWT);
					if (defaultLocal) {
						defaultLocalParse = JSON.parse(defaultLocal) as LoginJson;

						return defaultLocalParse;
					}
				}

				const res = await get();
				return res;
			},
			// staleTime: Infinity,

			staleTime: 1000,
			enabled: !pending,
			refetchOnMount: true,
			// initialData: defaultLocalParse,
		});

	const revertStatus = useCallback((): Status => {
		switch (status) {
			case "pending":
				return "loading";
			case "success":
				return "authenticated";
			case "error":
				return "unauthenticated";

			default:
				return "loading";
				break;
		}
	}, [status]);

	// useEffect(() => {
	// 	get();
	// }, []);

	return {
		token: data?.jwt,
		loginData: data,
		status: revertStatus(),
		isLoading: isLoading || isFetching,
		setToken: set,
		getToken: get,
	};
};

export default useSessionPos;
