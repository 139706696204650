import React, { ReactNode } from 'react'
import { cn } from '@/utils/utils'

export default function BgPromotionItem({ className, children }: { className?: string, children?: ReactNode }) {
    return (
        <p className=' relative'>
            <span className={cn(" inline-block w-[76px] h-[21px] text-[#F7D2D9]", className)}>
                <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 76 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M0 0V2.1C3.37778 3.78 1.40741 5.6 0 6.3V8.4C3.37778 10.08 1.40741 11.9 0 12.6V14.7C3.37778 16.38 1.40741 18.2 0 18.9V21H76V18.9C72.6222 17.22 74.5926 15.4 76 14.7V12.6C72.6222 10.92 74.5926 9.1 76 8.4V6.3C72.6222 4.62 74.5926 2.8 76 2.1V0H0Z"
                        fill="currentColor"
                    />
                </svg>
            </span>
            <div className=' absolute inset-0 '>
                <div className=' flex justify-center items-center'>
                    {children}
                </div>
            </div>
        </p>


    )
}


