import { CustomerAgeRange, CustomerGenders } from "@/types/Customer.type";

const styleIcon = {
	width: 14,
	height: 14,
};

const CustomerConstants = {
	LIMIT_TIME_UPDATE: 30,
	GENDERS: {
		NONE: 0,
		MALE: 1,
		FEMALE: 3,
		OTHER: 5,
	},
	LINK_ROUTER: {
		CUSTOMER: {
			INDEX: "/me",
			MY_PROFILE: "/myprofile",
			RESET_PASSWORD: "/myprofile/resetpassword",
			MY_COUPON: "/mycoupon",
			MY_ADDRESS: "/myaddress",
			MY_REWARD: "/myreward",
		},
		LOGIN: {
			INDEX: "/",
			LOGIN: "/account/login",
			REGISTER: "/account/register",
			VERIFY_OTP: "/account/verifyotp",
			VERIFY_OTP_QUICK: "/account/voqr",

			FORGOT_PASSWORD: "/account/forgotpassword",
			RESET_PASSWORD: "/account/resetpassword",
		},
		NEW_LETTER: {
			INDEX: "",
		},
	},
	NTACCOUNTID: {
		TYPE_CHANGE_EMAIL: 4, //dùng cho giao diện thay đổi email
		TYPE_CHANGE_PHONE: 5, //dùng cho giao diện thay đổi phone
	},
	SENDER_TYPE: {
		CHANGE_CONTACT: 10, //thay đổi email và phone
		CHANGE_USERNAME: 11, //update username
		VERIFY: 12, //verify
		PROFILE: 13,
		NEWSLETTER: 6, //đăng ký nhận tin
	},
	ERRORS: {
		ERROR_OTP_ALREADY: "error_otp_already",
		ERROR_OVER_LIMIT: "error_over_limit",
		ERROR_TIMECHANGE_USERNAME: "error_time_change_user_name",
		// ERROR_TIMECHANGE_CONTACT: "error_time_change_contact",
		ERROR_TIMECHANGE_EMAIL: "error_time_change_email",
		ERROR_TIMECHANGE_PHONE: "error_time_change_phone",
		// ERROR_TIMECHANGE_CONTACT_EMAIL: "error_time_change_contact_email",
		// ERROR_TIMECHANGE_CONTACT_PHONE: "error_time_change_contact_phone",
		ERROR_TIMECHANGE: "error_time_change",
		ERROR_CONTACTID_EXIST: "error_contactid_exist",
		ERROR_OTP_INVALID: "error_otp_invalid",
		ERROR_PASSWORD_INVALID: "error_password_does_not_match",

		//
	},
	STATUS: {
		CHANGE_USERNAME_SUCCESS: "CHANGE_USERNAME_SUCCESS",
		CHANGE_EMAIL_SUCCESS: "CHANGE_EMAIL_SUCESS",
		CHANGE_PHONE_SUCCESS: "CHANGE_PHONE_SUCCESS",
		CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
		VERIFY_EMAIL_SUCCESS: "VERIFY_EMAIL_SUCCESS",
		VERIFY_PHONE_SUCCESS: "VERIFY_PHONE_SUCCESS",
	},

	//Membership
	MEMBER_BENEFITS: [
		{
			title: "quyền lợi",
			descriptions: (rank_id: number) => {
				switch (rank_id) {
					case 1:
						return {
							des: [
								"Giảm 10% cho đơn hàng đầu tiên khi được ghi nhận thành viên",
							],
						};
					case 2:
						return {
							des: ["Giảm 10% cho cho mọi hóa đơn"],
						};

					default:
						return {
							des: ["Giảm 12% cho mọi hóa đơn"],
						};
				}
			},
			icon: "<TrophyIcon/>",
			rank: [1, 2, 3, 4],
		},
		{
			title: "Khuyến mãi",
			descriptions: (rank_id: number) => {
				switch (rank_id) {
					default:
						return {
							des: ["Ưu tiên nhận thông tin chương trình khuyến mãi"],
						};
				}
			},
			icon: "<GiftIcon/>",
			rank: [1, 2, 3, 4],
		},
		{
			title: "thành viên",
			descriptions: (rank_id: number) => {
				switch (rank_id) {
					default:
						return {
							des: ["Giảm giá độc quyền khách hàng"],
						};
				}
			},
			icon: "<UserScanIcon/>",
			rank: [1, 2, 3, 4],
		},
		{
			title: "sinh nhật",
			descriptions: (rank_id: number) => {
				switch (rank_id) {
					case 1:
						return {
							des: ["Giảm 10% / hóa đơn tối đa 15 triệu"],
						};

					case 4:
						return {
							des: ["Giảm 15% / hóa đơn tối đa 15 triệu, Quà tặng bất ngờ"],
						};
					default:
						return {
							des: ["Giảm 15% / hóa đơn tối đa 15 triệu"],
						};
				}
			},
			icon: "<CakeIcon/>",
			rank: [1, 2, 3, 4],
		},
		{
			title: "giao hàng",
			descriptions: (rank_id: number) => {
				switch (rank_id) {
					default:
						return {
							des: ["Miễn phí giao hàng "],
						};
				}
			},
			icon: "<TruckIcon/>",
			rank: [1, 2, 3, 4],
		},
		{
			title: "magazine",
			descriptions: (rank_id: number) => {
				switch (rank_id) {
					default:
						return {
							des: ["Nhận ấn phẩm độc quyền từ namperfume"],
						};
				}
			},
			icon: "<NewsIcon/>",
			rank: [3, 4],
		},
		{
			title: "new design",
			descriptions: (rank_id: number) => {
				switch (rank_id) {
					default:
						return {
							des: ["Ưu tiên trải nghiệm sản phẩm Design mới"],
						};
				}
			},
			icon: "<FeatherIcon/>",
			rank: [3, 4],
		},
		{
			title: "new niche",
			descriptions: (rank_id: number) => {
				switch (rank_id) {
					default:
						return {
							des: ["Ưu tiên trải nghiệm sản phẩm Niche mới"],
						};
				}
			},
			icon: "<StarIcon/>",
			rank: [4],
		},
		{
			title: "đổi trả",
			descriptions: (rank_id: number) => {
				switch (rank_id) {
					case 3:
						return {
							des: ["Gia hạn thời gian đổi trả lên đến 7 ngày"],
						};
					default:
						return {
							des: ["Gia hạn thời gian đổi trả lên đến 14 ngày"],
						};
				}
			},

			icon: "<ExchangeIcon/>",
			rank: [3, 4],
		},
		{
			title: "sự kiện",
			descriptions: (rank_id: number) => {
				switch (rank_id) {
					default:
						return {
							des: ["Nhận vé mời sự kiện VIP của namperfume"],
						};
				}
			},

			icon: "<DiamondIcon />",
			rank: [4],
		},
		{
			title: "hỗ trợ",
			descriptions: (rank_id: number) => {
				switch (rank_id) {
					default:
						return {
							des: ["Được chuyên viên chăm sóc và hỗ trợ"],
						};
				}
			},
			icon: "<HeartIcon/>",
			rank: [4],
		},
	],
	MEMBER_BENEFITS_COMING: {
		MEMBER: [
			{
				title: "Giảm giá độc quyền tại namperfume",
				des: "Hãy tận hưởng các ưu đãi của thẻ Gold. Chúng tôi sẽ cho bạn biết khi phần thưởng này sẵn sàng",
				icon: "<CheckSquareIcon/>",
			},
			{
				title: "Free Shipping",
				des: "Freeship cho mọi đơn hàng",
				icon: "<CheckSquareIcon/>",
			},
		],
		GOLD: [
			{
				title: "Magazine",
				des: "Nhận ấn phẩm độc quyền của namperfume",
				icon: "<LockIcon/>",
			},
			{
				title: "Đổi trả",
				des: "Gia hạn thời gian đổi trả lên đến 7 ngày",
				icon: "<LockIcon/>",
			},
			{
				title: "New Design",
				des: "Ưu tiên trải nghiệm sản phẩm Design mới",
				icon: "<LockIcon/>",
			},
		],
		PLATINUM: [
			{
				title: "New Niche",
				des: "Ưu tiên trải nghiệm sản phẩm Niche mới",

				icon: "<LockIcon/>",
			},
			{
				title: "Hỗ trợ",
				des: "Được chuyên viên chăm sóc và hỗ trợ",
				icon: "<LockIcon/>",
			},
			{
				title: "Sự kiện",
				des: "Nhận vé mời sự kiện VIP của namperfume",
				icon: "<LockIcon/>",
			},
		],
	},

	RANK_TYPE: {
		MEMBER: {
			ID: 1,
			DES: "Thành viên",
			LABEL: "Member",
		},
		GOLD: {
			ID: 2,
			DES: "Thành viên hạng Vàng",
			LABEL: "Gold",
		},
		PLATINUM: {
			ID: 3,
			DES: "Thành viên hạng Bạch Kim",
			LABEL: "Platinum",
		},
		NICHE: {
			ID: 4,
			DES: "Thành viên hạng Niche",
			LABEL: "Niche",
		},
	},

	RANK_EVENT: {
		ITEM_TYPE_UP_RANK: 1,
		ITEM_TYPE_DOWN_RANK: 2,
		ITEM_TYPE_KEEP_RANK: 3,
		ITEM_TYPE_JOIN_REWARD: 4,
	},

	ERROR_TRANSLATE: [
		"customer_error_customer_not_found",
		"customer_api_network_error",
		"customer_api_error_unexpected",
		"customer_error_email_or_phone_required",
		"customer_error_not_old_enough",
		"customer_error_phone_or_email_or_username_required",
		"customer_error_email_invalid",
		"customer_error_phone_invalid",
		"customer_error_platform_invalid",
		"customer_error_version_invalid_format",
		"customer_error_hostname_is_required",
		"customer_error_hostname_invalid_character",
		"customer_error_company_invalid",
		"customer_error_account_invalid",
		"customer_error_jwt_hash_not_found",
		"customer_error_customer_disabled",
		"customer_error_jwt_is_required",
		"customer_error_username_format",
		"customer_error_code_invalid",
		// "customer_CUSTOMER_ALREADY",
		// "customer_CUSTOMER_ALREADY_CREATED",
		// "customer_SUCCESS",

		// "customer_ERROR_CUSTOMER_NOTFOUND",
		// "customer_ERROR_DESTINATION_FORGOT_EMPTY",
		// "customer_ERROR_DESTINATION_CREATE_EMPTY",
		// "customer_ERROR_TYPE_OVER_STEP_FORGOT",
		// "customer_ERROR_ADD_NEW_USER",
		// "customer_ERROR_PHONE_EMPTY",
		"customer_error_password_required",
		"customer_error_email_exists",
		"customer_error_phone_exists",
		// "customer_CUSTOMER_ACTIVATED",
		// "customer_CUSTOMER_UNACTIVATED",
		// "customer_ERROR_USER_CUSTOMER_INVALID",
		// "customer_ERROR_SEND_OTP",
		// "customer_ERROR_TYPE_INVALID",
		// "customer_ERROR_TYPE_OVER_STEP",
		// "customer_ERROR_OVER_LIMIT",

		// "customer_CUSTOMER_NOTFOUND",
		"customer_true",
		"customer_false",
		// "customer_ERROR_OTP_INVALID",
		// "customer_ERROR_CUSTOMER_ACTIVATED",
		// "customer_ERROR_ACTIVATED_CUSTOMER",
		// "customer_ERROR_DESTINATION_VERIFIED",
		// "customer_ERROR_VERIFIED_DESTINATION",
		// "customer_ERROR_OTP_EMPTY",

		"customer_ERROR_DESTINATION_EMPTY",
		"customer_ERROR_OTP_ALREADY",
		"customer_VERIFY_SUCCESS",
		"customer_REGISTER_SUCCESS",
		"customer_CONNECT_ERROR",
		"customer_FETCH_ERROR",
		"customer_error_fullname_required",
		"customer_error_not_found",
		"customer_error_account_not_found",
		"customer_error_time_otp",
		"customer_error_limit_otp_in_day",
		"customer_error_send_otp",
		"customer_error_type_required",
		"customer_error_wrong_code",

		"customer_error_password_change",
		"customer_error_not_owner",
		"customer_error_status_unactivated",
		"customer_error_old_password_required",
		"customer_error_old_password_does_not_match",
		"customer_error_password_to_short",
		"customer_error_password_to_long",
		"customer_error_password2_invalid",
		"customer_error_password_invalid",

		// "customer_ERROR_PASSWORD_EMPTY",
		// "customer_ERROR_PASSWORD_DOES_NOT_MATCH",
		// "customer_ERROR_ACCOUNTID_EMAIL_EMPTY",
		// "customer_ERROR_ACCOUNTID_PHONE_EMPTY",

		"customer_ERROR_EMPTY",
		"customer_error_username_to_short",
		"customer_error_username_to_long",
		"customer_error_user_name_number",
		"customer_error_user_name_exists",
		// "customer_ERROR_EMAIL_FORMAT",
		// "customer_ERROR_PHONE_FORMAT",
		"customer_error_user_name_required",
		"customer_error_update_customer",
		"customer_error_firstname_not_found",
		"customer_error_lastname_not_found",
		"customer_error_update_code",
		"customer_error_get_setting",
		"customer_error_fetch",
	],
};

export const customerAgeRangeOptions = [
	{ label: "Dưới 18 tuổi", value: CustomerAgeRange.CHILD },
	{ label: "18 - 24", value: CustomerAgeRange.YOUNG },
	{ label: "25 - 34", value: CustomerAgeRange.MIDLLE },
	{ label: "35 - 44", value: CustomerAgeRange.MIDLLE_2 },
	{ label: "45 - 54", value: CustomerAgeRange.MIDLLE_3 },
	{ label: "55 - 64", value: CustomerAgeRange.ELDERL },
	{ label: "Trên 65 tuổi", value: CustomerAgeRange.OVER_ELDERLY },
	{ label: "Không xác định", value: CustomerAgeRange.UNKNOWN },
];

export const customerGenderOptions = [
	{ label: "Nam", value: CustomerGenders.MALE },
	{ label: "Nữ", value: CustomerGenders.FEMALE },
	{ label: "Khác", value: CustomerGenders.OTHER },
	{ label: "Không xác Định", value: CustomerGenders.NONE },
];
export default CustomerConstants;
