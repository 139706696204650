import { cn } from "@/utils/utils";
import React, { useId, useState } from "react";
import { Control, FieldPath, FieldValues } from "react-hook-form";
import { Controller, ControllerProps } from "react-hook-form";
import LabelForm, { LabelFormProps } from "./LabelForm";
import Helper from "@/utils/helper";

export type InputProps<
	TFieldValues extends FieldValues,
	TName extends FieldPath<TFieldValues>
> = React.InputHTMLAttributes<HTMLInputElement> & {
	name: TName;
	control?: Control<TFieldValues>;
	placeholder?: string;
} & Pick<
		ControllerProps<TFieldValues, TName>,
		"disabled" | "rules" | "control" | "defaultValue"
	> &
	InputCustom;

type InputCustom = {
	showError?: boolean;
	mode: "line" | "border";
	label?: LabelFormProps;
	typeInput?: "currency" | "default";
	max?: number;
	dissalbeFormItem?: boolean;
};

function Input<
	TFieldValues extends FieldValues,
	TName extends FieldPath<TFieldValues>
>(props: InputProps<TFieldValues, TName>) {
	const {
		name,
		control,
		disabled,
		rules,
		mode,
		label,
		typeInput = "default",
		className,
		dissalbeFormItem,
		...resInput
	} = props;

	const id = useId();
	const [isFocus, setIsFocus] = useState(false);

	function formatNumber(val: any): string {
		if (typeof val === "undefined") return "0";

		const replaceToNumber = val.toString().replace(/[^0-9-]/g, "");
		if (!replaceToNumber.length) return replaceToNumber;
		return parseInt(replaceToNumber, 10)
			.toLocaleString("vi-VN")
			.replace(/\./g, ",");
	}

	const isHasValue = (value: any) => {
		if (typeof value === "number") return true;
		if (typeof value === "string" && value.length > 0) return true;
		return false;
	};

	if (control) {
		return (
			<Controller
				name={name}
				control={control}
				disabled={dissalbeFormItem}
				rules={rules}
				// rules={{
				// 	required: "vui long",
				// 	validate: (value) => {
				// 		return "Vui long nhap";
				// 	},
				// }}
				defaultValue={props.defaultValue}
				render={({ field: { onChange, value, ref }, fieldState }) => {
					return (
						<div className="relative w-full">
							{props.label && (
								<LabelForm
									htmlFor={id + "_" + name}
									// required={!!rules?.required}
									required={!!props.required}
									{...label}
									className={cn("transition w-fit mb-1 block", {
										"translate-y-5":
											(mode === "line" || typeInput === "currency") &&
											!isFocus &&
											!isHasValue(value),
										hidden: props.hidden,
									})}></LabelForm>
							)}

							<input
								id={id + "_" + name}
								className={cn(
									`w-full ${mode === "line" ? "pr-[30px]" : ""}`,
									className,
									{
										"border-b border-solid  leading-5 focus:outline-none text-gray-500 text-base pb-1":
											["line"].includes(mode),
										"border rounded px-3 border-solid border-gray-300 leading-5 focus:outline-none placeholder:text-gray-400 text-gray-500 text-base":
											mode === "border",
										"placeholder-transparent": typeof label !== "undefined",
									}
								)}
								{...resInput}
								disabled={disabled}
								ref={ref}
								value={typeInput === "currency" ? formatNumber(value) : value}
								onChange={(e) => {
									if (typeInput === "currency") {
										const valNumber = Helper.convertCurencyToNumber(
											e.target.value
										);
										if (props?.max && valNumber > props.max) {
											return;
										}
									}
									if (
										props.type === "number" &&
										props?.max &&
										Number(e.target.value) > Number(props.max)
									)
										if (
											props?.max &&
											Helper.convertCurencyToNumber(e.target.value) >
												Number(props.max)
										) {
											return;
										}
									// return;
									if (
										!/^[0-9, ]*$/.test(e.target.value) &&
										["currency"].includes(typeInput)
									)
										return;

									props?.onChange?.(e);
									onChange(e);
								}}
								onBlur={(e) => {
									props?.onBlur?.(e);
									setIsFocus(false);
								}}
								onFocus={(e) => {
									props?.onFocus?.(e);
									setIsFocus(true);
								}}
								onKeyDown={(e) => {
									e.stopPropagation();
									props?.onKeyDown?.(e);
								}}
							/>
							{props.showError && (
								<p className="text-red-500">{fieldState.error?.message}</p>
							)}
						</div>
					);
				}}
			/>
		);
	}

	return (
		<input
			className={cn("w-full", className, {
				"border-b border-solid border-gray-400 leading-5 focus:outline-none text-gray-500 text-base pb-1 pr-[30px]":
					["line", "currency"].includes(mode),
				"border rounded p-3 border-solid border-gray-400 leading-5 focus:outline-none placeholder:text-gray-400 text-gray-500 text-base":
					mode === "border",
				"placeholder-transparent": typeof label !== "undefined",
			})}
			{...resInput}
		/>
	);
}

export default Input;
