import { Option } from "@/components/core/SelectControl";
import { DiscountType } from "@/types/Orders.type";

export enum ORDER_STATUS {
	NEW = 12,
	OPEN = 13,
	CONFIRM = 14,
	PROCESSING = 15,

	SHIPPING = 16,
	SHIPPED = 17,
	RETURNING = 18,
	COMPLETE = 19,
	CANCEL = 21,
}

export enum INVOICE_STATUS {
	OPEN = 13,
	PROCESSING = 15,
	COMPLETE = 19,
	CANCEL = 21,
}

export enum ORDER_TYPE {
	ORDER = 0,
	INVOICE = 1,
}

export enum ORDER_DISCOUNT_TYPE {
	ORDER_DISCOUNT_TYPE_PRECENT = 1,
	ORDER_DISCOUNT_TYPE_AMOUNT = 2,
}

export enum ORDER_CANCEL_REASON {
	CANCEL_REASON_OUT_OF_STOCK = 1,
	CANCEL_REASON_CUSTOMER_DISCARD = 3,
	CANCEL_REASON_CUSTOMER_DISCONNECT = 5,
	CANCEL_REASON_CUSTOMER_NOT_PAID = 7,
	CANCEL_REASON_DUPLICATE = 9,
	CANCEL_REASON_INCORRECT_ITEM = 11,
	CANCEL_REASON_HIGH_SHIPPING_FEE = 13,
	CANCEL_REASON_DELIVERY_NOT_ONTIME = 15,
	CANCEL_REASON_CUSTOMER_NOT_SATISFY = 17,
	CANCEL_REASON_SYSTEM_ERROR = 19,
	CANCEL_REASON_PARTNER_ERROR = 21,
	CANCEL_REASON_OTHER = 99,
}

function getStatus(status: number) {
	const value = orderStatusOptions.find((item) => item.value === status);
	return (
		value || {
			value: status,
			label: "Chưa xác định",
		}
	);
}

const orderStatusOptions: Option[] = [
	{
		label: "Chờ xác nhận",
		value: ORDER_STATUS.OPEN,
		className: "text-blue-500",
	},
	{
		label: "Xác nhận",
		value: ORDER_STATUS.CONFIRM,
		className: "text-green-500",
	},
	{
		label: "Đang xử lí",
		value: ORDER_STATUS.PROCESSING,
		className: "text-yellow-500",
	},
	{
		label: "Đang giao hàng",
		value: ORDER_STATUS.SHIPPING,
		className: "text-yellow-500",
	},
	{
		label: "Đã giao hàng",
		value: ORDER_STATUS.SHIPPED,
		className: "text-green-500",
	},
	{
		label: "Trả hàng",
		value: ORDER_STATUS.RETURNING,
		className: "text-yellow-500",
	},
	{
		label: "Hoàn thành",
		value: ORDER_STATUS.COMPLETE,
		className: "text-green-500",
	},
	{
		label: "Hủy",
		value: ORDER_STATUS.CANCEL,
		className: "text-red-500",
	},
];

const orderCancelReasons: Option[] = [
	{
		value: ORDER_CANCEL_REASON.CANCEL_REASON_OUT_OF_STOCK,
		label: "Hết hàng",
	},
	{
		value: ORDER_CANCEL_REASON.CANCEL_REASON_CUSTOMER_DISCARD,
		label: "Khách không mua nữa",
	},
	{
		value: ORDER_CANCEL_REASON.CANCEL_REASON_CUSTOMER_DISCONNECT,
		label: "💣 BOMB HÀNG",
	},
	{
		value: ORDER_CANCEL_REASON.CANCEL_REASON_CUSTOMER_NOT_PAID,
		label: "Khách không thanh toán",
	},
	{
		value: ORDER_CANCEL_REASON.CANCEL_REASON_DUPLICATE,
		label: "Trùng đơn",
	},
	{
		value: ORDER_CANCEL_REASON.CANCEL_REASON_INCORRECT_ITEM,
		label: "Đặt nhầm sản phẩm",
	},
	{
		value: ORDER_CANCEL_REASON.CANCEL_REASON_HIGH_SHIPPING_FEE,
		label: "Phí vận chuyển cao",
	},
	{
		value: ORDER_CANCEL_REASON.CANCEL_REASON_DELIVERY_NOT_ONTIME,
		label: "Không thể giao hàng đúng giờ",
	},
	{
		value: ORDER_CANCEL_REASON.CANCEL_REASON_CUSTOMER_NOT_SATISFY,
		label: "Khách không hài lòng về dịch vụ",
	},
	{
		value: ORDER_CANCEL_REASON.CANCEL_REASON_SYSTEM_ERROR,
		label: "Lỗi từ hệ thống",
	},
	{
		value: ORDER_CANCEL_REASON.CANCEL_REASON_PARTNER_ERROR,
		label: "Lỗi từ hệ thống của đối tác",
	},
	{
		value: ORDER_CANCEL_REASON.CANCEL_REASON_OTHER,
		label: "Lý do khác",
	},
];

const invoiceStatusOptions: Option[] = [
	{
		label: "Chờ xác nhận",
		value: ORDER_STATUS.OPEN,
	},
	{
		label: "Đang xử lí",
		value: ORDER_STATUS.PROCESSING,
	},
	{
		label: "Hoàn thành",
		value: ORDER_STATUS.COMPLETE,
	},
	{
		label: "Hủy",
		value: ORDER_STATUS.CANCEL,
	},
];
const orderTypeOptions: Option[] = [
	{
		label: "Đặt hàng",
		value: ORDER_TYPE.ORDER,
	},
	{
		label: "Hóa đơn",
		value: ORDER_TYPE.INVOICE,
	},
];
const orderDiscountType = [
	{
		label: "VNĐ",
		value: ORDER_DISCOUNT_TYPE.ORDER_DISCOUNT_TYPE_AMOUNT,
	},
	{
		label: "%",
		value: ORDER_DISCOUNT_TYPE.ORDER_DISCOUNT_TYPE_PRECENT,
	},
];
export {
	orderStatusOptions,
	orderTypeOptions,
	invoiceStatusOptions,
	orderDiscountType,
	orderCancelReasons,
	getStatus,
};
