import { cn } from "@/utils/utils";
import React from "react";

const TransferIcon = ({ className }: { className?: string }) => {
	return (
		<span className={cn("inline-block  text-gray-500", className)}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M22 6.73V13.72C22 16.42 21.37 17.29 19 17.43V10.28C19 7.3 18.24 6.55 15.22 6.55H5.78003C5.50003 6.55 5.24 6.56 5 6.57C5.03 3.72 5.81003 3 8.78003 3H18.22C21.24 3 22 3.75 22 6.73Z"
					fill="#CBE6FF"
					stroke="#1876FF"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M19 10.2808V17.4308C18.97 20.2808 18.19 21.0008 15.22 21.0008H5.78003C2.76003 21.0008 2 20.2508 2 17.2708V10.2808C2 7.58078 2.63 6.71078 5 6.57078C5.24 6.56078 5.50003 6.55078 5.78003 6.55078H15.22C18.24 6.55078 19 7.30078 19 10.2808Z"
					fill="#1876FF"
					stroke="#004B8F"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M2 12.6094H19"
					stroke="#004B8F"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M5.25 17.8105H6.96997"
					stroke="white"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M9.10938 17.8105H12.5494"
					stroke="white"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</span>
	);
};

export default TransferIcon;
