import { ErrorServer } from "@/common/api/Error";
import { callApi, callApiV2 } from "@/lib/config/Api";
import { ResponseApiClient } from "@/lib/config/axiosClient";
import {
	LoginJson,
	LoginPostJson,
	ResendPostJson,
	ResetPasswordPostJson,
	SenderJson,
	SignupJson,
	SignupPostJson,
	VerifyJson,
	VerifyPostJson,
	loginACPostJson,
} from "@/types/Auth.type";
import Helper from "@/utils/helper";

export async function login(data: LoginPostJson) {
	const response = await callApiV2<any>("/users/customer/login", "post", {
		data: {
			account_id: data.account_id,
			password: data.password,
			platform: 1,
			hostname: process.env.REACT_APP_BASE_URL_HOST,
			version: "1.0.0",
		},
		timeout: 1000 * 10,
	});
	return response;
}

export async function checkSender(
	data: ResendPostJson,
	handleFailed?: (err: ErrorServer | null) => void
): Promise<ResponseApiClient<boolean>> {
	const response = await callApi<any>("/customers/public/checksender", "post", {
		data: {
			...data,
		},
	});
	if (!response.data) {
		handleFailed && handleFailed(response.error);
	}
	return {
		data: response.data,
	};
}

export async function signUp(
	data: SignupPostJson,
	handleFailed?: (err: ErrorServer | null) => void
): Promise<ResponseApiClient<SignupJson>> {
	const response = await callApi<any>("/customers/register", "post", {
		data: {
			...data,
		},
	});
	if (!response.data) {
		handleFailed && handleFailed(response.error);
	}
	return {
		data: response.data,
	};
}

export async function loginActiveCode(
	data: loginACPostJson,
	handleFailed?: (err: ErrorServer | null) => void
): Promise<ResponseApiClient<LoginJson>> {
	const response = await callApi<any>("/users/customer/codelogin?", "post", {
		data: {
			platform: 1,
			hostname: process.env.REACT_APP_BASE_URL_HOST,
			version: "1.0.0",
		},
		params: { code: data.code },
	});
	if (!response.data) {
		handleFailed && handleFailed(response.error);
	}
	return {
		data: response.data,
	};
}

export async function verifyOtp(data: VerifyPostJson) {
	const response = await callApiV2<VerifyJson>(
		"/customers/public/verify",
		"post",
		{
			data: data,
		}
	);

	return response;
}

export async function sender(
	data: ResendPostJson,
	handleFailed?: (err: ErrorServer | null) => void
): Promise<ResponseApiClient<SenderJson>> {
	const response = await callApi<any>("/customers/public/sender", "post", {
		data: {
			...data,
		},
	});

	if (!response.data) {
		handleFailed && handleFailed(response.error);
	}
	return {
		data: response.data,
	};
}

export async function resetPassword(
	data: ResetPasswordPostJson,
	handleFailed?: (err: ErrorServer | null) => void
): Promise<ResponseApiClient<any>> {
	const response = await callApi<any>("/users/customer/resetpassword", "post", {
		data: {
			account_id: data.account_id.trim(),
			password: data.password,
			password2: data.password2,
			code: data.code.trim(),
			hostname: process.env.REACT_APP_BASE_URL_HOST,
		},
	});
	if (!response.data) {
		handleFailed && handleFailed(response.error);
	}
	return {
		data: response.data,
	};
}

export async function logout(isPos?: boolean): Promise<ResponseApiClient<any>> {
	try {
		const response = await callApi<any>("/users/customer/logout", "post", {
			data: {},
		});

		return {
			data: response.data,
		};
	} catch (error) {
		throw error;
	} finally {
		!isPos && Helper.removeJWTLocal();
	}
}
