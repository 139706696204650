import Helper from "@/utils/helper";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getSession, signOut } from "next-auth/react";
import axiosRetry from "axios-retry";
import detectEmployee from "@/utils/detectEmployee";
import { useCookies } from "react-cookie";
import COOKIES from "@/common/constants/cookies";
import { clearSessionPos, getSessionPos } from "@/utils/actionSessionPos";
import { KEY_LOCAL_POS_JWT } from "../hooks/useSessionPos";

export interface ResponseApiClient<T> {
	data?: T | null;
	errors?: string[];
	status_code?: number;
}

const source = axios.CancelToken.source();

const AxiosInstance = axios.create({
	timeout: 1000 * 60,
	headers: {
		"Content-Type": "application/json",
	},
	"axios-retry": {
		retries: 3, // Truyền số lần retry ở đây
	},
	cancelToken: source.token,
});
axiosRetry(AxiosInstance, {
	retries: AxiosInstance.defaults["axios-retry"]?.retries || 3,

	retryCondition: (error) => {
		const status = error?.response?.status || 500;
		const dataError = error.response?.data;

		if (status === 401) {
			return false;
		}

		if (status === 400 && dataError) {
			const err = { ...dataError } as any;
			if (err?.error?.includes("error_jwt_blacklisted")) {
				return false;
			}
		}
		if (status >= 500) {
			const currentUrl = error?.config?.url || "";
			// return retryUrls.some((url) => currentUrl.includes(url));
			return true;
		}

		if (status >= 300 && status < 400) {
			return true;
		}

		return false;
	},
	retryDelay: (retryCount) => {
		return retryCount * 1000; // delay between retries (1s)
	},

	shouldResetTimeout: true,
	onRetry: (retryCount, error, requestConfig) => {
		// requestConfig.data = JSON.parse(
		// 	JSON.stringify(AxiosInstance.defaults.data)
		// );
	},
});

let tokenPosDefault: string | null = null;
AxiosInstance.interceptors.request.use(async (request) => {
	const requestUrl = request.url || "";
	const arrUser = [
		"customers",
		"users",
		"user",
		"cart",
		"invoice",
		"locations",
		"wishlists",
		"employees",
		"shippingcarriers",
		"preorder",
		"deliveries",
		"logout",
		"cashflowreceipts",
		"admin",
		"order",
	];

	if (
		!requestUrl.includes("public") &&
		!requestUrl.includes("login") &&
		arrUser.some((i) => requestUrl.includes(i))
	) {
		let token = "";
		let isPos = false;
		if (!Helper.isServer()) {
			isPos = window.location.href.includes("pos");
		}

		if (!isPos) {
			const session = await getSession();

			if (session) {
				const isEmployee = detectEmployee(session.user);

				if (!isEmployee) {
					token = session.user.jwt;
				}
			}
		} else {
			const isExistedJwtPosOnLocal = !!localStorage.getItem(KEY_LOCAL_POS_JWT);
			if (!isExistedJwtPosOnLocal) {
				tokenPosDefault = null;
			}

			if (!tokenPosDefault) {
				const tokenPos = await getSessionPos();

				tokenPosDefault = tokenPos?.jwt ?? null;
				if (!tokenPosDefault) {
					// source.cancel("request_not_authentication");
				}
			} else {
			}

			if (tokenPosDefault) {
				token = tokenPosDefault;
			}
		}

		// if (!Helper.isServer()) {
		// 	const tokenLocal = localStorage.getItem("JWT");
		// 	if (tokenLocal) {
		// 		token = tokenLocal;
		// 	} else {
		// 		const session = await getSession();
		// 		const tokenSession = session?.user.jwt;
		// 		if (tokenSession) {
		// 			token = tokenSession;
		// 			localStorage.setItem("JWT", token);
		// 		}
		// 	}
		// } else {
		// 	const session = await getSession();
		// 	token = session?.user.jwt || "";
		// }

		if (token.length) {
			request.headers.Authorization = `${token}`;
		}
	}

	// If this is GET method, append jwt to param, to prevent prefetch request
	if (request.method === "get") {
		request.params = {
			...request.params,
		};
	} else {
		// Append company_id to POST/PUT request data
		// request.data = {
		// 	...request.data,
		// };
	}

	return request;
});
let isSignOutCalled = false;

AxiosInstance.interceptors.response.use(
	(response: AxiosResponse) => {
		return response;
	},
	async (error) => {
		// const originalRequest = error.config as AxiosRequestConfig & {
		// 	_retryCount?: number;
		// };
		// const currentUrl = originalRequest.url || "";

		// if (retryUrls.some((url) => currentUrl.includes(url))) {
		// 	originalRequest._retryCount = originalRequest._retryCount || 0;

		// 	if (originalRequest._retryCount < 3) {
		// 		originalRequest._retryCount++;
		// 		originalRequest.timeout = 5000;
		// 		originalRequest.data = JSON.parse(originalRequest.data);
		// 		await new Promise((res) => setTimeout(res, 1000));
		// 		return AxiosInstance(originalRequest);
		// 	}
		// }

		if (error.response) {
			if (error.response.status >= 500) {
				throw new Error("Server Error");
			}

			const isTokenExpired = error.response.status === 401;
			const isTokenBacklist =
				error.response.status === 400 &&
				error.response?.data?.error?.includes("error_jwt_blacklisted");

			if (isTokenExpired || isTokenBacklist) {
				if (!isSignOutCalled) {
					isSignOutCalled = true; // Đặt cờ mới trong quá trình xử lý đầu tiên
					// Helper.removeJWTLocal();
					if (window.location.pathname.includes("/pos")) {
						// await signOut({ redirect: true, callbackUrl: "/pos" });
						tokenPosDefault = null;
						await clearSessionPos();

						window.location.href = "/pos";
					} else {
						await signOut({ redirect: true });
					}
				}
			}
		}

		return Promise.reject(error);
	}
);

export default AxiosInstance;
