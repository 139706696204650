"use client";
import { useController, FieldValues, FieldPath } from "react-hook-form";
import React, { useId, useState } from "react";
import { cn } from "@/utils/utils";
import ErrorControl from "../core/components/ErrorControl";
import InputCore, { InputCoreProps } from "../core/InputCore";
import LabelControl from "../core/components/LabelControl";
export interface InputPropsCustom {
	label?: string | React.ReactNode;
	// type?: "email" | "password" | "search" | "tel" | "text" | "url" | "date";
	showError?: boolean;
	icon?: React.ReactNode;
	classNameContainer?: string;
}
function InputLine<
	TFieldValues extends FieldValues,
	TName extends FieldPath<TFieldValues>
>(props: InputCoreProps<TFieldValues, TName> & InputPropsCustom) {
	const {
		classNameContainer,
		icon,
		onBlur,
		onFocus,
		className,
		label,
		...resProps
	} = props;
	const { control, name, rules } = props;
	const { field, fieldState } = useController({ name, control, rules });
	const [isFocus, setIsFocus] = useState(false);

	const id = useId();
	const isHasValue = (value: any) => {
		if (typeof value === "number" && value >= 0) {
			return true;
		}

		if (typeof value === "string" && value.length > 0) {
			return true;
		}
		return false;
	};
	return (
		<div className={cn("flex flex-col h-auto", classNameContainer)}>
			{label ? (
				<LabelControl
					htmlFor={id + "-" + name}
					className={cn("transition w-fit mb-1", {
						"translate-y-5": !isFocus && !isHasValue(field.value),
					})}
					isrequired={
						typeof props.rules?.required !== "undefined" || props.required
					}>
					{label}
				</LabelControl>
			) : null}
			<div className="flex-1 relative">
				<InputCore
					{...resProps}
					onBlur={(e) => {
						onBlur?.(e);
						setIsFocus(false);
					}}
					onFocus={(e) => {
						onFocus?.(e);
						setIsFocus(true);
					}}
					id={id + "-" + name}
					className={cn(
						"w-full border-b border-solid border-gray-400 leading-5 focus:outline-none text-gray-500 text-base pb-1 pr-[30px]",
						className
						// fieldState.error && "border-red-500"
					)}
					inputClassName={`w-full ${props.inputClassName}`}
				/>
				<div className="  absolute right-3 top-0 bottom-0  flex items-center">
					{icon}
				</div>
			</div>
		</div>
	);
}
export default InputLine;
