import { cn } from "@/utils/utils";
import React from "react";

const LogoutIcon = ({ className }: { className?: string }) => {
	return (
		<span className={cn("inline-block  text-gray-500", className)}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M8.89844 7.56023C9.20844 3.96023 11.0584 2.49023 15.1084 2.49023H15.2384C19.7084 2.49023 21.4984 4.28023 21.4984 8.75023V15.2702C21.4984 19.7402 19.7084 21.5302 15.2384 21.5302H15.1084C11.0884 21.5302 9.23844 20.0802 8.90844 16.5402"
					stroke="#1A1A1A"
					strokeWidth="1.1"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M14.9972 12H3.61719"
					stroke="#1A1A1A"
					strokeWidth="1.1"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M5.85 8.65039L2.5 12.0004L5.85 15.3504"
					stroke="#1A1A1A"
					strokeWidth="1.1"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</span>
	);
};

export default LogoutIcon;
