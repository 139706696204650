"use Client";
import React from "react";
import { cn } from "@/utils/utils";
import LoadingIcon from "@/components/icons/LoadingIcon";
//
export type ButtonProps = {
	buttonProps?: Omit<
		React.ButtonHTMLAttributes<HTMLButtonElement>,
		"className"
	>;
	loading?: boolean;
	pending?: boolean;
	disabled?: boolean;
	className?: string;
	label?: React.ReactNode;
	mode?:
		| "main"
		| "dark"
		| "light"
		| "main-outline"
		| "dark-outline"
		| "lightgray"
		| "secondGray";
	type?: "submit" | "reset" | "button";
	children?: React.ReactNode;
	iconLoading?: React.ReactNode;
	classNameIcon?: string;
	onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};
const Button = ({
	className,
	mode = "dark",
	loading = false,
	pending = false,
	disabled = false,
	buttonProps,
	label = <></>,
	iconLoading,
	type = "button",
	children,
	classNameIcon,
	onClick,
}: ButtonProps) => {
	return (
		<button
			className={cn(
				"min-h-[34px] h-auto px-[16px] py-[8px] flex justify-center items-center gap-2 border-[1px] whitespace-nowrap rounded-[4px] text-sm line-clamp-1 text-ellipsis border-gray-500 bg-gray-500 text-white",
				// {" justify-between" : loading},
				className,
				{
					"border-red-500 bg-red-500 text-white": mode === "main",
					"border-gray-500 bg-white text-gray-500":
						mode === "light" || mode === "dark-outline",
					"border-red-500 bg-white text-red-500": mode === "main-outline",
					"border-white bg-white text-gray-500": mode === "lightgray",
					"border-gray-400 bg-gray-400 text-white": mode === "secondGray",
					"cursor-not-allowed": disabled || loading,
					"bg-gray-300 border-gray-400 text-gray-400":
						disabled && mode === "main",
				}
			)}
			disabled={disabled ? disabled : loading}
			onClick={onClick}
			type={type}>
			{
				<>
					{loading ? (
						<>
							{iconLoading ? (
								iconLoading
							) : (
								<LoadingIcon
									className={cn(
										"w-3 h-3 text-gray-400 flex-shrink-0",
										classNameIcon,
										{
											"text-white": mode === "main",
										}
									)}
								/>
							)}
						</>
					) : null}

					{children ? children : label}
				</>
			}
		</button>
	);
};

export default Button;
