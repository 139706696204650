import { getSettingDealthom } from "@/redux/features/PromotionSlice";
import { useAppSelector } from "@/redux/hook";
import { ProductJson } from "@/types/Product.type";
import { PromotionGroupType, PromotionJson } from "@/types/Promotion.type";
import { getPromotionValid } from "@/utils/product";
import useTimeServer from "./useTimeServer";
import useGetCodePromotion from "./useGetCodePromotion";

export default function useDealthom(product: ProductJson) {
	const promotionCouponItems = product.promotions;
	// const settingDealthom = useAppSelector(getSettingDealthom);
	const { data: time_server } = useTimeServer();

	const promotionsActive = getPromotionValid(
		promotionCouponItems,
		time_server?.data.time_server || new Date().getTime() / 1000
	);
	const promotionCode = promotionsActive.find(
		(pro) => pro.group === PromotionGroupType.coupon
	);

	const { data: codes } = useGetCodePromotion(promotionCode?.id || 0, {
		enabled: !!promotionCode?.id,
	});
	const promotionsHaveConfig: PromotionJson[] = promotionsActive.map((data) => {
		return {
			...data,
			codes: Array.isArray(codes)
				? codes?.filter((code) => code.promotion_id === data.campaign_info.id)
				: undefined,
		};
	});
	return {
		promotionsHaveConfig,
	};
}
