"use client";
import React, { useCallback, useEffect, useState } from "react";
import MapElements from "@/components/MapElements";
import ProductVariantItem from "./children/ProductVariantItem";
import { VariantsFromTags } from "@/lib/hooks/useVariants";
import { cn } from "@/utils/utils";
import { ProductJson } from "@/types/Product.type";
import ProductVariantList, { PropsVariantList } from "./ProductVariantList";
import { useAppDispatch } from "@/redux/hook";
import { setTagActive } from "@/redux/features/ShopSlice";
type Props = Omit<PropsVariantList, "variants" | "tag"> & {
	variantFromTags: VariantsFromTags[];
	elementLoadings?: React.ReactNode;
	className?: string;
	classNameList?: string;
	classNameGroup?: string;
	classNameTag?: string;
	classNameLoading?: string;
	isShowPromotion?: boolean;
	isShowImage?: boolean;
	emptyElement?: React.ReactNode | React.JSX.Element;
	showOnlySize?: boolean;
	showPromotion?: boolean;
};
export default function ProductVariantGroup({
	loading,
	variantActive,
	variantFromTags,
	elementLoadings,
	classNameList,
	className,
	classNameTag,
	classNameLoading,
	classNameItem,
	isShowPromotion,
	isShowImage,
	emptyElement,
	classNameGroup,
	renderItem,
	handleActiveVariant,
	showOnlySize,
	showPromotion,
}: Props) {
	const dispatch = useAppDispatch();

	const COLLECTION_TINTED = "son-moi";
	const isLipstick = variantActive.collections.some(
		(item) => item.handle === COLLECTION_TINTED
	);

	let colorTinted = "";
	let matterialTinted = "";
	if (isLipstick) {
		const tag = variantActive.tags.find((item) => item.type === "PRODUCT SIZE");
		if (tag) {
			const colors = tag.value.split("/");
			matterialTinted = colors[0];
			colorTinted = colors[colors.length - 1];
		}
	}

	useEffect(() => {
		if (variantFromTags.length > 0) {
			dispatch(setTagActive(variantFromTags[0].name));
		}
	}, [variantFromTags.length]);
	return (
		<div className={cn("variant_group flex flex-col gap-6  ", className)}>
			{loading ? (
				elementLoadings ? (
					elementLoadings
				) : (
					<div
						className={cn(
							"grid md:grid-cols-2 grid-cols-1 gap-1",
							classNameLoading
						)}>
						<li className="h-[44px] rounded bg-gray-200 border border-gray-300 px-3 flex flex-col gap-1 justify-center animate-pulse">
							<p className="w-full h-1 rounded-full bg-gray-300"></p>
							<p className="w-[30%] h-1 rounded-full bg-gray-300"></p>
						</li>
						<li className="h-[44px] rounded bg-gray-200 border border-gray-300 px-3 flex flex-col gap-1 justify-center animate-pulse">
							<p className="w-full h-1 rounded-full bg-gray-300"></p>
							<p className="w-[30%] h-1 rounded-full bg-gray-300"></p>
						</li>
					</div>
				)
			) : variantFromTags.length > 0 ? (
				<MapElements
					data={variantFromTags}
					render={(item, index) => {
						return (
							<div className={cn(" flex flex-col gap-2", classNameGroup)}>
								<div
									className={cn(
										"text-gray-500 capitalize md:font-normal  text-sm leading-[1.6]",
										classNameTag
									)}>
									{isLipstick ? (
										<div className=" text-base">
											<p>
												<b className=" font-bold ">Chất son: </b>{" "}
												{matterialTinted}
											</p>
											<p>
												<b className=" font-bold">Màu sắc: </b> {colorTinted}
											</p>
										</div>
									) : (
										<>{item.name}</>
									)}
								</div>
								<ProductVariantList
									showPromotion={showPromotion}
									showOnlySize={showOnlySize}
									variantActive={variantActive}
									variants={item.data}
									tag={item.name}
									className={classNameList}
									classNameItem={classNameItem}
									loading={loading}
									renderItem={renderItem}
									isShowPromotion={isShowPromotion}
									handleActiveVariant={handleActiveVariant}
									isShowImage={isShowImage}
								/>
							</div>
						);
					}}></MapElements>
			) : (
				<>{emptyElement && emptyElement}</>
			)}
		</div>
	);
}
