"use client";
import { useAppDispatch, useAppSelector } from "@/redux/hook";
import { setCartDetail, setCartLoading } from "@/redux/features/CartSlice";
import { useEffect, useMemo, useState } from "react";
import { CartJson, IsUse } from "@/types/Cart.type";
import { useSession } from "next-auth/react";
import { getListMyCart, getListMyCartPublic } from "@/lib/api/shop/cart";
import { getDataError } from "@/lib/config/apiHandler";
import useStoreId from "@/lib/hooks/useStoreId";
import useBrowerId from "@/lib/hooks/useBrowerId";
import usePromotions from "@/lib/hooks/usePromotions";
import {
	ConfigDealthom,
	PromotionDiscountType,
	PromotionGroupType,
	PromotionJson,
} from "@/types/Promotion.type";
import { getSettingV2 } from "@/lib/api/shop/setting";
import { PromotionContants } from "@/common/constants/promotion";
import {
	setPromotionAll,
	setPromotionCouponItem,
	setPromotionCouponOrder,
	setPromotionSeasonalItem,
	setPromotionSeasonalOrder,
	setSettingDealthom,
} from "@/redux/features/PromotionSlice";
import useOrder from "@/lib/hooks/useOrder";
import CartOrderModel from "@/common/models/CartOrderModel";
import { getPromotionValid } from "@/utils/product";
import detectEmployee from "@/utils/detectEmployee";

type Props = {
	children: React.ReactNode;
};

const CartProviders = ({ children }: Props) => {
	const dispatch = useAppDispatch();
	const { data, status } = useSession();
	const user = data?.user.company;
	let isUser = false;
	if (user && !user.employee) {
		isUser = true;
	}
	const { data: browser_id, isLoading, isError, error } = useBrowerId();
	const { data: storeId } = useStoreId();

	const { data: promotionList } = usePromotions();

	const [cartJson, setCartJson] = useState<null | CartJson>(null);

	const cartDetail = useAppSelector((state) => state.CartReducer.cartDetail);

	const { addPromotionBody } = useOrder({
		order: { ...CartOrderModel.getDefaultData(), id: cartJson?.id || "" },
		onUpdateOrder(order) {
			dispatch(setCartDetail(order));
		},
		skipInit: true,
	});

	const promotionCart = getPromotionValid(promotionList || [])?.filter(
		(item) =>
			item.discount_type === PromotionDiscountType.CART &&
			item.group === PromotionGroupType.seasonal
	);

	const getCartUser = async (store_id: number) => {
		try {
			const myCartCollection = (
				await getListMyCart({ sort_type: "DESC", limit: 1 })
			).data;
			let res: CartJson | null = null;
			if (myCartCollection.items.length > 0) {
				res = myCartCollection.items[0];
			}
			return res;
		} catch (error) {
			throw error;
		}
	};
	const getCartPublic = async (store_id: number, browser_id: string) => {
		try {
			let res: CartJson | null = null;
			const listMyCartPublic = (
				await getListMyCartPublic(browser_id, { sort_type: "DESC", limit: 1 })
			).data;
			if (listMyCartPublic.items.length > 0) {
				res = listMyCartPublic.items[0];
			}
			return res;
		} catch (error) {
			throw error;
		}
	};

	async function init(store_id: number, browser_id: string) {
		try {
			dispatch(setCartLoading(true));
			let cart: CartJson | null = null;
			if (isUser) {
				cart = await getCartUser(store_id);
			} else {
				cart = await getCartPublic(store_id, browser_id);
			}
			if (cart) {
				setCartJson(cart);
				dispatch(setCartDetail(JSON.parse(JSON.stringify(cart))));
			}
		} catch (error) {
			// const dataError = getDataError(error);
			// setErr(JSON.stringify(dataError));
		} finally {
			dispatch(setCartLoading(false));
		}
	}

	async function initPromotion() {
		const isEmployee = data ? detectEmployee(data?.user) : false;
		if (isEmployee) {
			return;
		}
		if (!cartDetail?.id || promotionCart?.length !== 1) return;

		const promotion = promotionCart[0];
		const isPromotionExisted = cartDetail.promotions.some(
			(promo) => promo.promotion_id === promotion.id
		);

		if (!isPromotionExisted) {
			setTimeout(async () => {
				await addPromotionBody(
					promotionCart,
					cartDetail.customer_token,
					cartDetail.id
				);
			}, 1000);
		}
	}

	// async function getConfigDealthom(promotions: PromotionJson[]) {
	// 	try {
	// 		if (promotions.length > 0) {
	// 			const settingDealthom = await getSettingV2(
	// 				PromotionContants.setting_dealthom
	// 			);
	// 			const dataDealthom = settingDealthom.data.value as ConfigDealthom[];
	// 			dispatch(setSettingDealthom(dataDealthom));
	// 		}
	// 	} catch (error) {
	// 		const dataError = getDataError(error);
	// 		setErr(JSON.stringify(dataError));
	// 		setIsErr(true);
	// 	}
	// }

	useEffect(() => {
		if (status === "loading") {
			return;
		}
		const isPos = window.location.href.includes("/pos")
			? true
			: data
			? detectEmployee(data?.user)
			: false;
		if (storeId && browser_id && !isPos) {
			init(storeId, browser_id);
		}
	}, [status, storeId, browser_id, isUser]);

	useEffect(() => {
		initPromotion();
	}, [
		cartDetail?.id,
		cartDetail?.customer_token,
		JSON.stringify(cartDetail?.details.data),
		JSON.stringify(promotionCart),
	]);

	useEffect(() => {
		if (promotionList && promotionList.length > 0) {
			const promotionCouponOrder = promotionList.filter(
				(p) =>
					p.group === PromotionGroupType.coupon &&
					p.discount_type === PromotionDiscountType.CART
			);
			const promotionCouponItem = promotionList.filter(
				(p) =>
					p.group === PromotionGroupType.coupon &&
					p.discount_type === PromotionDiscountType.PRODUCT
			);
			const promotionSeasonalOrder = promotionList.filter(
				(p) =>
					p.group === PromotionGroupType.seasonal &&
					p.discount_type === PromotionDiscountType.CART
			);
			const promotionSeasonalItem = promotionList.filter(
				(p) =>
					p.group === PromotionGroupType.seasonal &&
					p.discount_type === PromotionDiscountType.PRODUCT
			);

			dispatch(setPromotionAll(promotionList));
			dispatch(setPromotionSeasonalItem(promotionSeasonalItem));
			dispatch(setPromotionSeasonalOrder(promotionSeasonalOrder));
			dispatch(setPromotionCouponItem(promotionCouponItem));
			dispatch(setPromotionCouponOrder(promotionCouponOrder));
		}
	}, [promotionList]);

	// useEffect(() => {
	// 	if (promotionList) {
	// 		getConfigDealthom(promotionList);
	// 	}
	// }, [promotionCoupon]);

	// if (isErr || isError) {
	// 	return (
	// 		<ErrorCustom
	// 			from="cart"
	// 			error={{
	// 				...error,
	// 				name: "Cart provider",
	// 				message: err
	// 					? err
	// 					: error?.message
	// 					? error?.message
	// 					: "Cartprovider error",
	// 			}}
	// 			reset={() => {
	// 				location.reload();
	// 			}}
	// 		/>
	// 	);
	// }
	return <main>{children}</main>;
};

export default CartProviders;
