import { ShipType } from "@/app/pos/_components/PostCart";
import CashFlow from "@/common/constants/cashflow";
import {
	CartOrderJson,
	CartPromotion,
	IsUse,
	ProductCartAddEditJson,
} from "@/types/Cart.type";
import {
	CashflowreceiptJson,
	CashflowreceiptsMethod,
} from "@/types/Cashflowreceipts.type";
import { CustomerQuickRegisterJson } from "@/types/Customer.type";
import { OrderJson, SERVICE_TYPE } from "@/types/Orders.type";
import {
	PaymentDataBilling,
	PaymentDataShipping,
	PaymentDataSubmit,
} from "@/types/Payment.type";
import { ProductJson } from "@/types/Product.type";
import { PromotionJson } from "@/types/Promotion.type";
import Helper from "@/utils/helper";
import { mergeObjects } from "@/utils/utils";
import { useId } from "react";
import { DataLocalCashflow } from "../hooks/useCashFlowsLocal";

type Json = {
	order: OrderJson;
	defaultParams?: Object;
};
class OrderRepo {
	private order: OrderJson;
	private defaultParams: Object;
	private static instance: OrderRepo;

	private constructor(json: Json) {
		this.order = json.order;
		this.defaultParams = json.defaultParams || {};
	}

	public static getInstance(json: Json): OrderRepo {
		if (!OrderRepo.instance) {
			OrderRepo.instance = new OrderRepo(json);
		}
		return OrderRepo.instance;
	}

	//////////////////////////////////
	// calc

	static calcPriceOrderItem(item: CartOrderJson, q?: number) {
		const quantity = q ?? 1;
		const product = item.product_json;
		const discount = item.price_discount / item.item_quantity;
		const p_price_preview = product.pricePreview;
		const p_compare = product.compare_at_price;
		const p_price = product.price;
		const final = () => {
			let result = product.price;
			if (discount) {
				result = item.price_unit_final;
			}
			return result * quantity;
		};
		const compare = () => {
			let result = p_compare;

			if (p_compare <= 0 && discount > 0) {
				result = p_price;
			}

			if (p_compare <= p_price) {
				result = 0;
			}

			return result * quantity;
		};
		const dataFinal = final();
		const dataCompare = compare();

		const percent = () => {
			const compareInit = dataCompare / quantity;
			const final = p_price_preview?.price_final || dataFinal;

			if (compareInit > 0) {
				if (final !== undefined) {
					return compareInit <= final
						? 0
						: ((compareInit - final) / compareInit) * 100;
				}
				return ((compareInit - p_price) / compareInit) * 100;
			}

			if (final !== undefined) {
				return p_price <= final ? 0 : ((p_price - final) / p_price) * 100;
			}

			return 0;
		};

		const dataPercent = percent();
		return {
			priceFinal: dataFinal,
			priceCompare: dataCompare,
			percent: dataPercent,
		};
	}

	public calcValueCashFlow(cash: CashflowreceiptJson[]) {
		// const;
	}

	//convert
	static convertPromotionToOrderPromotion(
		promotions: PromotionJson[],
		quantity: number = 1,
		isUse?: IsUse,
		productId?: ProductJson["id"]
	): CartPromotion[] {
		return promotions
			.filter((pro) => pro.status)
			.map((p) => {
				return Helper.convertParams({
					is_use: isUse ?? p.is_use,
					code: p.code,
					product_id: productId,
					promotion_detail: p,
					promotion_id: p.campaign_info.id,
					sale_promotion_id: p.id,
					// item_quantity: quantity,
				});
			}) as CartPromotion[];
	}

	static convertCashFlowsFromPaymentMethod() {}

	static convertPaymentDataToQuickRegisterCustomer(
		data: Partial<PaymentDataSubmit>
	) {
		const {
			billing_province,
			billing_district,
			billing_ward,
			billing_phone,
			billing_country,
			billing_fullname,
			billing_address,
		} = data;
		const submitData: CustomerQuickRegisterJson = {
			full_name: billing_fullname?.trim() || "",
			phone: billing_phone?.trim() || "",
			address: billing_address?.trim() || "",
			province: billing_province || 0,
			district: billing_district || 0,
			ward: billing_ward || 0,
			country: billing_country || 0,
		};
		return submitData;
	}
	static convertProductToProductOrder(
		productId: ProductJson["id"],
		quantity: number = 1,
		promotionsOrder: CartPromotion[] = []
	): ProductCartAddEditJson {
		return {
			item_quantity: quantity,
			product_id: productId,
			promotions: promotionsOrder,
		};
	}

	static convertDataShippingForCreateOrder(
		data: Omit<PaymentDataSubmit, "order_id">
	) {
		console.log("🚀 ~ OrderRepo ~ data:", data);
		try {
			const newData = { ...data } as any;
			const shippingKeys: Array<keyof PaymentDataShipping> = [
				"shipping_fullname",
				"shipping_phone",
				"shipping_address",
				"shipping_full_address",
				"shipping_ward",
				"shipping_district",
				"shipping_province",
				"shipping_country",
				"shipping_firstname",
				"shipping_lastname",
				"shipping_email",
			];

			const billingKeys: Array<keyof PaymentDataBilling> = [
				"billing_fullname",
				"billing_firstname",
				"billing_lastname",
				"billing_phone",
				"billing_address",
				"billing_province",
				"billing_district",
				"billing_email",
				"billing_ward",
				"billing_country",
			];

			const combinedKeys = new Set([...shippingKeys, ...billingKeys]);
			console.log("🚀 ~ OrderRepo ~ combinedKeys:", combinedKeys);

			const extractedData: Record<string, any> = {};

			for (const key of combinedKeys) {
				if (key in data) {
					extractedData[key] = newData[key];
				}
			}
			console.log("🚀 ~ OrderRepo ~ extractedData:", extractedData);

			return extractedData;
		} catch (error) {
			return {};
		}
	}

	public static convertPromotionForCartItem(
		cartItem: CartOrderJson,
		quantity: number
	): CartPromotion[] {
		const promotionInItem = cartItem.promotions;
		return promotionInItem.map((p) => ({
			...p,
			item_quantity: quantity,
			is_use: 1,
		}));
	}

	public static convertDetailsForUpdateQuantityItem(
		cartItem: CartOrderJson,
		quantity: number
	): ProductCartAddEditJson | null {
		// const cartItem = this.order.details.data.find(
		// 	(item) => item.id === cartItemId
		// );
		// if (!cartItem) return null;
		return {
			item_quantity: quantity,
			product_id: cartItem.product_id,
			id: cartItem.id,
			is_use: cartItem.is_use,
			promotions: this.convertPromotionForCartItem(cartItem, quantity),
		};
	}

	static addPromotionInListOrderItems(
		cartItems: CartOrderJson[],
		promotions: PromotionJson[]
	) {}

	public static convertDetailCart(details: CartOrderJson[]) {
		return details.map((item) => ({
			item_quantity: item.item_quantity,
			product_id: item.product_id,
			id: item.id,
			is_use: item.is_use,
			promotions: item.promotions,
		}));
	}
	//utils

	public static utilGetLableMethodCashFlow(value: number) {
		switch (value) {
			case CashflowreceiptsMethod.CASH:
				return "Tiền mặt";
			case CashflowreceiptsMethod.CARD:
				return "Visa/Master";
			case CashflowreceiptsMethod.COD:
				return "COD";
			case CashflowreceiptsMethod.TRASNFER:
				return "Chuyển khoản";
			case CashflowreceiptsMethod.VNPAY:
				return "VNPay";
			case CashflowreceiptsMethod.VOUCHER:
				return "Voucher";

			default:
				return "";
		}
	}

	//contants

	static contantMethodCashFlow(typeSale?: SERVICE_TYPE) {
		if (typeSale) {
			switch (typeSale) {
				case SERVICE_TYPE.PURTCHASE_METHOD_IN_STORE:
					return Object.values(CashFlow.method)
						.filter((i) => i !== CashFlow.method.COD)
						.map((method) => {
							return {
								label: this.utilGetLableMethodCashFlow(method),
								value: method,
							};
						});

				default:
					return Object.values(CashFlow.method).map((method) => {
						return {
							label: this.utilGetLableMethodCashFlow(method),
							value: method,
						};
					});
			}
		}

		return Object.values(CashFlow.method).map((method) => {
			return {
				label: this.utilGetLableMethodCashFlow(method),
				value: method,
			};
		});
	}
	// defaultData
	static defaultDataCashflow(data: Partial<CashflowreceiptJson>) {
		let defaultResult: CashflowreceiptJson = {
			cashflow_group: 101,
			company_id: 0,
			creator_id: 0,
			date_affected: new Date().getTime() / 1000,
			date_created: new Date().getTime() / 1000,
			date_modified: new Date().getTime() / 1000,
			direction: 5,
			id: Date.now(),
			identifier: "",
			method: CashflowreceiptsMethod.CASH,
			name: "",
			note: "",
			source: 1,
			source_id: 0,
			target: 3,
			store_id: 0,
			status: 1,
			target_id: 0,
			target_label: "",
			value: 100000,
		};

		const result = Object.assign(defaultResult, data) as CashflowreceiptJson;

		return result;
	}

	static getCashflowCreatedLocalOfOrder(
		id: number | string,
		isCreated: boolean
	) {
		try {
			let dataCashflowLocal = localStorage.getItem("pos_cashflows");
			if (dataCashflowLocal) {
				const dataInit = JSON.parse(dataCashflowLocal) as DataLocalCashflow;
				return dataInit?.[id]?.filter((i) => i.isCreate === isCreated) ?? [];
			}
			return [];
		} catch (error) {
			return [];
		}
	}
}

export default OrderRepo;
