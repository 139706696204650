
"use client"
import { useQuery } from '@tanstack/react-query'
import { getHomeDeliveries } from '../api/deliverry/delivery'

export default function useGetHomeDeliveries() {
  return useQuery({
    queryKey: ["home-deliveries"],
    queryFn: async () => {
        const {data} = await getHomeDeliveries({
          page : 1,
          limit : 20
        })
        return data.items
    },
    staleTime: Infinity,
  })
}
