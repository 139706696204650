"use client";
import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/redux/hook";
import { getMultiSetting } from "../api/shop/setting";
import Helper from "@/utils/helper";
import {
	getDataFromLocalStorage,
	setDataLanguage,
} from "@/redux/features/ShopSlice";
import { useQuery } from "@tanstack/react-query";
import { DataLanguageCache } from "@/common/types/SiteLanguage.type";
import Local from "@/common/constants/local";

// Đảm bảo rằng `DataLanguageCacheJson` là một đối tượng với các khóa là chuỗi và giá trị là chuỗi
type DataLanguageMap = { [key: string]: string };

const useTranslationApi = (keys: string[]) => {
	const dispatch = useAppDispatch();
	const siteLanguage = useAppSelector((state) => state.ShopReducer.language);
	const dataLanguageCache = useAppSelector(
		(state) => state.ShopReducer.dataLanguage
	);
	const dataLocal = getDataFromLocalStorage<DataLanguageCache>(
		Local.cookies.language
	);

	const dataCache = dataLocal?.data || dataLanguageCache.data;

	const keyNotExited = keys
		.filter((k) =>
			typeof dataCache === "object" ? !dataCache.hasOwnProperty(k.trim()) : true
		)
		.map((k) => k.trim());

	const { data, isLoading, isFetching } = useQuery({
		queryKey: ["keyTranslates", siteLanguage.lang, keyNotExited],
		queryFn: async () => {
			if (keyNotExited.length === 0) return {};
			const keysStr = Helper.removeCharAtString(keyNotExited.join(","));
			const res = await getMultiSetting(
				keysStr,
				{ lang: siteLanguage.lang },
				{ timeout: 10000 }
			);

			const dataResult = res.data.items.reduce((acc: DataLanguageMap, crr) => {
				if (crr.value && crr.group === "LANGUAGE") {
					acc[crr.key] = crr.value;
				}
				return acc;
			}, {} as DataLanguageMap);
			dispatch(
				setDataLanguage({
					data: dataResult,
					space: keyNotExited,
					lang: siteLanguage.lang,
				})
			);
			return dataResult;
		},
		staleTime: Infinity,
		enabled: Object.keys(keyNotExited).length > 0,
	});

	const viewTranslate = useCallback(
		(key: string) => {
			"use client";
			const trimmedKey = key.trim();

			if (isLoading || isFetching) {
				return "...";
			}
			return dataCache && trimmedKey in dataCache
				? dataCache[trimmedKey]
				: trimmedKey;
		},
		[JSON.stringify(dataCache), isLoading, isFetching]
	);

	return { t: viewTranslate };
};

export default useTranslationApi;
