"use client";

import useTranslationApi from "@/lib/hooks/useTranslationApi";

import { CartJson, IsUse } from "@/types/Cart.type";

import Image from "@/components/ui/Image";
import Money from "@/components/ui/Money";
import CartIcon from "@/components/icons/CartIcon";
import LinkElement from "@/components/LinkElement";
import DataEmpty from "~components/empty/DataEmpty";
import ImageConstant from "@/common/constants/image";
import HomeTranslate from "@/common/translate/home";

type Props = {
	cartDetail: CartJson | null;
};

const HeaderCart = ({ cartDetail }: Props) => {
	const { t } = useTranslationApi(Object.values(HomeTranslate.key));

	//state
	const productsIsUse =
		cartDetail?.details.data.filter((item) => item.is_use === IsUse.USE) || [];

	const totalDiscountProduct = productsIsUse.reduce((pre, item) => {
		if (item && item.price_discount) {
			return pre + item.price_discount;
		}
		return pre;
	}, 0);

	const quantity = cartDetail?.quantity || 0;

	return (
		<div className="h-[24px] flex-1 relative text-center group hover:after:absolute hover:after:bottom-[-25px] hover:after:right-0 hover:after:w-[90px] hover:after:h-[30px] hover:hover:after:content-[''] hover:after:bg-[transparent] z-[5]">
			<div className="relative cursor-pointer transition-all h-full">
				<LinkElement
					aria-label="go to cart"
					href={"/cart"}
					className="relative inline-flex items-center mr-2">
					<CartIcon className="min-w-[24px]" />
					{quantity > 0 && (
						<p className="absolute flex justify-center items-center w-[15px] h-[15px] leading-[15px] text-[8px] font-bold text-white bg-red-500 rounded-full bottom-0 right-[-5px]">
							{quantity}
						</p>
					)}
				</LinkElement>
				<div className="absolute mt-4 hidden group-hover:block left-auto top-full right-0 z-10 bg-white border border-gray-300 drop-shadow-sm py-4 w-[400px] max-h-[578px]">
					{/* Cart List */}
					{!cartDetail || productsIsUse.length === 0 ? (
						<DataEmpty className="flex flex-col items-center justify-center gap-2 py-6" />
					) : (
						<>
							<p className="text-gray-400 text-lg mb-[10px] px-4 text-left">
								{/* Giỏ hàng */}
								{t("cart")}
							</p>
							<ul className="scrollbarCustomer pl-4 pr-3 max-h-[350px] overflow-y-scroll flex flex-col gap-2">
								{productsIsUse.map((item, index) => (
									<li
										key={index}
										className="flex items-center text-start min-h-[114px]">
										<LinkElement href={`/products/${item.product_json.handle}`}>
											<Image
												width={114}
												height={114}
												src={item.product_json?.images[0]?.url || ""}
												alt="img"
												className="w-[114px] h-[114px] object-contain"
												placeholder={"blur"}
												blurDataURL={ImageConstant.BlurDataURL}
											/>
										</LinkElement>
										<div className="flex-1 flex flex-col gap-1 min-w-[250px] bg-gray-200 text-gray-500 px-4 py-2 leading-[1.6]">
											<LinkElement
												href={`/products/${item.product_json.handle}`}
												className="text-gray-500 text-sm line-clamp-1 font-bold m-0 p-0 capitalize hover:text-red-100 transition-all">
												{item.product_json.name}
											</LinkElement>
											<p className="text-gray-500 text-xs line-clamp-1">
												{/* Thương hiệu:*/}
												{t("brand")}:{" "}
												<span className="font-bold">
													{item.product_json.brand &&
														item.product_json.brand.title}
												</span>
											</p>
											<p className="text-gray-500 text-xs line-clamp-1">
												{/* Mã hàng: */}
												{t("plu")} {item.product_json.sku}
											</p>
											<p className="text-gray-500 text-xs line-clamp-1">
												{item.product_json.option_name
													? item.product_json.option_name
													: item.product_json.full_name}
											</p>
											<p className="flex justify-between w-full text-sm">
												<span>
													{/* Số lượng: */}
													{t("quantity")}:{" "}
													<span className="font-bold">
														{item.item_quantity}
													</span>
												</span>
												<span className="font-bold">
													<Money value={item.price_final} />
												</span>
											</p>
											{/* <PriceList data={item} /> */}
										</div>
									</li>
								))}
							</ul>
							{/* Total bill */}
							{/* {
								cartDetail.price_discount_coupon > 0 ? 		<div className="p-4 py-1 flex items-center justify-between">
								<p className="text-[13px] font-bold text-gray-500">
										Giảm giá hóa đơn
								
								</p>
								<p className="text-[13px] font-bold text-gray-500">
									<Money value={cartDetail.price_discount_coupon} />
								</p>
							</div> : null
							}  */}

							<div className="p-3 flex items-center justify-between">
								<p className="text-[13px] font-bold text-gray-500">
									{/* Tổng */}
									{t("total")}{" "}
									<span className="text-gray-400 font-normal">
										{/* ({quantity} sản phẩm) */}({quantity} {t("product")})
									</span>
								</p>
								<p className="text-[13px] font-bold text-gray-500">
									<Money value={cartDetail.price_sell} />
								</p>
							</div>
							{/* Check out */}
							<LinkElement
								href={"/cart"}
								className="block text-center h-[34px] leading-[34px] rounded text-sm text-white focus:outline-none bg-red-500 hover:opacity-80 transition-all mx-4">
								{/* Xem giỏ hàng & thanh toán */}
								{t("view_cart_and_checkout")}
							</LinkElement>
							{/* Benefits */}
							<div className="flex flex-col justify-center items-center mt-4 px-4 leading-[1.4]">
								<p className="text-sm font-bold  text-gray-500 uppercase">
									{/* Ship nhanh 3h */}
									{t("fast_shipping_2_hours")}
								</p>
								<p className="text-sm text-gray-500 uppercase pt-1 pb-2">
									<span className="font-bold">
										{/* FREE SHIP */}
										{t("free_ship")}
									</span>
									{/* MỌI ĐƠN HÀNG */} {t("every_order")}
								</p>
								<p className="text-sm text-gray-500 ">
									{/* Đơn hàng của bạn sẽ được ship nhanh nội thành HCM */}
									{t("fast_shipping_hcm")}
								</p>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default HeaderCart;
