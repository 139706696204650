import { callApiV2 } from "@/lib/config/Api";
import {
	FilterStore,
	StoreCollectionJson,
	StoreJson,
} from "@/types/Store.type";
import Helper from "@/utils/helper";

const URL = "/stores";
export async function getListStore(
	filters: FilterStore = { sort_type: "ASC" }
) {
	const apiParams = Helper.convertParams(filters || {});

	const res = await callApiV2<StoreCollectionJson>(`${URL}/public`, "get", {
		params: {
			...apiParams,
		},
	});

	return res;
}

export async function getStore(id: StoreJson["code"]) {
	const res = await callApiV2<StoreJson>(`${URL}/public/${id}`, "get", {
		params: {
			show_web: 1,
		},
		isCache: true,
		revalidate: 5 * 60,
	});

	return res;
}
