import {
	CashflowreceiptJson,
	CashflowreceiptsDirection,
	CashflowreceiptsMethod,
	CashflowreceiptsStatus,
} from "@/types/Cashflowreceipts.type";
import { AddCouponProps, CouponJson } from "@/types/Coupon.type";
import {
	PromotionCalculateOnSalePrice,
	PromotionDiscountType,
	PromotionGroupType,
	PromotionJson,
} from "@/types/Promotion.type";
import { PricePos, useOrderProps } from "@/types/UseOrder.type";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createDeliveries, getDeliveries } from "../api/shop/delivery";
import {
	DeliveryInternalAddEditJson,
	DeliveryInternalAddOrderDetail,
} from "@/types/Delivery.type";
import {
	createCashflowreceipts,
	createCashflowreceiptsPublic,
	updateCashflowreceipts,
} from "../api/shop/cashflowreceipts";
import { DeliveryJson } from "@/types/Delivery.type";
import { useOrderError, SumaryOrder } from "@/types/UseOrder.type";
import { getDataError } from "../config/apiHandler";
import {
	createOrderProps,
	DiscountType,
	ORDER_STATUS,
	OrderJson,
	SERVICE_TYPE,
	updateCartItemQuantityProps,
} from "@/types/Orders.type";
import {
	CartAddEditJson,
	CartOrderJson,
	CartPromotion,
	IsUse,
	ProductCartAddEditJson,
} from "@/types/Cart.type";
import useBrowerId from "./useBrowerId";
import OrderRepo from "../repo/order";
import {
	createCartPublic,
	createCartUser,
	createOrderPublic,
	createOrderUser,
	deletePublicCart,
	deleteUserCart,
	postActionCartPublic,
	postActionCartUser,
} from "../api/shop/cart";
import {
	addCouponPublic,
	addCouponUser,
	checkCoupon,
} from "../api/shop/coupon";
import { ProductJson } from "@/types/Product.type";
import {
	PaymentDataBilling,
	PaymentDataShipping,
	PaymentDataSubmit,
} from "@/types/Payment.type";
import { checkoutAction } from "@/actions/payment";
import CheckoutRepo from "../repo/checkout";
import { mergeObjects } from "@/utils/utils";
import Helper from "@/utils/helper";
import { useSession } from "next-auth/react";
import useStoreId from "./useStoreId";
import { createTransactionVNpay, quickRegister } from "../api/shop/payment";
import BaseApi from "@/common/api/BaseApi";
import useCashFlows from "./useCashFlows";
import useDeliveries from "./useDeliveries";
import { getSettingV2 } from "../api/shop/setting";
import SettingApi from "@/common/constants/setting";
import usePromotions from "./usePromotions";
import { getPromotionValid } from "@/utils/product";
import { useAppSelector } from "@/redux/hook";
import _ from "lodash";
import useCashFlowsLocal, { CashflowLocal } from "./useCashFlowsLocal";
import { CustomerJson } from "@/types/Customer.type";
import {
	CartDeliveryFormType,
	PriceShippingType,
} from "@/app/pos/_components/posCart/CartDeliveryForm";
import { getListLocation } from "../api/shop/locations";
import { editOrderUser } from "../api/shop/orders";
import {
	ORDER_CANCEL_REASON,
	ORDER_DISCOUNT_TYPE,
} from "@/common/constants/order";
import { LoginJson } from "@/types/Auth.type";
import checkoutConstant from "@/common/constants/checkout";
export type useOrderReturn = ReturnType<typeof useOrder>;

const useOrder = ({
	order: orderDefault,
	isUser: isUserDefault,
	loginData,
	defaultParams,
	storeId: storeIdDefault,
	skipInit = true,
	onUpdateOrder,
	typeSale,
}: useOrderProps) => {
	const defaultError: useOrderError = {
		action: [],
		cashFlows: [],
		checkout: [],
		deliveries: [],
		init: [],
		order: [],
	};
	///////////////////////////////////
	const allPromotionSessonalBodyValid = getPromotionValid(
		useAppSelector((state) => state.PromotionReducer.promotionSeasonalOrder)
	);

	///////////////////////////////////
	const [order, setOrder] = useState<OrderJson>(orderDefault);
	// console.log("🚀 ~ order:", order);
	// const [cashFlows, setCashFlows] = useState<CashflowLocal[]>([]);
	const [promotions, setPromotions] = useState<CartPromotion[]>(
		orderDefault?.promotions || []
	);
	const { data: promotionList } = usePromotions();
	const [coupons, setCoupons] = useState<CouponJson[]>([]);
	// const [deliveries, setDeliveries] = useState<DeliveryJson[]>([]);

	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<useOrderError>(defaultError);

	const [extraCharges, setExtraCharges] = useState({
		priceShipping: order.price_shipping,
	});

	const [dataCustomer, setDataCustomer] = useState<CustomerJson | undefined>(
		undefined
	);

	const [dataDelivery, setDataDelivery] = useState<
		CartDeliveryFormType | undefined
	>(undefined);

	const [dataShipping, setDataShipping] = useState<
		(PaymentDataShipping & PaymentDataBilling) | undefined
	>(undefined);

	const [sumaryOrderData, setSumaryOrderData] = useState<SumaryOrder>({
		priceSell: 0, // Tổng tiền đơn hàng
		priceDiscountTotalProducts: 0,
		priceDiscountTotalItemsAndBody: 0,
		priceDiscountVoucher: 0,
		priceDiscountTotalOrder: 0,
		priceSaveTotal: 0, // Tổng tiết kiệm
		priceFinal: 0, // Tổng tiền thanh toán
		order_custom_discount: 0, // Tiền giảm custom POS
		priceDiscountPromotionTotal: 0, // Tổng tiền giảm khuyễn mãi (item + body)
	});

	const [pricePosData, setPricePosData] = useState<PricePos>({
		totalOrder: 0,
		totalCashFlows: 0,
		totalAmountPaid: 0, // tiền khách đã trả
		totalAmountPayable: 0, //  tiền khách phải trả thêm
		priceVat: 0,
		totalReturn: 0, // tiền thối lại cho khách
	});

	///////////////////////////////////
	const { data: browserId } = useBrowerId();

	const { data: cus, status } = useSession();

	const userLogin = loginData ?? (cus?.user as LoginJson | undefined);

	let isUser = !!isUserDefault || !!userLogin;
	if (defaultParams?.type_sale === "employee") {
		isUser = true;
	}

	const { data: store_id, isLoading: isLoadingStoreId } =
		useStoreId(storeIdDefault);

	// const {
	// 	// cashFlows: cashApi,
	// 	setCashFlows,
	// 	loadingCashFlows,
	// 	isFetched: isFetchedCashflows,
	// } = useCashFlows({
	// 	order: order,
	// 	skip: skipInit,
	// 	autoCreate: true,
	// 	userId: user?.employee?.id,
	// });

	const hookCashflows = useCashFlowsLocal({
		order,
		userId: userLogin?.company.employee?.id,
		typeSale: typeSale,
	});

	const { cashFlows, deleteOrderCashflowData } = hookCashflows;

	const { deliveries, setDeliveries, loadingDeliveries } = useDeliveries({
		orderId: order.code,
		skip: true,
	});

	const browser_id = browserId;
	const customer_token = order.customer_token;
	// Helper.removeDuplicatesArrObject(
	const defaultCouponItems = order.details.data
		.filter((item) => item.is_use === IsUse.USE)
		.flatMap((data) =>
			data.promotions.map((pro) => ({ ...pro, product: data.product_json }))
		)
		.filter((pro) => pro.promotion_detail.group === PromotionGroupType.coupon);

	///////////////////////////////////
	// common + private
	async function handleAction(body: CartAddEditJson, notSetFinish?: boolean) {
		try {
			const idCustomerToUpdate: number = body.customer_id
				? body.customer_id
				: order.customer_id;
			const dataBody: CartAddEditJson = {
				customer_id: idCustomerToUpdate || undefined,
				...body,
			};

			const { data } = isUser
				? await postActionCartUser(dataBody)
				: await postActionCartPublic(dataBody);

			const res = await handleAutoUpdatePromotionBody(dataBody, data);
			const orderUpated = res ? res : data;

			console.log("123", orderUpated);

			!notSetFinish &&
				onFinish({
					...orderUpated,
					customer_id: body.customer_id ? body.customer_id : order.customer_id,
				});

			return orderUpated;
		} catch (error) {
			throw getDataError(error);
		} finally {
			setLoading(false);
		}
	}

	async function handleAutoUpdatePromotionBody(
		data: CartAddEditJson,
		o: OrderJson
	) {
		try {
			if (data.action !== "promotion") {
				const promotionBodySessonalNotUse = o.promotions.filter((p) => {
					const reqSubtotal = p.promotion_detail.req_subtotal;

					const reqOnSalePrice = p.promotion_detail.calculate_on_sale_price;

					const reqPriceOrder =
						reqOnSalePrice === PromotionCalculateOnSalePrice.FINAL
							? o.price_final
							: o.item_total;

					const condition = reqSubtotal <= reqPriceOrder;

					return (
						p.promotion_detail.discount_type === PromotionDiscountType.CART &&
						p.promotion_detail.group === PromotionGroupType.seasonal &&
						p.is_use === IsUse.NOT_USE &&
						condition
					);
				});

				const promotionBodySessonalCanUse =
					allPromotionSessonalBodyValid.filter((p) => {
						const reqSubtotal = p.req_subtotal;

						const reqOnSalePrice = p.calculate_on_sale_price;

						const reqPriceOrder =
							reqOnSalePrice === PromotionCalculateOnSalePrice.FINAL
								? o.price_final
								: o.item_total;

						const condition = reqSubtotal <= reqPriceOrder;

						return (
							p.discount_type === PromotionDiscountType.CART &&
							p.group === PromotionGroupType.seasonal &&
							p.is_use === IsUse.NOT_USE &&
							condition
						);
					});

				if (promotionBodySessonalNotUse.length > 0) {
					// const promise =
					const dataUpate: CartAddEditJson = {
						action: "promotion",
						cart_id: order.id,
						customer_token: customer_token,
						customer_id: order.customer_id,
						promotions: promotionBodySessonalNotUse.map((p) => ({
							...p,
							is_use: IsUse.USE,
						})),
						...defaultParams,
					};
					const { data } = isUser
						? await postActionCartUser(dataUpate)
						: await postActionCartPublic(dataUpate);
					return data;
				}
			}
		} catch (error) {}
	}
	const updateProductOder = useCallback(
		async (datas: ProductCartAddEditJson[]) => {
			const orderId = order.id;
			const submitData: CartAddEditJson = {
				...defaultParams,
				cart_id: orderId,
				action: "update",
				details: datas,
				customer_token: customer_token,
			};
			const responsePostCart = await handleAction(submitData);
			return responsePostCart;
		},
		[order, handleAction, defaultParams]
	);
	const onFinish = (o: OrderJson) => {
		setOrder(o);
		onUpdateOrder(o);
	};

	///////////////////////////////////
	// action
	async function updateCartItemQuantity({
		item_id,
		quantity,
	}: updateCartItemQuantityProps) {
		setLoading(true);
		const item = order.details.data.find((item) => item.id === item_id);
		if (!item) return;
		const detail = OrderRepo.convertDetailsForUpdateQuantityItem(
			item,
			quantity
		);
		const postData: CartAddEditJson = {
			cart_id: order.id,
			action: "update",
			details: detail ? [detail] : [],
			customer_token: customer_token,
			...defaultParams,
		};
		return handleAction(postData);
	}

	async function addPromotionBody(
		promotions: PromotionJson[],
		cusToken?: string,
		cart_id?: string
	) {
		const initPromotion: CartPromotion[] = [
			...order.promotions,
			...OrderRepo.convertPromotionToOrderPromotion(
				promotions,
				undefined,
				IsUse.USE
			),
		];
		try {
			// Xử lý nếu có bất kỳ promotion nào trong order ko cho áp dụng với các promotion khác
			const allPromotionInOrder = [
				...order.promotions,
				...order.details.data.flatMap((i) => i.promotions),
			].filter((p) => p.is_use === IsUse.USE);
			if (false) {
				// throw Error("not_apply_with_other");
			} else {
				const submitData: CartAddEditJson = {
					action: "promotion",
					cart_id: cart_id || order.id,
					customer_token: cusToken || customer_token,
					promotions: initPromotion.reduce(
						(curr: CartPromotion[], prev: CartPromotion) => {
							const isExited = promotions.some(
								(pp) => pp.id === prev.promotion_id
							);
							if (!curr.find((i) => i.promotion_id === prev.promotion_id)) {
								curr.push({
									...prev,
									is_use: isExited ? IsUse.USE : prev.is_use,
								});
							}
							return curr;
						},
						[]
					),
					...defaultParams,
				};
				return handleAction(submitData);
			}
		} catch (error) {
			throw error;
		}
	}

	async function removePromotionBody(promotions: PromotionJson[]) {
		const submitData: CartAddEditJson = {
			action: "promotion",
			cart_id: order.id,
			// details: order.details.data,
			customer_token,
			promotions: order.promotions.map((p) => {
				const isExited = promotions.some((pp) => pp.id === p.promotion_id);
				if (isExited) {
					return {
						...p,
						is_use: IsUse.NOT_USE,
					};
				}

				return p;
			}),
			...defaultParams,
		};

		return handleAction(submitData);
	}

	async function addCouponOrder2(code: string) {
		try {
			const data: AddCouponProps = {
				code: code,
				order_id: order.id,
				customer_token: order.customer_token,
			};

			if (isUser) {
				delete data.customer_token;
			}
			const { data: res } = isUser
				? await addCouponUser(data)
				: await addCouponPublic(data);
			console.log(res);
			onFinish(res);
			return res;
		} catch (error) {
			throw error;
		}
	}

	const removeCouponOrder = useCallback(
		async (coupon: CartPromotion) => {
			const typeCoupn = coupon.promotion_detail.discount_type;
			const codeRemove = coupon.code;

			if (typeCoupn === PromotionDiscountType.PRODUCT) {
				const listCartItemContainsCoupon = order.details.data.filter((item) =>
					item.promotions.some((p) => p?.code === coupon?.code)
				);
				const listCartItemUpdate: ProductCartAddEditJson[] =
					listCartItemContainsCoupon.map((item) => {
						return {
							...item,
							promotions: item.promotions.filter(
								(pro) => pro.code !== codeRemove
							),
						};
					});

				const res = await updateProductOder(listCartItemUpdate);
				onFinish(res);
				return res;
			}

			if (typeCoupn === PromotionDiscountType.CART) {
				const coupons = order.promotions.filter(
					(pro) => pro.code !== codeRemove
				);
				const data: CartAddEditJson = {
					action: "promotion",
					cart_id: order.id,
					promotions: coupons,
					customer_token,
					...defaultParams,
				};
				if (isUser) {
					delete data.customer_token;
				}

				const res = await handleAction(data);
				onFinish(res);
				return res;
			}
		},
		[JSON.stringify(order), onFinish]
	);

	//////////// action on productOrder

	const buyNow = useCallback(
		async (product: ProductJson, quantity: number) => {
			const listItem = order.details.data;
			const isExitedInCart = listItem.some((i) => i.product_id === product.id);
			const jsonForAddInCart = OrderRepo.convertProductToProductOrder(
				product.id,
				quantity,
				OrderRepo.convertPromotionToOrderPromotion(
					product.promotions,
					quantity,
					IsUse.USE
				)
			);
			if (listItem.length > 0) {
				const listItemUpdate: CartOrderJson[] = listItem.map((i) => {
					if (i.product_id === product.id) {
						return {
							...i,

							is_use: IsUse.USE,
							item_quantity: quantity,
						};
					}
					return {
						...i,
						is_use: IsUse.NOT_USE,

						item_quantity: quantity,
					};
				});

				try {
					const resUpdateIsUse = await updateManyIsUseProductOrder(
						listItemUpdate
					);
					if (!isExitedInCart) {
						return await addManyProductOder([jsonForAddInCart]);
					}
					return resUpdateIsUse;
				} catch (error) {
					throw getDataError(error);
				}
			} else {
				return await addManyProductOder([jsonForAddInCart]);
			}
		},
		[order]
	);

	const addManyProductOder = useCallback(
		async (datas: ProductCartAddEditJson[], id?: string) => {
			try {
				//case not default order or cart
				let orderId = id || order.id;
				let customerTokenData = order.customer_token;
				if (orderId.toString().length <= 0) {
					try {
						const res = await initPreOrder();
						orderId = res.id;
						customerTokenData = res.customer_token;
					} catch (error) {}
				}

				const submitData: CartAddEditJson = {
					cart_id: orderId,
					action: "add",
					details: datas,
					customer_token: customerTokenData,
					...defaultParams,
				};
				let responsePostCart = await handleAction(submitData);
				const prePromotionItems = responsePostCart.details.data.flatMap(
					(item) => item.promotions
				);
				for await (const data of datas) {
					if (data.promotions && data.promotions?.length) {
						for await (const promotion of data.promotions) {
							const findP = prePromotionItems.find(
								(pro) => pro.promotion_id === promotion.promotion_id && pro.code
							);
							if (findP && findP.code) {
								responsePostCart = await addCouponOrder2(findP.code);
							}
						}
					}
				}
				onFinish(responsePostCart);
				return responsePostCart;
			} catch (error) {
				const err = getDataError(error);
				throw err;
			}
		},
		[order.id, handleAction]
	);

	const updateVariantProductOrder = useCallback(
		async (productOrder: CartOrderJson, productVariantNew: ProductJson) => {
			try {
				const item: ProductCartAddEditJson = {
					id: productOrder.id,
					product_id: productVariantNew.id,
					item_quantity: productOrder.item_quantity,
					promotions: OrderRepo.convertPromotionToOrderPromotion(
						productVariantNew.promotions,
						productOrder.item_quantity,
						IsUse.USE,
						productVariantNew.id
					),
					is_use: productOrder.is_use,
				};
				const data = await updateProductOder([item]);
				return data;
			} catch (error) {
				const err = getDataError(error);
				throw err;
			}
		},
		[updateProductOder]
	);

	//update promotion
	const updatePromotionProductOrder = useCallback(
		async (productOrder: CartOrderJson, promotionsNew: CartPromotion[]) => {
			try {
				const item: ProductCartAddEditJson = {
					id: productOrder.id,
					item_quantity: productOrder.item_quantity,
					product_id: productOrder.product_id,
					promotions: promotionsNew,
					is_use: IsUse.USE,
				};
				const data = await updateProductOder([item]);
				return data;
			} catch (error) {
				const err = getDataError(error);
				throw err;
			}
		},
		[updateProductOder]
	);

	const addManyPromotionForOrderItem = useCallback(
		async (productOrder: CartOrderJson, promotionsNew: PromotionJson[]) => {
			try {
				const initPromotion: CartPromotion[] = [
					...productOrder.promotions,
					...OrderRepo.convertPromotionToOrderPromotion(
						promotionsNew,
						undefined,
						productOrder.is_use
					),
				];
				const dataUpdate: ProductCartAddEditJson = {
					id: productOrder.id,
					is_use: productOrder.is_use,
					product_id: productOrder.product_id,
					item_quantity: productOrder.item_quantity,
					promotions: initPromotion.reduce(
						(curr: CartPromotion[], prev: CartPromotion) => {
							const isExited = promotionsNew.some(
								(pp) => pp.id === prev.promotion_id
							);
							const cartPromotionExited = curr.find(
								(i) => i.promotion_id === prev.promotion_id
							);
							if (!cartPromotionExited) {
								curr.push({
									...prev,
									item_quantity: productOrder.item_quantity,
									is_use: isExited ? IsUse.USE : prev.is_use,
								});
							} else if (cartPromotionExited.is_use === IsUse.NOT_USE) {
								curr.push({
									...prev,
									is_use: IsUse.USE,
								});
							}

							return curr;
						},
						[]
					),
				};

				const data = await updateProductOder([dataUpdate]);
				onFinish(data);

				return data;
			} catch (error) {
				console.log("🚀 ~ error:", error);
				const err = getDataError(error);
				throw err;
			}
		},
		[updateProductOder]
	);

	const removeManyPromotionForOrderItem = useCallback(
		async (productOrder: CartOrderJson, promotionsNew: PromotionJson[]) => {
			try {
				const initPromotion: CartPromotion[] = [
					...productOrder.promotions,
					...OrderRepo.convertPromotionToOrderPromotion(
						promotionsNew,
						undefined,
						productOrder.is_use
					),
				];
				const dataUpdate: ProductCartAddEditJson = {
					id: productOrder.id,
					is_use: productOrder.is_use,
					product_id: productOrder.product_id,
					item_quantity: productOrder.item_quantity,
					promotions: initPromotion.reduce(
						(curr: CartPromotion[], prev: CartPromotion) => {
							console.log("🚀 ~ curr:", curr);
							const isExited = promotionsNew.some(
								(pp) => pp.id === prev.promotion_id
							);
							if (!curr.find((i) => i.promotion_id === prev.promotion_id)) {
								curr.push({
									...prev,
									item_quantity: productOrder.item_quantity,
									is_use: isExited ? IsUse.NOT_USE : prev.is_use,
								});
							}

							return curr;
						},
						[]
					),
				};

				const data = await updateProductOder([dataUpdate]);
				onFinish(data);

				return data;
			} catch (error) {
				const err = getDataError(error);
				throw err;
			}
		},
		[updateProductOder]
	);

	///////////////////////////
	const updateManyIsUseProductOrder = useCallback(
		async (productsOrderUpdate: CartOrderJson[], isUse?: IsUse) => {
			const details: ProductCartAddEditJson[] = productsOrderUpdate.map(
				(productOrder) => ({
					id: productOrder.id,
					is_use: isUse ?? productOrder.is_use,
					product_id: productOrder.product_id,
					item_quantity: productOrder.item_quantity,
					promotions: productOrder.promotions,
				})
			);
			try {
				const data = await updateProductOder(details);
				return data;
			} catch (error) {
				const err = getDataError(error);
				throw err;
			}
		},
		[updateProductOder]
	);

	const deleteManyProductOrder = useCallback(
		async (productsOrderDelete: CartOrderJson[]) => {
			try {
				const submitData: CartAddEditJson = {
					action: "delete",
					cart_id: order.id,
					details: productsOrderDelete.map((item) => ({ id: item.id })),
					customer_token: customer_token,
					...defaultParams,
				};
				const data = await handleAction(submitData);
				return data;
			} catch (error) {
				const err = getDataError(error);
				throw err;
			}
		},
		[order.id, handleAction]
	);

	const updateCustomer = useCallback(
		async (customer_id: number) => {
			setLoading(true);
			const orderId = order.id;
			const submitData: CartAddEditJson = {
				cart_id: orderId,
				action: "update_customer",
				customer_token: customer_token,
				customer_id: customer_id,
				...defaultParams,
			};
			const responsePostCart = await handleAction(submitData);
			setLoading(false);

			return responsePostCart;
		},
		[order, handleAction]
	);

	const updateSeller = useCallback(
		async (seller_id: number) => {
			// setLoading(true);
			const orderId = order.id;
			const submitData: CartAddEditJson = {
				cart_id: orderId,
				action: "update_seller",
				customer_token: customer_token,
				seller_id: seller_id,

				...defaultParams,
			};
			const responsePostCart = await handleAction(submitData);
			// setLoading(false);

			return responsePostCart;
		},
		[order, handleAction]
	);

	const updatePriceTax = useCallback(
		async (value: number) => {
			setLoading(true);
			try {
				const data: CartAddEditJson = {
					action: "update_price",
					cart_id: order.id,
					price_tax: value,
					type_sale: "employee",
				};
				const res = await handleAction(data);
				onFinish(res);
				return res;
			} catch (error) {
				throw error;
			} finally {
				setLoading(false);
			}
		},
		[order]
	);

	const updatePriceShipping = async (value: number) => {
		try {
			const data: CartAddEditJson = {
				action: "update_price",
				cart_id: order.id,
				price_shipping: value,
				// price_tax: order.price_tax,
				type_sale: "employee",
			};
			const res = await handleAction(data);
			// onFinish(res);
			return res;
		} catch (error) {
			throw getDataError(error);
		} finally {
		}
	};

	const updateVatInNote = useCallback(
		async (dataVat: PaymentDataSubmit["note_invoice"]) => {
			setLoading(true);
			try {
				const data: CartAddEditJson = {
					action: "note",
					cart_id: order.id,
					type_sale: "employee",
					note_invoice: dataVat,
					// details: OrderRepo.convertDetailCart(order.details.data),
				};
				const res = await handleAction(data);
				onFinish(res);
				return res;
			} catch (error) {
				throw error;
			} finally {
				setLoading(false);
			}
		},
		[order]
	);

	const updatePriceDiscount = useCallback(
		async (value: number, type: ORDER_DISCOUNT_TYPE) => {
			setLoading(true);

			try {
				const data: CartAddEditJson = {
					action: "update_discount",
					cart_id: order.id,
					discount_custom_type: type,
					discount_custom_value: value,
					type_sale: "employee",
				};
				const res = await handleAction(data);
				onFinish(res);
				return res;
			} catch (error) {
				throw error;
			} finally {
				setLoading(false);
			}
		},
		[order.id]
	);

	const updatePurchaseInfor = async (value: SERVICE_TYPE) => {
		try {
			const data: CartAddEditJson = {
				action: "update_purchase_info",
				cart_id: order.id,
				service_type: value,
				type_sale: "employee",
			};
			const res = await handleAction(data, true);
			// onFinish(res);
			return res;
		} catch (error) {
			throw getDataError(error);
		} finally {
			// setLoading(false);
		}
	};

	const removeCustomerOrder = useCallback(async () => {
		setLoading(true);
		const orderId = order.id;
		const submitData: CartAddEditJson = {
			cart_id: orderId,
			action: "update_customer",
			customer_token: customer_token,
			customer_id: 0,
			...defaultParams,
		};
		const responsePostCart = await handleAction(submitData);
		setLoading(false);

		return responsePostCart;
	}, [order, handleAction]);

	///////////////////////////////////
	// checkout
	const checkout = async (
		data: Omit<PaymentDataSubmit, "order_id">,
		type: "pos" | "shop"
	) => {
		setLoading(true);
		try {
			const resCheckout =
				type === "shop" ? await checkoutShop(data) : await checkoutPos(data);
			return resCheckout;
		} catch (error) {
			setLoading(false);

			throw error;
		} finally {
		}
	};

	async function handleDeleteCart(cart_id: string, customer_token?: string) {
		const res = isUser
			? deleteUserCart({ cart_id })
			: deletePublicCart({ cart_id, customer_token });
		return res;
	}
	////////////// checkout with type
	const checkoutShop = useCallback(
		async (data: Omit<PaymentDataSubmit, "order_id">) => {
			try {
				if (!isUser) {
					const dataCusNew =
						OrderRepo.convertPaymentDataToQuickRegisterCustomer(data);
					const resQuickRegister = await quickRegister(dataCusNew);
					data.customer_id = resQuickRegister.data.customer_id;
				}

				// create order
				const resCreateOrder = await createOrder({
					cart_id: order.id,
					store_id: store_id || 0,
					customer_token: customer_token,
					customer_id: data.customer_id,
					...OrderRepo.convertDataShippingForCreateOrder(data),
				});

				// create cashFlows
				const methodPayment = data.paymentMethod;

				if (!methodPayment) {
					throw Error("payment_method_empty");
				}
				const cashflow = CheckoutRepo.convertCashFlowsFromPaymentMethod([
					{ amount: order.debt, method: methodPayment },
				]);

				const cashflowRes = await createCashflowreceiptsPublic({
					status: CashflowreceiptsStatus.DRAFT,
					// name: order.code,
					// identifier: order.code,
					target: 3,
					source: 1,
					source_id: resCreateOrder.order_id,
					target_id: data.customer_id,
					store_id: store_id,
					...cashflow[0],
				});
				const { data: saleChannel } = await getSettingV2(
					SettingApi.KEY.id_ecomplatforms_for_web
				);

				// checkout order
				delete data.customer_id;
				const { data: resCheckout } = await checkoutAction(
					{
						...data,
						order_id: resCreateOrder.id,
						customer_token: customer_token,
						sale_channel: saleChannel.value,
					},
					isUser
				);

				let urlPayment = "";
				if (data.paymentMethod === "vnpay" && cashflowRes) {
					const { data: res } = await createTransactionVNpay({
						order_id: resCheckout.id,
						return_url: `${location.origin}/checkouts/vnpay`,
						cashflow_receipt_id: cashflowRes.id || 0,
					});
					urlPayment = res.payment_url;
				}
				await handleDeleteCart(order.id);
				return { ...resCheckout, url_payment: urlPayment || undefined };
			} catch (error) {
				throw getDataError(error);
			}
		},
		[order]
	);

	const checkoutPos = useCallback(
		async (data: Omit<PaymentDataSubmit, "order_id">) => {
			console.log("🚀 ~ dataDelivery:", dataDelivery);

			try {
				if (cashFlows.length <= 0) {
					throw BaseApi.handleErrorCore({
						errors: ["no_data_cashflows"],
						status: 406,
					});
				}

				/////////////////////////
				const { data: dataPos } = await getSettingV2(
					SettingApi.KEY.setting_pos_checkouts
				);

				/////////////////////////
				// delivery
				const dataDeliveryPos = dataPos.value as {
					sale_channel: number;
					partner_delivery: number;
				};
				let deliveries_id_list: number | string = order.delivery_lits_id;
				let deliveryAdressFull = data?.shipping_full_address ?? "";
				// Xử lý địa chỉ giao hàng cho delivery từ json order shipping

				if (
					!(
						data?.shipping_full_address && data.shipping_full_address.length > 0
					)
				) {
					let listIdLocations = [
						data?.shipping_ward || 0,
						data?.shipping_district || 0,
						data?.shipping_province || 0,
						data?.shipping_country || 0,
					];

					const { data: dataLocations } = await getListLocation({
						list_ids: listIdLocations.filter((i) => i > 0).join(","),
					});

					const addressToCreateDelivery =
						order.shipping_address +
						dataLocations.items
							.reduce((curr: string[], prev, index) => {
								const indexPush = listIdLocations.findIndex(
									(i) => i === prev.location_id
								);
								curr[indexPush] = prev.name;
								return curr;
							}, [])
							.join(",");

					deliveryAdressFull = addressToCreateDelivery;
				}

				try {
					const deliveryPromise = await createDelivery({
						type: "manual",
						action_code:
							data.service_type === SERVICE_TYPE.PURTCHASE_METHOD_IN_STORE
								? "ACTION_COMPLETE"
								: undefined,
						cod:
							data.service_type === SERVICE_TYPE.PURTCHASE_METHOD_IN_STORE
								? 0
								: data.codPriceToPay ?? 0,
						contact_address: deliveryAdressFull,
						contact_fullname: data.shipping_fullname || "",
						contact_phone: data.shipping_phone || "",
						cost_total: order.total_payment,
						cotips: 0,
						note: data.delivery_note || "",
						partner_delivery:
							data.partner_delivery || dataDeliveryPos.partner_delivery,
						resource_id: order.order_id.toString(),
						resource_type: 2,
						resource_number: order.code,
						shipping_cost_by_customer:
							dataDelivery?.shipping_cost_by_customer ?? 0,
						shipping_cost_covered_by_shop:
							dataDelivery?.shipping_cost_covered_by_shop ?? 0,

						payment_method:
							data.service_type === SERVICE_TYPE.PURTCHASE_METHOD_DELIVERY &&
							data?.codPriceToPay
								? CashflowreceiptsMethod.COD
								: undefined,
						order_detail: order.details.data.map((i) => {
							return {
								product_name: i.product_json.name,
								product_quantity: i.item_quantity,
								product_sku: i.product_json.sku,
							};
						}),
					});
					deliveries_id_list = deliveryPromise.data.delivery_id;
					dataDelivery &&
						(await CheckoutRepo.createCashflowForDelivery(
							deliveryPromise.data.data.id,
							dataDelivery,
							order.store_id,
							order.customer_id
						));
				} catch (error) {
					const codeErrorToPass = ["error_resource_id_already_exist"];
					const deliveryErrors = getDataError(error).errors;

					if (_.intersection(codeErrorToPass, deliveryErrors).length <= 0) {
						const dataError = getDataError(error);
						dataError.errors.push(
							checkoutConstant.POS.ERRORS.delivery_create_failed
						);
						throw dataError;
					} else {
						const listDeliveryOnOrder = await getDeliveries({
							resource_id: order.id,
						});
						deliveries_id_list = listDeliveryOnOrder.data.items
							.map((i) => i.id)
							.join(",");

						dataDelivery &&
							CheckoutRepo.createCashflowForDelivery(
								listDeliveryOnOrder.data.items?.[0]?.id,
								dataDelivery,
								order.store_id,
								order.customer_id
							);
					}
				}

				const { data: resCheckout } = await checkoutAction(
					{
						...data,
						order_id: order.id,
						sale_channel: dataDeliveryPos.sale_channel,
						delivery_id:
							deliveries_id_list.toString().length > 0
								? deliveries_id_list.toString()
								: undefined,
					},
					isUser
				);
				// checkout - create delivery + craete cashFlows
				if (!resCheckout?.invoice_id) {
					throw BaseApi.handleErrorCore({
						errors: ["respons_checkout_not_invoiceid"],
						status: 406,
					});
				}

				return resCheckout;
			} catch (error) {
				const dataError = getDataError(error);
				dataError.errors.push(
					checkoutConstant.POS.ERRORS.order_checkout_failed
				);
				throw dataError;
			}
		},
		[JSON.stringify(order), cashFlows, JSON.stringify(dataDelivery)]
	);

	const createOrder = useCallback(async (data: createOrderProps) => {
		try {
			const res = isUser
				? await createOrderUser(data)
				: await createOrderPublic(data);
			// if (res.data) {
			// 	onFinish(res.data);
			// }
			return res.data as OrderJson;
		} catch (error) {
			throw getDataError(error);
		}
	}, []);

	const cancelOrder = useCallback(
		async (orderId: string | number, cancelReason: ORDER_CANCEL_REASON) => {
			const promiseCancelCashflow = async (id: number | string) => {
				return updateCashflowreceipts({
					id: id,
					status: CashflowreceiptsStatus.CANCEL,
				});
			};

			try {
				const data: Omit<CartAddEditJson, "cart_id"> = {
					status: ORDER_STATUS.CANCEL,
					type_sale: "employee",
					cancel_reason: cancelReason,
				};

				const cashflowCreatedOfOrder = OrderRepo.getCashflowCreatedLocalOfOrder(
					orderId,
					true
				);
				const promiseCancelCashflows = cashflowCreatedOfOrder.map((i) =>
					promiseCancelCashflow(i.id)
				);
				await Promise.all(promiseCancelCashflows);

				const res = await editOrderUser(orderId, data);

				return res.data;
			} catch (error) {
				throw getDataError(error);
			} finally {
			}
		},
		[]
	);

	const deleteOrder = useCallback(async (orderId: string | number) => {
		try {
			const res = await deleteUserCart({
				cart_id: orderId.toString(),
				type_sale: "employee",
			});
			deleteOrderCashflowData(orderId);
			return res;
		} catch (error) {
			throw error;
		}
	}, []);

	const createPreOrder = useCallback(async () => {
		try {
			const res = isUser
				? await createCartUser({
						store_id: store_id,
				  })
				: await createCartPublic({
						store_id: store_id,
						customer_token: browser_id,
				  });
			if (res.data) {
				onFinish(res.data);
			}
			return res.data;
		} catch (error) {
			throw getDataError(error);
		}
	}, []);

	const initPreOrder = useCallback(async () => {
		const hasOrder = order.id.toString().length > 0;

		if (!hasOrder) return createPreOrder();

		return order;
	}, []);

	const createCashFlows = useCallback(
		async (data: Partial<CashflowreceiptJson>[]) => {
			// const defaultData = {
			// 	status: 1, //1: nháp | 3: chờ duyệt | 5: duyệt | 9: hoàn thành | 11: cancel
			// 		direction: 5, //5 là dạng phiếu thu | 10 là dạng phiếu chi
			// 		source_id: order.id, //id tham chiếu là đơn hàng
			// 		source: 1, //type 1 là đơn hàng
			// 		target: 3, // 3 là khách hàng
			// 		target_id: order.customer_id, //id khách hàng
			// 		creator_id: cus?.user?.company?.employee?.id || 0, // người tạo
			// 		identifier: order.code, //order code
			// 		name: order.code, //order code
			// 		note: "", //ghi chú
			// 		cashflow_group: 101, //group bán hàng
			// }
			// const mergeData = mergeObjects(defaultData,data)
			// setLoading(true);
			try {
				if (order.order_id <= 0) {
					throw Error("order_id_empty");
				}

				// if (order.customer_id <= 0) {
				// 	throw Error("customer_id_empty");
				// }

				const promises = data.map((d) =>
					isUser
						? createCashflowreceipts({
								status: CashflowreceiptsStatus.DRAFT,
								// name: order.code,
								// identifier: order.code,

								...d,
								target_id: d?.target_id ?? order.customer_id,
								source: 1,
								source_id: order.order_id,
						  })
						: createCashflowreceiptsPublic({
								status: CashflowreceiptsStatus.DRAFT,
								// name: order.code,
								// identifier: order.code,
								...d,
								target_id: d?.target_id ?? order.customer_id,
								source: 1,
								source_id: order.order_id,
						  })
				);

				const res = await Promise.all(promises);
				if (res) {
					// const dataPush = [...res] as CashflowLocal[];
					// setCashFlows((prev: CashflowLocal[]) => [...prev, ...dataPush]);
				}

				return res;
			} catch (error) {
				throw getDataError(error);
			} finally {
				// setLoading(false);
			}
		},
		[order]
	);

	const updateCashFlows = useCallback(
		async (
			data: { id: number | string; data: Partial<CashflowreceiptJson> }[],
			isProcessLoading?: boolean
		) => {
			isProcessLoading && setLoading(true);

			try {
				const hasNotValidValue = data.some(
					(item) => item.data.hasOwnProperty("value") && item.data.value == 0
				);
				if (hasNotValidValue) {
					throw BaseApi.handleErrorCore({
						errors: ["value_not_in_valid"],
						status: 406,
					});
				}
				const dataMap = data.reduce((curr: CashflowLocal[], cashUpdate) => {
					const cashFlowExited = cashFlows.find(
						(i: CashflowLocal) => i.id === cashUpdate.id
					);
					if (cashFlowExited) {
						const dataMerge = mergeObjects(
							cashFlowExited,
							cashUpdate.data
						) as CashflowLocal;
						curr.push(dataMerge);
					}
					return curr;
				}, []);
				if (!dataMap.length) {
					throw BaseApi.handleErrorCore({
						errors: ["no_date_update"],
						status: 406,
					});
				}

				const promises: Promise<CashflowLocal>[] = dataMap.map((item) => {
					const dataUpdate = Helper.convertParams(
						item
					) as Partial<CashflowLocal>;
					return updateCashflowreceipts({
						method: dataUpdate.method,
						status: dataUpdate.status,
						id: dataUpdate.id,
					});
				});

				const res = await Promise.all(promises);

				// const dataNewUpdate = cashFlows.reduce(
				// 	(curr: CashflowLocal[], prev: CashflowLocal) => {
				// 		const cashflowinUpdated = res.find((i) => i.id === prev.id);
				// 		if (cashflowinUpdated) {
				// 			const dataMerge = mergeObjects(
				// 				prev,
				// 				cashflowinUpdated
				// 			) as CashflowLocal;

				// 			curr.push(dataMerge);
				// 		} else {
				// 			curr.push(prev);
				// 		}

				// 		return curr;
				// 	},
				// 	[]
				// );

				return res;
			} catch (error) {
				throw getDataError(error);
			} finally {
				isProcessLoading && setLoading(false);
			}
		},
		[order, cashFlows]
	);

	const removeAllCashflows = async () => {
		try {
			const resDelete = await updateCashFlows(
				cashFlows.map((cash: CashflowLocal) => ({
					id: cash.id,
					data: { status: CashflowreceiptsStatus.CANCEL },
				})),
				false
			);
			// setCashFlows([]);
		} catch (error) {
			throw error;
		}
	};

	const createDelivery = useCallback(
		async (data: DeliveryInternalAddEditJson) => {
			try {
				const res = await createDeliveries(data);
				return res;
			} catch (error) {
				throw getDataError(error);
			}
		},
		[]
	);

	///////////////////////////////////
	// calc + const
	const calcPriceItem = useCallback(
		(item: CartOrderJson, q?: number) => {
			return OrderRepo.calcPriceOrderItem(item, q);
		},
		[order]
	);

	const getCalculatedCashflowValue = ({
		cashs,
		cashCalculated,
		priceTotalOrder,
	}: {
		cashs: CashflowLocal[];
		cashCalculated: CashflowLocal;
		priceTotalOrder: number;
	}) => {
		const totalCashFlowFilters = cashs.reduce(
			(curr: number, prev: CashflowLocal) => {
				if (prev.id !== cashCalculated.id) {
					curr += prev.value;
				}

				return curr;
			},
			0
		);

		return priceTotalOrder - totalCashFlowFilters;
	};

	const calPriceDataPrintBill = ({
		priceOrder,
		cashflows,
	}: {
		priceOrder: number;
		cashflows: CashflowLocal[];
	}) => {
		const priceDeposit =
			priceOrder -
			(cashflows.find((c) => c.method === CashflowreceiptsMethod.COD)?.value ||
				0);

		return {
			price_final: priceOrder,
			debt:
				cashflows.find((c) => c.method === CashflowreceiptsMethod.COD)?.value ||
				0,
			deposit: priceDeposit,
		};
	};

	const productOrders = order.details.data.filter(
		(data) => data.is_use === IsUse.USE
	);

	const couponItems = productOrders
		.flatMap((data) => data.promotions)
		.filter((pro) => pro.promotion_detail.group === PromotionGroupType.coupon);
	const vouchers = order.payments.filter((item) => item.method === "voucher");

	const sumaryOrder = useMemo(() => {
		// total price origin of product
		const priceSell = productOrders.reduce((pre, pro) => {
			if (pro.product_json.compare_at_price) {
				return pre + pro.product_json.compare_at_price * pro.item_quantity;
			}
			return pre + pro.item_unit_price * pro.item_quantity;
		}, 0);

		// total discount price  of product
		const priceDiscountTotalProducts = productOrders.reduce((pre, pro) => {
			if (
				pro.promotions.find(
					(promo) =>
						promo.is_use === IsUse.USE &&
						promo.promotion_detail.group === PromotionGroupType.seasonal
				)
			) {
				return (
					pre +
					pro.price_discount +
					pro.product_json.compare_discount * pro.item_quantity
				);
			}
			return pre + pro.product_json.compare_discount * pro.item_quantity;
		}, 0);

		const priceDiscountCouponOnItem = couponItems.reduce((pre, pro) => {
			return pre + (pro.discount || 0);
		}, 0);

		const priceDiscountTotalItemsAndBody =
			order.item_discount + order.order_discount;

		const priceDiscountVoucher = vouchers.reduce((pre, item) => {
			return pre + item.amount;
		}, 0);

		const priceDiscountTotalOrder =
			priceDiscountTotalItemsAndBody + priceDiscountVoucher;

		const priceVat = Math.floor((order.price_tax / 100) * order.price_sell);

		const order_custom_discount = order.order_custom_discount;
		const priceFinal = order.price_final;
		// priceVat +

		const priceDiscountPromotionTotal =
			order.order_discount + priceDiscountCouponOnItem;

		const priceSaveTotal =
			priceDiscountPromotionTotal + priceDiscountTotalProducts;

		//////////////////////////
		// Tổng tiết kiệm

		return {
			priceSell,
			priceDiscountTotalProducts,
			priceDiscountTotalItemsAndBody,
			priceDiscountVoucher,
			priceDiscountTotalOrder,
			priceSaveTotal,
			priceFinal,
			order_custom_discount,
			priceDiscountPromotionTotal,
		};
	}, [JSON.stringify(order)]);

	const pricePos = useMemo(() => {
		const totalOrder = order.total_payment;
		const totalCashFlows = cashFlows.reduce(
			(curr: number, prev: CashflowLocal) => {
				curr += prev.value;

				return curr;
			},
			0
		);

		//

		const totalAmountPaid = totalCashFlows;
		const totalAmountPayable = Math.max(0, totalOrder - totalAmountPaid);
		const priceVat = Math.floor((order.price_tax / 100) * order.price_sell);

		const priceReturn = totalAmountPaid - totalOrder;
		const totalReturn = Math.max(0, priceReturn);
		return {
			totalOrder,
			totalCashFlows,
			totalAmountPaid,
			totalAmountPayable,
			priceVat,
			totalReturn,
		};
	}, [
		order.id,
		order.details.data.length,
		cashFlows,
		order.price_tax,
		order.total_payment,
	]);

	const isHasVat = useMemo(() => {
		let result = false;
		if (order?.note_invoice?.hasOwnProperty("bill_recipient_name")) {
			result = true;
		}

		return result;
	}, [order.note_invoice]);

	///////////////////////////////////
	// ueffect

	useEffect(() => {
		// if (hadOrder && order.status === 12) {
		setOrder(orderDefault);
		setPromotions(orderDefault.promotions);
		// }
	}, [JSON.stringify(orderDefault)]);

	useEffect(() => {
		setSumaryOrderData(sumaryOrder);
	}, [sumaryOrder]);

	useEffect(() => {
		setPricePosData(pricePos);
	}, [pricePos]);

	// useEffect(() => {
	// 	return () => {
	// 		setCashFlows([]);
	// 	};
	// }, []);
	return {
		order,
		cashFlows,
		promotions,
		coupons,
		deliveries,
		loading: loading,
		loadingCashFlows: false,
		isFetchedCashflows: false,
		error,
		pricePosData,
		sumaryOrderData,
		defaultCouponItems,
		isHasVat,
		loadingDeliveries,
		hookCashflows,

		//data for checkout
		dataCustomer,
		dataDelivery,
		dataShipping,

		setDataDelivery,
		setDataShipping,
		setDataCustomer,
		/////////////
		setOrder,
		// setCashFlows,
		removeAllCashflows,
		// addCouponOrder,
		addCouponOrder2,
		updateCartItemQuantity,
		addPromotionBody,
		removePromotionBody,
		addManyProductOder,

		updateVariantProductOrder,
		updatePromotionProductOrder,
		updateManyIsUseProductOrder,
		updatePurchaseInfor,
		updateCustomer,
		updateSeller,
		updatePriceShipping,

		addManyPromotionForOrderItem,
		removeManyPromotionForOrderItem,
		updatePriceTax,
		updateVatInNote,
		deleteManyProductOrder,
		removeCouponOrder,
		createOrder,
		createCashFlows,
		updateCashFlows,
		createDelivery,
		checkout,
		buyNow,
		calcPriceItem,
		deleteOrder,
		updatePriceDiscount,
		setLoading,

		getCalculatedCashflowValue,
		calPriceDataPrintBill,

		cancelOrder,
	};
};

export default useOrder;
