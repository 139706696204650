"use client";
import React, { useState } from "react";
import InfoCircleIcon from "./icons/InfoCircleIcon";
import Button from "./ui/Button";
import useTranslationApi from "@/lib/hooks/useTranslationApi";
import { cn } from "@/utils/utils";

type Props = {
	loading: boolean;
	fallback: React.ReactNode;
	children: React.ReactNode;
	fallbackError?: React.ReactNode;
	errors?: string[];
	onReload?: () => void;
	className?: string;
	fallbackProps?: {
		className?: string;
	};
};
export default function LoadingElement({
	loading,
	children,
	fallback,
	fallbackError,
	errors = [],
	onReload,
	className,
	fallbackProps,
}: Props) {
	const [isFetching, setIsFetching] = useState(false);
	const { t } = useTranslationApi(["quick_view_error"]);

	const handleReload = () => {
		setIsFetching(true);
		onReload && onReload();
		return new Promise((resolve, recject) => {
			setTimeout(() => {
				setIsFetching(false);
				resolve(1);
			}, 2000);
		});
	};
	return (
		<>
			{errors.length > 0 ? (
				fallbackError || (
					<div className="text-gray-400 font-medium text-center bg-white flex flex-col gap-3 items-center justify-center rounded-lg min-h-[85vh] md:min-h-[480px]">
						<InfoCircleIcon className="text-gray-400 w-6 h-6 min-w-6" />
						{t("quick_view_error")}
						<Button loading={isFetching} onClick={() => handleReload()}>
							{isFetching ? "Đang tải lại" : "Tải lại ngay"}
						</Button>
					</div>
				)
			) : (
				<>
					{loading && (
						<div
							className={cn("", fallbackProps?.className, {
								"hidden ": !loading,
							})}>
							{fallback}
						</div>
					)}

					<div
						className={cn("", className, {
							"hidden ": loading,
						})}>
						{children}
					</div>
				</>
			)}
		</>
	);
}
