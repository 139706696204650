"use client";
import Link from "next/link";
import React, { useEffect } from "react";

import CartIcon from "@/components/icons/CartIcon";
import SearchIcon from "@/components/icons/SearchIcon";
import LogoHeaderIcon from "@/components/icons/LogoHeaderIcon";
import NotificationIcon from "@/components/icons/NotificationIcon";
import { useAppSelector, useAppDispatch } from "@/redux/hook";
import {
	setIsShowSearchMobile,
	setNotifyMobile,
	setSidebarMobile,
} from "@/redux/features/ShopSlice";
import HeaderTinyNotification from "./children/HeaderTinyNotification";
import HeaderTinyMenuSearch from "./children/HeaderTinyMenuSearch";
import useNotification from "@/lib/hooks/useNotification";
import { MenuItemMobile } from "@/types/Setting.type";
import LinkElement from "@/components/LinkElement";

// const categories = [
//   {
//     title: "Nước hoa nữ",
//     image: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/March/1330-219541711348963-1711348963.jpg",
//     link: "/collections/nuoc-hoa-nu",
//   },
//   {
//     title: "Nước hoa nam",
//     image:
//       "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/March/1328-386931711348953-1711348953.jpg",
//     link: "/collections/nuoc-hoa-nam",

//   },
//   {
//     title: "Nước hoa mini",
//     image:
//       "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/March/1327-717271711348948-1711348948.jpg",
//     link: "/collections/nuoc-hoa-mini",

//   },
//   {
//     title: "nước hoa niche",
//     image:
//       "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/March/1329-505621711348960-1711348960.jpg",
//     link: "/collections/nuoc-hoa-niche",

//   },
//   {
//     title: "giftset",
//     image:
//       "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/March/1326-384411711348944-1711348944.jpg",
//     link: "/collections/giftset-nuoc-hoa",

//   },
//   {
//     title: "son môi",
//     image:
//       "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/March/1343-778171711349881-1711349881.jpg",
//     link: "/collections/son-moi",

//   },
//   {
//     title: "namperfume Favorites",
//     image:
//       "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/March/1334-824411711349441-1711349442.jpg",
//     link: "/blogs/thuong-hieu-nuoc-hoa/namperfume-favorites",

//   },
//   {
//     title: "Deal Thơm",
//     image:
//       "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/March/1333-758331711349438-1711349438.jpg",
//     link: "/collections/deal-thom",

//   }

// ];

const HeaderMobileTop = ({ categories }: { categories: MenuItemMobile[] }) => {
	const dispatch = useAppDispatch();

	const isShowSearchMobile = useAppSelector(
		(state) => state.ShopReducer.isShowSearchMobile
	);
	const notifyMobile = useAppSelector(
		(state) => state.ShopReducer.notifyMobile
	);
	const cartDetail = useAppSelector((state) => state.CartReducer.cartDetail);

	const toggleNotify = () => {
		dispatch(setNotifyMobile(!notifyMobile));
		dispatch(setIsShowSearchMobile(false));
		dispatch(setSidebarMobile(false));
	};

	const toggleMenuSearch = () => {
		dispatch(setIsShowSearchMobile(!isShowSearchMobile));
		dispatch(setNotifyMobile(false));
		dispatch(setSidebarMobile(false));
	};

	//Notification
	const { notifications: data, errors, loading, total } = useNotification();

	useEffect(() => {
		dispatch(setIsShowSearchMobile(false));
		dispatch(setNotifyMobile(false));
		dispatch(setSidebarMobile(false));

		return () => {
			dispatch(setIsShowSearchMobile(false));
			dispatch(setNotifyMobile(false));
			dispatch(setSidebarMobile(false));
		};
	}, []);

	return (
		<>
			<div
				className={
					"flex items-center justify-between relative bg-white md:hidden px-[16px] py-[8px] h-[40px]"
				}>
				<div
					className="relative inline-flex items-center cursor-pointer z-20"
					onClick={toggleNotify}>
					<NotificationIcon className="min-w-[24px]" />
					{total > 0 && (
						<div className="absolute flex justify-center items-center w-[15px] h-[15px] leading-[15px] text-[8px] font-[700] text-white bg-red-500 rounded-full bottom-[-2px] right-[-4px]">
							{total}
						</div>
					)}
				</div>
				{/* Logo */}
				<div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
					<LinkElement passHref={true} href={"/"} prefetch={true}>
						<LogoHeaderIcon className="w-[123px] h-[24px] min-h-[24px]" />
					</LinkElement>
				</div>
				{/* Search & Cart */}
				<div className="flex items-center gap-[6px] z-20">
					<div onClick={toggleMenuSearch}>
						<SearchIcon className="min-w-[24px] cursor-pointer" />
					</div>
					<div className="relative inline-flex items-center cursor-pointer">
						<div className="pt-[3px]">
							<LinkElement href="/cart">
								<CartIcon className="min-w-[24px]" />
								{cartDetail && cartDetail.details.data.length > 0 && (
									<div className="absolute flex justify-center items-center w-[15px] h-[15px] leading-[15px] text-[8px] font-[700] text-white bg-red-500 rounded-full bottom-0 right-[-5px]">
										{cartDetail.details.data.length}
									</div>
								)}
							</LinkElement>
						</div>
					</div>
				</div>
			</div>
			{notifyMobile && (
				<HeaderTinyNotification data={data} errors={errors} loading={loading} />
			)}
			{isShowSearchMobile && (
				<HeaderTinyMenuSearch
					categories={categories}
					onClick={toggleMenuSearch}
				/>
			)}
		</>
	);
};

export default HeaderMobileTop;
