import { useEffect, useId, useRef, useState } from "react";
import {
	createCashflowreceipts,
	getCashFlowreceipts,
} from "../api/shop/cashflowreceipts";
import {
	CashflowreceiptJson,
	CashflowreceiptsMethod,
	CashflowreceiptsStatus,
} from "@/types/Cashflowreceipts.type";
import { useQuery } from "@tanstack/react-query";
import { getDataError } from "../config/apiHandler";
import { OrderJson, SERVICE_TYPE } from "@/types/Orders.type";
import CheckoutRepo from "../repo/checkout";
import { CustomerJson } from "@/types/Customer.type";
import { ORDER_TYPE } from "@/common/constants/order";
import { CASHFLOW_TARGET } from "@/common/constants/cashflow";
import useLocalStorage from "./useLocalStorage";
import OrderRepo from "../repo/order";
import { ShipType } from "@/app/pos/_components/PostCart";
import { v4 as uuidv4 } from "uuid";

type Props = {
	order: OrderJson;
	userId?: number;
	typeSale?: SERVICE_TYPE;
};

export type DataLocalCashflow = {
	[id: string]: CashflowLocal[];
};

export type CashflowLocal = Pick<CashflowreceiptJson, "method" | "value"> & {
	id: number | string;
	status: CashflowreceiptsStatus;
	orderId?: number | string;
	isCreate?: boolean;
};

type CreateCashProps = {
	method?: number;
	amount: number;
	status?: CashflowreceiptsStatus;
	id?: number | string;
	isCreate?: boolean;
};

type UpdateCashProps = {
	defaultData?: CreateCashProps;
	id: number | string;
	dataUpdate: Partial<CreateCashProps>;
};
const useCashFlowsLocal = ({ order, userId, typeSale }: Props) => {
	const KEY_CASHFLOW_LOCAL = "pos_cashflows";
	const orderId = typeof order.id === "string" ? order.order_id : order.id;
	const allMethod = OrderRepo.contantMethodCashFlow(typeSale);

	/////////////////////////////////////////
	const { data: dataLocal, setLocal } = useLocalStorage<DataLocalCashflow>({
		key: KEY_CASHFLOW_LOCAL,
	});

	////////////////////////////////////////
	const dataLocalWithOrderId = dataLocal?.[order.id.toString()];

	const [cashFlows, setCashFlows] = useState<Array<CashflowLocal>>(
		dataLocalWithOrderId || []
	);

	const isInitDataLocalInState = useRef<boolean>(false);
	////////////////////////////////////////
	const chooseMethodToCreateCashFlow = (): number | null => {
		const dataFilter = allMethod.filter(
			(i) => !cashFlows.some((ii: CashflowLocal) => ii.method === i.value)
		);
		if (dataFilter.length > 0) return dataFilter[0].value;

		return null;
	};

	const createCash = async (data: CreateCashProps) => {
		const methodLabelToCreate = data?.method ?? chooseMethodToCreateCashFlow();
		const amountToCreate = data.amount;
		try {
			if (!methodLabelToCreate) {
				throw new Error("method_to_create_empty");
			}

			if (methodLabelToCreate === CashflowreceiptsMethod.CARD) {
			}
			const idFake = uuidv4();
			const dataCreate = {
				id: data?.id ?? idFake,
				method: methodLabelToCreate,
				value: amountToCreate,
				orderId: order.id,
				status: data?.status || CashflowreceiptsStatus.DRAFT,
				isCreate: data?.isCreate ?? false,
			} as CashflowLocal;

			setCashFlows((prev) => [...prev, dataCreate]);
			rewriteLocal([...cashFlows, dataCreate]);

			return dataCreate;
		} catch (error) {
			throw error;
		}
	};

	const deleteCash = (id: string | number) => {
		try {
			const itemFindToDelete = cashFlows.find((i) => i.id === id);
			if (!itemFindToDelete) {
				throw new Error("not_find_item_to_delete");
			}

			setCashFlows((prev) => {
				const result = prev.filter((i) => i.id !== id);
				rewriteLocal(result);

				return result;
			});

			return itemFindToDelete;
		} catch (error) {
			throw error;
		}
	};

	const updateCash = (data: UpdateCashProps) => {
		try {
			let updatedItem = null as CashflowLocal | null;
			let cashFlowFull = cashFlows;

			setCashFlows((prevCashFlows) => {
				// Tìm vị trí của phần tử cần cập nhật
				const indexItemToUpdate = prevCashFlows.findIndex(
					(i) => i.id === data.id
				);

				if (indexItemToUpdate >= 0) {
					// throw new Error("not_find_item_to_update");
					// return

					cashFlowFull = prevCashFlows.map((item, index) => {
						if (index === indexItemToUpdate) {
							const methodUpdate = data.dataUpdate?.method ?? item.method;
							const amountUpdate = data.dataUpdate?.amount ?? item.value;
							const statusUpdate = data.dataUpdate?.status ?? item.status;
							const isCreateUpdate = data.dataUpdate?.isCreate ?? item.isCreate;

							updatedItem = {
								...item,
								method: methodUpdate,
								value: amountUpdate,
								id: data?.dataUpdate?.id || item.id,
								status: statusUpdate,
								isCreate: isCreateUpdate,
							};

							return {
								...item,
								method: methodUpdate,
								value: amountUpdate,
								id: data?.dataUpdate?.id || item.id,
								status: statusUpdate,
								isCreate: isCreateUpdate,
							};
						}
						return item;
					});

					// Cập nhật local storage hoặc nơi lưu trữ khác nếu cần thiết
					rewriteLocal(cashFlowFull);
				}

				// Tạo một mảng mới với phần tử đã cập nhật

				return cashFlowFull;
			});

			return {
				itemUpdated: updatedItem,
				cashflowFull: cashFlowFull,
			};
		} catch (error) {
			throw error;
		}
	};

	//////////////////////////////////
	const deleteOrderCashflowData = (orderId: number | string) => {
		try {
			let dataInit = dataLocal as DataLocalCashflow;
			if (dataInit.hasOwnProperty(orderId.toString())) {
				delete dataInit[orderId.toString()];

				setLocal(dataInit);
			}
		} catch (error) {}
	};

	const rewriteLocal = (data: CashflowLocal[]) => {
		let dataInit = dataLocal as DataLocalCashflow;

		if (!dataInit) {
			setLocal(Object.assign({}, { [order.id]: data }));
		} else {
			if (typeof dataInit === "object") {
				dataInit = { ...dataInit, [order.id]: data };

				// Cập nhật local storage
				setLocal(dataInit);
				// if (dataInit.hasOwnProperty(order.id.toString())) {
				// 	dataInit[order.id] = data;
				// 	setLocal(dataInit);
				// } else {
				// 	setLocal(Object.assign(dataInit, { [order.id]: data }));
				// }
			}
		}
	};

	useEffect(() => {
		if (
			typeof dataLocalWithOrderId !== "undefined" &&
			isInitDataLocalInState.current === false
		) {
			isInitDataLocalInState.current = true;
			if (dataLocalWithOrderId.length <= 0) {
			} else {
				setCashFlows(dataLocalWithOrderId);
			}
		}
	}, [isInitDataLocalInState, dataLocalWithOrderId]);

	return {
		loadingCashFlows: false,
		cashFlows,
		updateCash,
		deleteCash,
		createCash,
		deleteOrderCashflowData,
	};
};

export default useCashFlowsLocal;
