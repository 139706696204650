import { BaseCollectionJson } from "@/common/types/BaseCollection.type";
import { CouponCheckJson } from "./Coupon.type";
import { ProductJson } from "./Product.type";
import { PromotionJson } from "./Promotion.type";
import { PaymentDataSubmit, PaymentMethodDetailUpdate } from "./Payment.type";
import { BrandJson } from "./Brand.type";
import { ORDER_STATUS, OrderJson, SERVICE_TYPE } from "./Orders.type";
import {
	ORDER_CANCEL_REASON,
	ORDER_DISCOUNT_TYPE,
} from "@/common/constants/order";

type CartId = CartJson["id"];

// type CartJson = {
// 	company_id: number;
// 	creator_id: number; // người tạo

// 	id: string; // id của order

// 	code: string;
// 	store_id: number; // cửa hàng
// 	customer_id: number; // customer
// 	browser_id: string;
// 	coupons?: CartCouponAddJson[];
// 	cancel_reason: number;

// 	// tiền
// 	price_sell: number;
// 	price_shipping: number;
// 	price_discount: number; // tiền giảm giá
// 	price_discount_coupon: number;
// 	price_final: number; // tiền tổng
// 	price_cost: number;
// 	price_deposit: number;
// 	price_debt: number;
// 	price_tax: number;

// 	promotion_id: number;
// 	// promotion_detail: string;
// 	promotions: CartPromotion[];

// 	contact_email: string; // email liên hệ

// 	// phần mua
// 	billing_fullname: string;
// 	billing_phone: string;
// 	billing_email: string;
// 	billing_address: string;
// 	billing_ward: number;
// 	billing_district: number;
// 	billing_province: number;
// 	billing_country: number;
// 	billing_company: string;
// 	billing_sub_sub_region_id: number;
// 	billing_sub_region_id: number;
// 	billing_region_id: number;

// 	// phần giao hàng
// 	shipping_fullname: string;
// 	shipping_phone: string;
// 	shipping_address: string;
// 	shipping_ward: number;
// 	shipping_district: number;
// 	shipping_province: number;
// 	shipping_country: number;
// 	shipping_company: string;
// 	// shipping_sub_sub_region_id: number;
// 	// shipping_sub_region_id: number;
// 	// shipping_region_id: number;

// 	warehouse_id: number; // kho hàng nào

// 	shipping_carrier: number; // đơn vị vẫn chuyển

// 	product_receipt_id_list: number[];
// 	cashflow_receipt_id_list: number[];
// 	tax_invoice_id: number;
// 	quantity: number;
// 	note: string;
// 	cod_amount: number;

// 	status: number; // trạng thái mới tạo là 12 danh sách trạng thái ở dưới
// 	vnpay_status: boolean;
// 	tag: string;
// 	ecom_platform_id: number;
// 	ecom_platform_type: number;
// 	ecom_platform_order_id: string;
// 	ecom_platform_invoice_id: string;
// 	date_arrived: number;
// 	date_created: number;
// 	date_modified: number;
// 	payment_method: number;
// 	resource_type: number;
// 	resource_id: string;
// 	resource_number: string;
// 	order_detail: string;
// 	order_description: string;
// 	// product_receipt_list: [];
// 	// cashflow_receipt_list: [];
// 	// tax_invoice_list: [];
// 	kiotviet_code: string;
// 	details: {
// 		data: CartOrderJson[];
// 		total: number;
// 	};
// 	total_payment: number;
// 	debt: number;
// 	payments: PaymentMethodDetailUpdate[];
// 	has_invoice: boolean;
// 	order_custom_discount: number;
// };
type CartJson = OrderJson;
export type AddProductToCart = {
	cart_id: CartId;
	action: "add";
	customer_token?: string;
	details?: Partial<ProductCartAddEditJson>[];
	type_sale?: "employee";
	promotions?: CartPromotion[];
};

type CartAddEditJson = {
	cart_id: CartId;
	action?:
		| "add"
		| "update"
		| "delete"
		| "creator"
		| "coupon"
		| "fetch"
		| "promotion"
		| "update_discount"
		| "update_price"
		| "update_customer"
		| "note"
		| "update_purchase_info"
		| "update_seller";
	customer_token?: string;
	details?: Partial<ProductCartAddEditJson>[];
	coupons?: CartCouponAddJson[];
	promotions?: CartPromotion[];
	type_sale?: "employee";
	note_invoice?: PaymentDataSubmit["note_invoice"];
	service_type?: SERVICE_TYPE;
	seller_id?: number;
	status?: ORDER_STATUS;
	cancel_reason?: ORDER_CANCEL_REASON;
} & Partial<CartUpdateDiscount> &
	Partial<CartUpdatePrice> &
	Partial<CartUpdateCustomer>;

type CartUpdateDiscount = {
	discount_custom_type: ORDER_DISCOUNT_TYPE;
	discount_custom_value: number;
};

export type CartUpdateCustomer = {
	customer_id: number;
};

type CartUpdatePrice = {
	price_tax: number; // thuế truyền phần trăm
	price_shipping: number;
};

type ProductCartAddEditJson = Partial<
	Pick<CartOrderJson, "item_quantity" | "product_id">
> & {
	id?: number;
	promotions?: CartPromotion[];
	is_use?: IsUse;
};

export enum IsUse {
	NOT_USE,
	USE,
}

type CartPromotion = {
	promotion_id: number; // id của loại chường trình promotion
	sale_promotion_id: number; // id của điều kiện promotion
	product_id?: number | string; // id của product nào phát sinh ra promotion (điều kiện)
	item_quantity?: number; // sô lượng sản phẩm
	is_use: IsUse; // có sài cái promotion này không , nếu sài để 1 không sài để 0
	promotion_detail: PromotionJson;
	discount: number;
	code: string;
	id?: number;
};

type CartOrderJson = {
	order_id: CartId;
	product_id: number;
	id: number;
	is_use: IsUse;
	item_title: string;
	item_quantity: number;
	item_unit_price_original: number;
	item_unit_price: number;
	item_date_deleted: number;
	price_unit_final: number;
	// promotion_id: number;
	promotions: CartPromotion[];
	price_final: number;
	price_discount: number;
	product_json: ProductJson;
	discount_percent: number;
	item_total: number;
};
// type CartOrderJson =  {
// 	product_id:  ProductJson["id"];
// 	is_use: IsUse;
// 	item_fullname: ProductJson["full_name"];
// 	item_handle : ProductJson["handle"]
// 	item_quantity: number;
// 	item_unit_price: number;
// 	item_unit_price_compare?: number;
// 	item_sku: ProductJson["sku"]
// 	item_brand:ProductJson["brand"]
// 	promotions: CartPromotion[];
// 	price_discount: number;
// };

type ProductJsonOrder = Omit<
	ProductJson,
	| "compare_at_price_min"
	| "compare_at_price_max"
	| "type_id"
	| "supplier_id"
	| "kiotviet_image"
	| "price_min"
	| "price_max"
> & {
	compare_discount: number;
	compare_discount_percent: number;
};

// type CartPayMethod = {
//   method: "voucher" | "transfer" | "cash";
//   amount: number;
//   voucher?: {
//     code: string;
//   };
// };

type CartOrderCollectionJson = BaseCollectionJson<CartOrderJson>;

type CartCreateJson = {
	store_id: number | string;
	customer_token?: string;
	type_sale?: "employee";
};

type CartDeleteJson = {
	cart_id: CartId;
	customer_token?: string;
	type_sale?: "employee";
};

type CartPreOrderAddEditJson = {
	store_id: number | string;
	details?: ProductCartAddEditJson[];
	coupons?: CartCouponAddJson[];
	promotions?: CartPromotion[];
	customer_token?: string;
	cart_id: string;
};

type CartPreOrderJson = {
	order_id: CartId;
};

type CartPayJson = {
	code: string;
	id: CartId;
	url_payment?: string;
	invoice_id?: string;
};

type CartTimingJson = {
	switch_cart: number;
	recheck_stock: number;
};

type CartCouponAddJson = {
	code: string; // code của coupom
	coupon_campaign_id: number; // id của chương trình coupon
	id: number; // id của coupon
	coupon_detail: CouponCheckJson;
	is_use: IsUse;
	discount?: number;
};

type PriceProductPromotionProps = {
	item_quantity: number;
	product_id: number;
	cart_id: CartId;
	promotions: CartPromotion[];
};

type CalcPriceProps = {
	data: PriceProductPromotionProps[];
};

type PricePromotionJson = {
	product_id: number;
	discount: number;
	price_Unit: number;
	item_quantity: number;
	price_final: number;
};

type QuantityRemaining = {
	product_id: number;
	quantity: number;
	store_id: number;
};

type CalcPricePromotionJson = PricePromotionJson[];

type CartCollectionJson = BaseCollectionJson<CartJson>;

export type {
	CartAddEditJson,
	CartCollectionJson,
	CartCouponAddJson,
	CartCreateJson,
	CartDeleteJson,
	CartJson,
	CartOrderCollectionJson,
	CartOrderJson,
	CartPayJson,
	CartPreOrderAddEditJson,
	CartPreOrderJson,
	CartPromotion,
	CartTimingJson,
	ProductCartAddEditJson,
	ProductJsonOrder,
	CalcPriceProps,
	CalcPricePromotionJson,
	PricePromotionJson,
	PriceProductPromotionProps,
	QuantityRemaining,
	CartId,
	CartUpdateDiscount,
};
