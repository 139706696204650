"use client";

import * as React from "react";
import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { vi } from "date-fns/locale";
import { Calendar } from "@/components/ui/calendar";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";

import { Controller, useController, UseControllerProps } from "react-hook-form";
import { toast } from "sonner";
import useClickOutside from "@/lib/hooks/useClickOutSide";
import { cn } from "@/utils/utils";

type Props = UseControllerProps<any> & {
	placeholder?: string;
	name: string;
	control: any;
	disabled?: boolean;
	showTime?: boolean;
	showIn?: "modal" | "default";
	triggerProps?: {
		className?: String;
	};
	contentProps?: {
		className?: string;
	};
	onChange?: (val: any) => void;
};

export default function DatePickerForm({
	placeholder = "Chọn ngày",
	name,
	control,
	disabled,
	rules,
	showTime,
	showIn = "default",
	triggerProps,
	contentProps,
	onChange,
}: Props) {
	const { field, fieldState, formState } = useController({
		name,
		control,
		rules: rules,
	});

	const { NodeRef, isOutSite, setIsOutSite, setShow, show } = useClickOutside({
		defaultValue: false,
	});
	const buttonTriggerRef = React.useRef<HTMLButtonElement>(null);

	const dateTime = new Date(field.value);
	const [hours, setHours] = React.useState(dateTime.getHours());
	const [minutes, setMinutes] = React.useState(dateTime.getMinutes());

	const handleTimeChange = () => {
		if (!isNaN(hours)) {
			dateTime.setHours(hours);
		}
		if (!isNaN(minutes)) {
			dateTime.setMinutes(minutes);
		}
		field.onChange(dateTime.getTime());
	};

	React.useEffect(() => {
		handleTimeChange();
	}, [hours, minutes, dateTime]);
	return (
		<Controller
			control={control}
			rules={rules}
			name={name}
			render={({
				field: { name, onBlur, ref, value },
				fieldState,
				formState,
			}) => {
				return (
					<>
						{showIn === "default" && (
							<Popover>
								<PopoverTrigger asChild>
									<button
										ref={buttonTriggerRef}
										type="button"
										disabled={disabled}
										className={cn(
											`w-full pb-2 flex items-center justify-between gap-2 border-b border-gray-400 max-h-[46px]`,
											triggerProps?.className,
											{
												"text-muted-foreground": !dateTime,
											}
										)}>
										<p className="flex-1 text-left">
											{field.value && fieldState.isDirty ? (
												<span className="text-base text-gray-500">
													{format(
														field.value,
														showTime ? "dd/MM/yyyy HH:mm" : "dd/MM/yyyy"
													)}
												</span>
											) : (
												<span className="text-sm text-gray-400">
													{placeholder}
												</span>
											)}
										</p>
										<CalendarIcon className="mr-3 h-4 w-4 text-gray-400" />
									</button>
								</PopoverTrigger>
								<PopoverContent className="w-auto p-0 flex items-center">
									<Calendar
										locale={vi}
										mode="single"
										selected={dateTime}
										onSelect={(date, day) => {
											console.log("1");
											const datePrev = dateTime.getDate();
											if (hours && minutes) {
												day.setHours(hours);
												day.setMinutes(minutes);
											}
											const dateTimeNew = day.getTime();
											field.onChange(dateTimeNew);
											onChange?.(dateTimeNew);
										}}
										onMonthChange={(data) => {
											const datePrev = dateTime.getDate();
											data.setDate(datePrev);
											if (data.getDate() === datePrev) {
												const dateTimeNew = data.getTime();
												field.onChange(dateTimeNew);
											} else {
												toast(
													"Ngày bạn chọn không còn hợp lệ vui lòng chọn lại!"
												);
												data.setDate(1);
												data.setMonth(data.getMonth() - 1);
												if (hours && minutes) {
													data.setHours(hours);
													data.setMinutes(minutes);
												}
												const dateTimeNew = data.getTime();
												field.onChange(dateTimeNew);
											}
										}}
										defaultMonth={dateTime}
										initialFocus
										captionLayout="dropdown-buttons"
										fromYear={1900}
										toYear={new Date().getFullYear()}
										onConfirm={() => {
											if (buttonTriggerRef.current) {
												buttonTriggerRef.current.click();
											}
										}}
									/>
									<div className="relative">
										{showTime && (
											<div className="flex items-center gap-2">
												<SelectHours
													value={hours}
													onChange={(newHours) => {
														setHours(newHours);
													}}
													disabled={disabled}
												/>
												<span>:</span>
												<SelectMinutes
													value={minutes}
													onChange={(newMinutes) => {
														setMinutes(newMinutes);
													}}
													disabled={disabled}
												/>
											</div>
										)}
									</div>
								</PopoverContent>
							</Popover>
						)}

						{showIn === "modal" && (
							<div ref={NodeRef} className="relative h-full w-full">
								<button
									onClick={(e) => {
										e.stopPropagation();
										setShow(!show);
									}}
									type="button"
									disabled={disabled}
									className={cn(
										`w-full pb-1 flex items-center justify-between gap-2 border-b border-gray-400 `,
										triggerProps?.className,
										{
											"text-muted-foreground": !dateTime,
										}
									)}>
									<p className="flex-1 text-left">
										{field.value && fieldState.isDirty ? (
											<span className="text-base text-gray-500">
												{format(
													field.value,
													showTime ? "dd/MM/yyyy HH:mm" : "dd/MM/yyyy"
												)}
											</span>
										) : (
											<span className="text-sm text-gray-400">
												{placeholder}
											</span>
										)}
									</p>
									<CalendarIcon className="mr-3 h-4 w-4 text-gray-400" />
								</button>
								<div
									className={cn(
										"bg-white z-tooltip shadow-md hidden absolute top-full left-0",
										{
											" inline-block animate-fade": show,
											"": show,
											" animate-fadeOut": !show,
										}
									)}>
									<Calendar
										locale={vi}
										mode="single"
										selected={dateTime}
										onSelect={(date, day) => {
											console.log("🚀 ~ day:", day);
											if (hours && minutes) {
												day.setHours(hours);
												day.setMinutes(minutes);
											}
											const dateTimeNew = day.getTime();
											field.onChange(dateTimeNew);
										}}
										onMonthChange={(data) => {
											console.log("month");
											const datePrev = dateTime.getDate();
											data.setDate(datePrev);
											if (data.getDate() === datePrev) {
												const dateTimeNew = data.getTime();
												field.onChange(dateTimeNew);
											} else {
												toast(
													"Ngày bạn chọn không còn hợp lệ vui lòng chọn lại!"
												);
												data.setDate(1);
												data.setMonth(data.getMonth() - 1);
												if (hours && minutes) {
													data.setHours(hours);
													data.setMinutes(minutes);
												}
												const dateTimeNew = data.getTime();
												field.onChange(dateTimeNew);
											}
										}}
										onDayClick={() => {
											console.log("123");
										}}
										defaultMonth={dateTime}
										initialFocus
										captionLayout="dropdown-buttons"
										fromYear={1900}
										toYear={new Date().getFullYear()}
										onConfirm={() => {
											setShow(false);
										}}
									/>
									{showTime && (
										<div className="flex items-center gap-2">
											<SelectHours
												value={hours}
												onChange={(newHours) => {
													setHours(newHours);
												}}
												disabled={disabled}
											/>
											<span>:</span>
											<SelectMinutes
												value={minutes}
												onChange={(newMinutes) => {
													setMinutes(newMinutes);
												}}
												disabled={disabled}
											/>
										</div>
									)}
								</div>
							</div>
						)}
					</>
				);
			}}></Controller>
	);
}

type SelectHoursProps = {
	value: number;
	onChange: (value: number) => void;
	disabled?: boolean;
};
function SelectHours({ value, onChange, disabled }: SelectHoursProps) {
	const hours = Array.from({ length: 24 }, (_, i) => i);

	return (
		<select
			value={value}
			onChange={(e) => {
				e.stopPropagation();
				e.preventDefault();
				onChange(Number(e.target.value));
			}}
			disabled={disabled}
			className="border border-gray-300 rounded-md p-2 bg-white text-gray-700 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:text-gray-500">
			{hours.map((hour) => (
				<option
					key={hour}
					value={hour}
					className="text-gray-700 hover:bg-blue-100">
					{hour.toString().padStart(2, "0")}
				</option>
			))}
		</select>
	);
}

type SelectMinutesProps = {
	value: number;
	onChange: (value: number) => void;
	disabled?: boolean;
};

export function SelectMinutes({
	value,
	onChange,
	disabled,
}: SelectMinutesProps) {
	const minutes = Array.from({ length: 60 }, (_, i) => i);

	return (
		<select
			value={value}
			onChange={(e) => {
				e.stopPropagation();
				e.preventDefault();
				onChange(Number(e.target.value));
			}}
			disabled={disabled}
			className="border border-gray-300 rounded-md p-2 bg-white text-gray-700 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100 disabled:text-gray-500">
			{minutes.map((minute) => (
				<option
					key={minute}
					value={minute}
					className="text-gray-700 hover:bg-blue-100">
					{minute.toString().padStart(2, "0")}
				</option>
			))}
		</select>
	);
}
