const CashFlow = {
	method: {
		CASH: 1,
		TRASNFER: 3,
		COD: 11,
		// VNPAY: 15,
		CARD: 19,
		// VOUCHER: 9999,
	},

	taget: {},
	transfer: {
		setting_bank_infor: "setting_info_transfer",
	},
};

export enum CASHFLOW_TARGET {
	"customer" = 3,
	"employee" = 1,
}

export default CashFlow;
