import Link, { LinkProps } from "next/link";
import React, { AnchorHTMLAttributes, useId } from "react";
import { UrlObject } from "url";

interface Props<T>
	extends LinkProps,
		Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "href"> {
	children?: React.ReactNode;
	ariaLabel?: string;
	target?: "_blank" | "_self" | "_parent" | "_top" | string;
}
export default function LinkElement<T>({
	children,
	ariaLabel,
	href,
	target = "_self",
	...props
}: Props<T>): React.ReactElement {
	const hrefFinal =
		typeof href === "string" ? (href.length > 0 ? href : "#") : href;

	return (
		<Link
			target={target}
			passHref={href === "/" ? true : props?.passHref || false}
			aria-label={ariaLabel}
			key={useId()}
			prefetch={props?.prefetch ?? false}
			href={hrefFinal ? hrefFinal : "#"}
			className={props.className}
			{...props}>
			{children}
		</Link>
	);
}
