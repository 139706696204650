

"use client"
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { getCouponPublicList } from '../api/shop/coupon'
import { CouponCollectionJson, CouponJson } from '@/types/Coupon.type'

export default function useGetCodePromotion(promotion_id : number , option ?: Omit<UseQueryOptions<CouponJson[],any> , "queryKey" | "queryFn">) {
  return useQuery({
    queryKey: ["useGetCodePromotion" , promotion_id],
    queryFn: async () => {
        const { data } = await getCouponPublicList(promotion_id)
        return data.items
    },
    staleTime: Infinity,
    ...option
  })
}
