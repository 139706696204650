import React from "react";
import SelectControl, { SelectCoreProps } from "../core/SelectControl";
import { FieldValues, FieldPath } from "react-hook-form";
import LabelControl from "../core/components/LabelControl";
import { cn } from "@/utils/utils";
interface SelectPropsCustom {
  label?: string;
}
function SelectLine<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>(props: SelectCoreProps<TFieldValues, TName> & SelectPropsCustom) {
  return (
    <div className="w-full flex flex-col">
      <div>
        <LabelControl isrequired={props.required}>{props.label}</LabelControl>
      </div>
      <div>
        <SelectControl
          {...props}
          className={cn(
            "w-full bg-white translate cursor-pointer z-[1] min-w-[150px] py-[4px] flex items-center justify-between gap-[6px] border-b border-gray-400 text-base",
            props.className
          )}
        />
      </div>
    </div>
  );
}

export default SelectLine;
