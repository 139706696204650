"use client";
import MapElements from "@/components/MapElements";
import { ProductJson } from "@/types/Product.type";
import { cn } from "@/utils/utils";
import React, { useState } from "react";
import ProductVariantItem from "./children/ProductVariantItem";
import { useAppDispatch } from "@/redux/hook";
import { setTagActive } from "@/redux/features/ShopSlice";

export type PropsVariantList = {
	variants: ProductJson[];
	variantActive: ProductJson;
	loading?: boolean;
	className?: string;
	classNameItem?: string;
	tag?: string;
	isShowPromotion?: boolean;
	isShowImage?: boolean;
	handleActiveVariant?: (variant: ProductJson, tag?: string) => void;
	renderItem?: (item: ProductJson) => React.ReactNode;
	colorTinted?: string;
	showOnlySize?: boolean;

	showPromotion?: boolean;
};
export default function ProductVariantList({
	variants,
	variantActive,
	loading,
	className,
	classNameItem,
	tag,
	isShowPromotion,
	isShowImage,
	handleActiveVariant,
	renderItem,
	showOnlySize,
	showPromotion = true,
}: PropsVariantList) {
	const dispatch = useAppDispatch();

	const handleActive = (v: ProductJson) => {
		dispatch(setTagActive(tag));
		handleActiveVariant && handleActiveVariant(v);
	};

	return (
		<div
			className={cn(
				"grid grid-cols-2 lg:grid-cols-3 gap-4 md:gap-2",
				className
			)}>
			{variants.length > 0 && (
				<MapElements
					data={variants}
					render={(v, index) => {
						if (renderItem) {
							return renderItem(v);
						} else {
							return (
								<ProductVariantItem
									showOnlySize={showOnlySize}
									key={index}
									variant={v}
									variantActive={variantActive}
									handleActiveVariant={handleActive}
									isShowPromotion={isShowPromotion}
									className={classNameItem}
									tag={tag}
									isShowImage={isShowImage}
									showPromotion={showPromotion}
									// promotions={promotionForProductId(11111)}
								/>
							);
						}
					}}
				/>
			)}
		</div>
	);
}
