"use client";

import ImageConstant from "@/common/constants/image";
import Image from "@/components/ui/Image";
import useDealthom from "@/lib/hooks/useDealthom";
import { checkPromotionInValid } from "@/lib/repo/promotions";
import { ProductJson } from "@/types/Product.type";
import { PromotionJson, TypeTagConfig } from "@/types/Promotion.type";
import Helper from "@/utils/helper";
import { cn } from "@/utils/utils";
type Props = {
  variant: ProductJson;
  variantActive: ProductJson;
  promotions?: PromotionJson[];
  className?: string;
  tag?: String;
  isShowPromotion?: boolean;
  isShowImage?: boolean;
  handleActiveVariant?: (variant: ProductJson) => void;
  colorTinted?: string;
  showOnlySize?: boolean;
  showPromotion?: boolean;
};
export default function ProductVariantItem({
  variant,
  variantActive,
  className = "",
  isShowImage = true,
  handleActiveVariant,
  showOnlySize,
  showPromotion = true,
}: // colorTinted
Props) {
  const variantActiveId = variantActive.id;
  const isOutOfStock = variant.quantity <= 0;
  const { promotionsHaveConfig } = useDealthom(variant);

  const handleActive = (v: ProductJson) => {
    handleActiveVariant && handleActiveVariant(v);
  };
  const isSlected = variantActiveId === variant.id;

  const isPromotion = checkPromotionInValid(variant.promotions);

  const COLLECTION_TINTED = "son-moi";
  const isLipstick = variant.collections.some(
    (item) => item.handle === COLLECTION_TINTED
  );

  let colorTinted = "";
  let matterialTinted = "";
  if (isLipstick) {
    const tag = variant.tags.find((item) => item.type === "PRODUCT SIZE");
    if (tag) {
      const colors = tag.value.split("/");
      matterialTinted = colors[0];
      colorTinted = colors[colors.length - 1];
    }
  }

  const nameShow = variant.option_name
    ? variant.option_name
    : variant.full_name;
  const nameSize = showOnlySize ? Helper.getSizeName(nameShow) : nameShow;
  return (
    <li
      className={cn(
        `border border-gray-300 rounded h-[44px] ${
          isPromotion && "mb-2"
        } px-3 py-2 flex variants-center gap-2 cursor-pointer relative items-center`,
        className,
        {
          "border-red-500 border": isSlected,
        },
        {
          " line-through": isOutOfStock,
        },
        {
          "bg-[#c5c5c5]": isOutOfStock && isSlected,
        }
      )}
      onClick={() => handleActive(variant)}
    >
      {isShowImage && (
        <div className="relative w-[28px] h-[28px] overflow-hidden">
          {!colorTinted ? (
            <Image
              alt="img"
              width={28}
              height={28}
              src={variant?.images[0]?.url || ""}
              className="object-cover object-center"
              placeholder={"blur"}
              blurDataURL={ImageConstant.BlurDataURL}
            />
          ) : (
            <div
              className="  w-7 h-7"
              style={{
                backgroundColor: `#${colorTinted}`,
              }}
            ></div>
          )}
        </div>
      )}

      {true ? (
        <p className="flex-1 text-sm line-clamp-2 leading-4">{nameSize}</p>
      ) : null}

   {/* {showPromotion && promotionsHaveConfig.length ? (
        <>
          {promotionsHaveConfig.map((data) => {
            if (data.config && data.config.tag_infor.length) {
              const tag = data.config.tag_infor.find(
                (tag) => tag.type === TypeTagConfig.VARIANT
              );
              if (tag) {
                return (
                  <span
                    className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2 bg-red-500 text-white rounded-sm px-[5px]  w-fit text-xs text-center line-clamp-1  text-nowrap"
                    style={{
                      backgroundColor: tag.bg_color,
                      color: tag.text_color,
                    }}
                  >
                    {tag.name}
                  </span>
                );
              }
            }
          })}
        </>
      ) : null} */}

{showPromotion && promotionsHaveConfig.length ? (
        <>
          {promotionsHaveConfig.map((data) => {
            const tag = typeof data.label === "string" ? JSON.parse(data.label || "") : data.label
              if (tag) {
                return (
                  <span
                    className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2 bg-red-500 text-white rounded-sm px-[5px]  w-fit text-xs text-center line-clamp-1  text-nowrap"
                    style={{
                      backgroundColor: tag.bg_color,
                      color: tag.text_color,
                    }}
                  >
                    {tag.name}
                  </span>
                );
              }
          })}
        </>
      ) : null}
    </li>
  );
}
