import Helper from "@/utils/helper";
import {
	AxiosError,
	AxiosRequestConfig,
	AxiosResponse,
	AxiosResponseHeaders,
	Method,
} from "axios";
import { ErrorServer } from "@/common/api/Error";
import { debugServer, handleAxiosResponse } from "./apiHandler";
import AxiosInstance from "./axiosClient";

export type FetchData<T> = {
	status: number;
	data: T | null;
	error: ErrorServer | null;
};

export type FetchDataV2<T> = {
	status: number;
	data: T | null;
	error: Error | null;
};

export type Option = Pick<
	AxiosRequestConfig,
	"params" | "data" | "cancelToken" | "timeout" | "headers" | "axios-retry"
> & {
	isCache?: boolean;
	revalidate?: number;
};

type Props = {
	url: string;
	method: Lowercase<Method>;
	options?: Option;
};

export const callApiV2 = async <T>(
	url: string,
	method: Lowercase<Method>,
	options?: Option
) => {
	const urlFinal =
		url.includes("https") || url.includes("http")
			? url
			: process.env.REACT_APP_BASE_URL + url;

	if (!Helper.isServer()) {
		const result = await handleAxiosFetchV2<T>({
			method: method,
			url: urlFinal,
			options,
		});

		return result;
	} else {
		const result = await handleServerFetch<T>({
			method: method,
			url: urlFinal,
			options,
		});

		return result;
	}
};

const handleAxiosFetchV2 = async <T>({ method, url, options }: Props) => {
	const cache =
		options && options?.isCache === false
			? "no-cache "
			: "public, max-age=3600";

	const axiosOptionsInstance: AxiosRequestConfig = {
		url: url,
		method: method,
		data: options?.data,
		cancelToken: options?.cancelToken,
		params: options?.params,
		timeout: options?.timeout,
		headers: {
			"Cache-Control": cache,
			...options?.headers,
		},
	};
	const startTime = Date.now() / 1000;
	const response = await AxiosInstance<T>(axiosOptionsInstance);
	return response;
};

const handleServerFetch = async <T>({ method, url, options }: Props) => {
	const controller = new AbortController();
	// const timeOut = options?.timeout ? options.timeout : 10000;

	const timeoutId = setTimeout(() => controller.abort(), 10000);

	let params = "";
	let urlFinal = url;
	const headerConfig = {
		"Content-Type": "application/json",
		"Cache-Control": options?.isCache ? "public, max-age=3600" : "no-cache",
	};
	// const cacheOption: Pick<RequestInit, "cache"> | undefined = options?.isCache
	// 	? { cache: "force-cache" }
	// 	: options?.revalidate
	// 	? {}
	// 	: { cache: "no-store" };

	const fetchOptions: RequestInit = {
		method: method,
		headers: headerConfig,
		keepalive: false,
		signal: controller.signal,
		// ...cacheOption,
		next: {
			revalidate: options?.revalidate,
		},
	};

	if (options?.params) {
		params = Helper.convertFilterToParams(options.params);
		urlFinal = url + params;
	}

	const response = await fetch(urlFinal, {
		...fetchOptions,
		method: fetchOptions.method?.toUpperCase() || "GET",
	});

	let res: any = null;
	try {
		res = await response.json();
	} catch (error) {}
	// try {
	// } catch (error) {
	// 	const err: AxiosError = {
	// 		status: response.status,
	// 		response: res,
	// 		message: response.statusText,
	// 		isAxiosError: true,
	// 		toJSON: function (): object {
	// 			throw new Error("Function not implemented.");
	// 		},
	// 		name: "",
	// 	};
	// 	throw err;
	// }
	if (!response.ok) {
		if (res) {
			const err: AxiosError = {
				status: response.status,
				response: res,
				message: response.statusText,
				isAxiosError: false,

				toJSON: function (): object {
					return {
						status: response.status,
						message: response.statusText,
						isAxiosError: false,
						data: res,
					};
				},
				name: "",
			};

			debugServer(
				urlFinal,
				res,
				response.status,
				response.headers.get("execution-time") || "",
				"error"
			);
			throw err;
		}
	}

	const headers: Record<string, string> = {};
	response.headers.forEach((value, name) => {
		headers[name] = value;
	});

	const data: Pick<
		AxiosResponse<T>,
		"data" | "status" | "statusText" | "headers"
	> = {
		data: res,
		status: response.status,
		statusText: response.statusText,
		headers: Object.fromEntries(
			response.headers.entries()
		) as unknown as AxiosResponseHeaders,
	};
	debugServer(
		urlFinal,
		res,
		response.status,
		response.headers.get("execution-time") || ""
	);
	// clearTimeout(timeoutId);
	return data;
};

const handleAxiosFetch = async <T>({ method, url, options }: Props) => {
	// const isProductApi = url.includes("products");
	const cache =
		options && options?.isCache ? "public, max-age=3600" : "no-cache";
	const axiosOptionsInstance: AxiosRequestConfig = {
		url: url,
		method: method,
		data: options?.data,
		cancelToken: options?.cancelToken,
		params: options?.params,
		timeout: options?.timeout,
		headers: {
			// "Cache-Control": !(options && typeof options.isCache !== "undefined")
			// 	? "public, max-age=3600"
			// 	: "no-cache",
			"Cache-Control": cache,
		},
	};

	// const axiosI = AxiosInstance();

	const startTime = Date.now() / 1000;
	try {
		const response = await AxiosInstance(axiosOptionsInstance);
		return handleAxiosResponse<T>(
			response,
			axiosOptionsInstance,
			null,
			startTime
		);
	} catch (error) {
		return handleAxiosResponse<T>(null, axiosOptionsInstance, error, startTime);
	}
};

export const callApi = async <T>(
	url: string,
	method: Lowercase<Method>,
	options?: Option
): Promise<FetchData<T>> => {
	const urlFinal =
		url.includes("https") || url.includes("http")
			? url
			: process.env.REACT_APP_BASE_URL + url;

	if (!Helper.isServer()) {
		const result = await handleAxiosFetch<T>({
			method: method,
			url: urlFinal,
			options,
		});

		// const result =
		// 	method === "get"
		// 		? await handleServerFetch<T>({
		// 				method: method,
		// 				url: urlFinal,
		// 				options,
		// 		  })
		// 		: await handleAxiosFetch<T>({
		// 				method: method,
		// 				url: urlFinal,
		// 				options,
		// 		  });
		return result;
	} else {
		const result = await handleAxiosFetch<T>({
			method: method,
			url: urlFinal,
			options,
		});

		return result;
	}
};
