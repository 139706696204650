import { BaseCollectionJson } from "@/common/types/BaseCollection.type";
import { FileJson } from "@/common/types/File.type";
import { ReactNode } from "react";

//original

export enum CustomerGenders {
	NONE = 0,
	MALE = 3,
	FEMALE = 1,
	OTHER = 5,
	UNKNOWN = 7,
}
type CustomerJson = {
	company_id: number;
	user_id: number;
	id: number;
	user_name: string;
	creator_id: number;
	customer_type_id: number;
	code: string;
	full_name: string;
	first_name: string;
	last_name: string;
	gender: CustomerGenders;
	rank: Rank;
	rank_type: number;
	birthday: number;
	email: string;
	phone: string;
	email_verified: number;
	phone_verified: number;
	country: number;
	province: number;
	district: number;
	ward: number;
	road: string;
	address: string;
	region_id: number;
	sub_region_id: number;
	sub_sub_region_id: number;
	tag: string;
	note: string;
	invoicing_detail: string;
	count_order: number;
	point: number;
	money: number;
	tax_buyer_name: string;
	tax_buyer_address: string;
	tax_buyer_phone: string;
	tax_buyer_account_number: string;
	tax_buyer_tax_number: string;
	ignore_auto_upgrade: number;
	order_spent: number;
	order_count: number;
	order_lastdate: number;
	order_lastid: number;
	order_lastnumber: string;
	order_lastspent: number;
	date_created: number;
	datemodified_username: number;
	datemodified_contact: number;
	datemodified_email: number;
	datemodified_phone: number;
	datemodified_edit_user: number;
	date_modified: number;
	date_first_ordered: number;
	date_last_ordered: number;
	status: number;
	isdeleted: number;
	date_deleted: number;
	total_collection: number;
	avartar: FileJson;
	is_visitor: IS_VISTOR;
};

export enum IS_VISTOR {
	NOT,
	IS,
}

type CustomerJsonAddEdit = {
	id?: number;
	company_id?: number;
	creator_id?: number;
	last_name?: string;
	first_name?: string;
	// full_name?: string;
	customer_type_id?: number;
	code?: string;
	birthday?: number | string;
	address?: string;
	region_id?: number;
	sub_region_id?: number;
	sub_sub_region_id?: number;
	email?: string;
	phone?: string;
	gender?: CustomerGenders;
	note?: string;
	tag?: string;
	invoicing_detail?: string;
	status?: number;

	password?: string;
};

type UpdateQuickCustomer = {
	first_name: string;
	last_name: string;
	birthday: number;
	email?: string;
	tokenid: string;
	password: string;
	password2: string;
	phone?: string;
	customer_id: number;
};

type CheckCustomerJson = {
	status: string;
};

//address
type CustomerAddressJson = {
	customer_id: number;
	is_default: number;
	type_name: string;
	status: number;
	is_deleted: number;
	date_deleted: number;
	date_created: number;
	date_modified: number;
	id: number;
	full_name: string;
	address: string;
	phone: string;
	district: number;
	province: number;
	country: number;
	ward: number;
	road: string;
	type: number;
	full_address: string;
	first_name: string;
	last_name: string;
};

type CustomerAddressAddEditJson = {
	customer_id?: number;
	id: number;
	full_name: string;
	address: string;
	phone: string;
	district: number;
	province: number;
	country: number;
	ward: number;
	road: string;
	type: number;
	full_address: string;
	is_default: number;
	first_name: string;
	last_name: string;
};

export enum CustomerAgeRange {
	CHILD = 1,
	YOUNG,
	MIDLLE,
	MIDLLE_2,
	MIDLLE_3,
	ELDERL,
	OVER_ELDERLY,
	UNKNOWN,
}
//account
type CustomerQuickRegisterJson = {
	phone?: string; //string
	full_name?: string; //string
	first_name?: string;
	last_name?: string;
	address?: string; //string
	province?: number; //int
	district?: number; //int
	ward?: number; //int
	country?: number;
	gender?: CustomerGenders;
	age_range?: CustomerAgeRange;
	type?: "quick" | "normal";
	birthday?: number | string;
	email?: string;
	company_id?: number;
};

type CartRegisterJson = {
	customer_id: number;
};

//membership
//rank
type Rank = {
	customer_id: number;
	datecreated: number;
	day_rank: number;
	id: number;
	total_spending: number;
	rank_type_id: number;
	status: number;
	total_rank_accumulate: number;
	max_day_rank: number;
	min_rank_type: number;
	max_rank_type: number;
	rank_next: number;
};

type DesRankType = {
	des: string[] | React.ReactNode[];
};

type CustomerBenefitsJson = {
	title: string;
	icon: ReactNode;
	descriptions: (rank_id: number) => DesRankType;
	rank: number[];
};

type CustomerBenefitsComingJson = {
	title: string;
	icon: ReactNode;
	des: string;
};

type CustomerRankTypeJson = {
	datecreated: number;
	datedeleted: number;
	datemodified: number;
	description: string;
	id: number;
	isdeleted: number;
	max: number;
	min: number;
	status: number;
	title: string;
};

type CustomerRankTypeFilters = {
	id?: number;
};
type CustomerUsernameAddEditJson = {
	type: number;
	code?: string;
	user_name: string;
};

//event
type CustomerEventJson = {
	source_id: number;
	source_type: number;
	event_type: number;
	event_detail_type: number;
	rank_old: number;
	rank_new: number;
	status: number;
	date_created: number;
};

type CustomerEventFilters = {
	customer_id?: number;
};
type PasswordAddEditJson = {
	old_password: string;
	password: string;
	password2: string;
};
type ConfirmPasswordAddEditJson = {
	password: string;
};
type TypeAbleChange = "EMAIL" | "INFO" | "USER_NAME" | "PHONE";

//collections
type CustomerCollectionJson = BaseCollectionJson<CustomerJson>;
type CustomerAddressCollectionJson = BaseCollectionJson<CustomerAddressJson>;
type CustomerRankTypeCollectionJson = BaseCollectionJson<CustomerRankTypeJson>;

//update info
type SenderCustomerAddEditJson = {
	type: number;
	code?: string;
	account_id?: string;
	non_code?: number;
	contact_type?: string;
	user_name?: string;
};

type VerifyCustomerAddEditJson = {
	account_id: string;
	otp: string;
	type: number;
};
type ConfirmPasswordCustomerRes = {
	code: number;
};

type NotificationProps = {
	contact: string;
	item_id: number;
	type: number;
};

type NotificationJson = {
	id: number;
};

type CustomerFiler = { keyword?: string; page: number; limit?: number };
export type {
	CustomerFiler,
	CustomerAddressAddEditJson,
	CustomerAddressJson,
	CustomerAddressCollectionJson,
	CustomerJson,
	CustomerQuickRegisterJson,
	CustomerJsonAddEdit,
	Rank,
	DesRankType,
	CustomerBenefitsJson,
	CustomerBenefitsComingJson,
	CustomerRankTypeJson,
	CustomerRankTypeFilters,
	CustomerCollectionJson,
	CustomerRankTypeCollectionJson,
	CustomerEventJson,
	CustomerEventFilters,
	SenderCustomerAddEditJson,
	VerifyCustomerAddEditJson,
	PasswordAddEditJson,
	ConfirmPasswordAddEditJson,
	TypeAbleChange,
	CustomerUsernameAddEditJson,
	ConfirmPasswordCustomerRes,
	CartRegisterJson,
	NotificationProps,
	NotificationJson,
	UpdateQuickCustomer,
	CheckCustomerJson,
};
