import { cn } from "@/utils/utils";
import React, { useId } from "react";
import {
	Control,
	Controller,
	ControllerProps,
	FieldPath,
	FieldValues,
} from "react-hook-form";
import LabelForm, { LabelFormProps } from "./LabelForm";
import { CheckIcon } from "../icons";

export type InputProps<
	TFieldValues extends FieldValues,
	TName extends FieldPath<TFieldValues>
> = Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> & {
	name: TName;
	control?: Control<TFieldValues>;
	placeholder?: string;
	defaultValue?: string | number;
} & Pick<ControllerProps<TFieldValues, TName>, "disabled" | "rules"> &
	InputCustom;

type RadioItem = {
	label: React.ReactNode;
	value: string | number;
};

type InputCustom = {
	showError?: boolean;
	label?: LabelFormProps;
	options: RadioItem[];
	onChange?: (value: any) => void;
	classNameControler?: string;
	typeRender?: "checked" | "default";
};

const Radio = <
	TFieldValues extends FieldValues,
	TName extends FieldPath<TFieldValues>
>(
	props: InputProps<TFieldValues, TName>
) => {
	const {
		name,
		control,
		disabled,
		rules,
		label,
		className,
		options,
		classNameControler,
		typeRender = "checked",
		...resInput
	} = props;

	const RenderTypeCheck = ({
		value,
		option,
	}: {
		value: any;
		option: RadioItem;
	}) => {
		if (typeRender === "checked") {
			return (
				<span
					className={`w-4 h-4 rounded-full border-[1px] border-gray-100 flex justify-center items-center ${
						value === option.value ? "bg-red-500 border-red-500" : ""
					}`}>
					{value === option.value && <CheckIcon />}
				</span>
			);
		}

		if (typeRender === "default") {
			return (
				<span
					className={`w-4 h-4 rounded-full border-[1px]  flex justify-center items-center  border-red-500 `}>
					{value === option.value && (
						<span className=" bg-red-500 w-2 h-2 rounded-full"></span>
					)}
				</span>
			);
		}
	};

	const calChecked = (val: string) => {
		return val === props.value;
	};

	if (control) {
		return (
			<Controller
				name={name}
				control={control}
				rules={rules}
				render={({ field: { onChange, value, ref }, fieldState }) => (
					<div className={cn("relative flex gap-4", classNameControler)}>
						{options.map((op, index) => (
							<div
								key={index}
								className="relative items-center gap-3 inline-flex">
								<RenderTypeCheck value={value} option={op} />
								<input
									disabled={disabled}
									defaultChecked={op.value === value}
									className={cn(
										`w-full h-full opacity-0 cursor-pointer absolute top-0 left-0 bottom-0 right-0`,
										className
									)}
									{...resInput}
									type="radio"
									ref={ref}
									onClick={(e: any) => {
										if (value === op.value) {
											return;
										}
										props?.onChange?.(op.value);
										props?.onClick?.(e);

										onChange(op.value);
									}}
									onChange={(e) => {
										console.log("🚀 ~ e:", e);
										// if (value === op.value) return;

										props?.onChange?.(op.value);
										onChange(op.value);
									}}
								/>
								<LabelForm
									htmlFor={op.value.toString()}
									required={!!rules?.required || props.required}
									{...label}>
									{op.label}
								</LabelForm>
							</div>
						))}

						{props.showError && (
							<p className="text-red-500">{fieldState.error?.message}</p>
						)}
					</div>
				)}
			/>
		);
	}

	return (
		<div>
			{options.map((op, index) => {
				const idRandom = useId() + "_" + op.value.toString();
				const defaultChecked = props.defaultValue == op.value;
				return (
					<div className="flex gap-1 items-center">
						<label className="cursor-pointer select-none" htmlFor={idRandom}>
							{op.label}
						</label>
						<input
							{...resInput}
							// checked={calChecked(op.value.toString())}
							defaultChecked={defaultChecked}
							// checked={op.value === props.value}
							name={props.name}
							id={idRandom}
							value={op.value}
							type="radio"
						/>
					</div>
				);
			})}
		</div>
	);
};

export default Radio;
